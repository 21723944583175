import React from 'react';
import {useLocation} from 'react-router';
import {useAppContext} from './context/AppContext';
import {User, UserRole} from './model/User';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';

import {UzivatelCiselnikMasterRole} from './page/Dials/master/UzivatelCiselnikMasterRole';
import {ProvozovnaCiselnikUser} from './page/Dials/user/ProvozovnaCiselnik';
import {PrepravyCiselnik} from './page/Dials/user/zadani/PrepravyCiselnik';
import {VlastniVozyCiselnik} from './page/Dials/user/zadani/VlastniVozyCiselnik';
import InzerceCiselnik from './page/Dials/user/zadani/InzerceCiselnik';
import {PrepravyCiselnikView, PrepravyCiselnikViewNew} from './page/Dials/user/prohlizeni/PrepravyCiselnikView';
import {VlastniVozyCiselnikView, VolneVozyCiselnikViewNew} from './page/Dials/user/prohlizeni/VlastniVozyCiselnikView';
import InzerceViewCiselnik, {InzerceCiselnikViewNew} from './page/Dials/user/prohlizeni/InzerceViewCiselnik';
import {UzivatelCiselnik, UzivatelHistoryCiselnik} from './page/Dials/admin/UzivatelCiselnik';
import {ProvozovnaCiselnik, ProvozovnaHistoryCiselnik} from './page/Dials/admin/ProvozovnaCiselnik';
import {FirmaCiselnik, FirmaHistoryCiselnik} from './page/Dials/admin/FirmaCiselnik';
import {StatCiselnik} from './page/Dials/admin/StatCiselnik';
import {CurrencyCiselnik} from './page/Dials/admin/CurrencyCiselnik';
import {ZakazanaSlovaCiselnik} from './page/Dials/admin/ZakazanaSlovaCiselnik';
import {HlidanaSlovaCiselnik} from './page/Dials/admin/NevhodnaSlovaFirmaCiselnik';
import {SystemParameterCiselnik} from './page/Dials/admin/SystemParameterCiselnik';
import {JobCiselnik} from './page/Dials/admin/JobCiselnik';
import {PviCiselnikProhlizeni, PviCiselnikZadani} from './page/Dials/user/PviCiselnik';
import {Dashboard} from './page/Dashboard/Dashboard';
import {Help} from './page/Help/Help';
import {Announcement} from './page/Help/Announcement';
import {SystemInformation} from './page/Help/System';
import {ThunderNewsAdmin} from './page/Dials/admin/ThunderNews';
import {ThunderNews} from './page/Dials/user/ThunderNews';
import {KilometrovnikCiselnik} from './page/Dials/admin/KilomentrovnikCiselnik';
import {ProfilVozidlaDial} from './page/Dials/user/kilometrovnik/ProfilVozidlaDial';
import {TrasaDial} from './page/Dials/user/kilometrovnik/TrasaDial';
import {LicenseType} from './model/CommonTypes';
import {exist, getBoolean} from '../common/utils/Util';
import {KilometrovnikHlaseniDial} from './page/Dials/admin/KilometrovnikHlaseniDial';
import {KilometrovnikHlaseniForm} from './page/Dials/user/kilometrovnik/KilometrovnikHlaseniForm';
import {PrejezdyPrepravDial} from './page/Dials/user/kilometrovnik/PrejezdyPrepravDial';
import {PrejezdyVozidelDial} from './page/Dials/user/kilometrovnik/PrejezdyVozidelDial';
import {DokladkyDial} from './page/Dials/user/kilometrovnik/DokladkyDial';
import {DiagPage} from './page/Dials/admin/DiagPage';
import {SbernaSluzbaPage} from './page/Dials/user/kilometrovnik/SbernaSluzbaPage';
import {useData} from './context/DataContext';
import {SystemParameter, SystemParamKey} from './model/SystemParameter';
import {NepohodlnaOsobaDial} from './page/Dials/admin/stiznosti/NepohodlnaOsobaDial';
import {DluhPripadDial} from './page/Dials/admin/stiznosti/DluhPripadDial';
import {DluhNadpripadDial} from './page/Dials/admin/stiznosti/DluhNadpripadDial';
import {NewsAdmin} from './page/Dials/admin/News';
import {ReportTextsDial} from './page/Dials/admin/report/ReportTextDial';
import InzerceCiselnikSearch from './page/Dials/user/search/InzerceCiselnikSearch';
import {PrepravyCiselnikSearch} from './page/Dials/user/search/PrepravyCiselnikSearch';
import {VlastniVozyCiselnikSearch} from './page/Dials/user/search/VlastniVozyCiselnikSearch';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import {TrikratADostForm} from './page/Dials/admin/stiznosti/TrikratADostForm';
import {DluhSoubDial} from './page/Dials/admin/stiznosti/DlouSoubDial';
import {ProvozovnaRecordsView} from './page/Dials/admin/ProvozovnaRecordsView';
import {CenyMytaDial} from './page/Dials/user/kilometrovnik/CenyMytaDial';
import {DluhNadpripadPoznamkyDial} from './page/Dials/admin/stiznosti/DluhNadpripadPoznamkaDial';
import {DluhFaktDial} from './page/Dials/admin/stiznosti/DluhFaktDial';
import {PrepravyCiselnikArchiveView} from './page/Dials/user/archiv/PrepravaCiselnikArchiveView';
import {PrepravyCiselnikArchive} from './page/Dials/user/archiv/PrepravaCiselnikArchive';
import {VolneVozyCiselnikArchiveView} from './page/Dials/user/archiv/VolneVozyCiselnikArchiveView';
import {VolneVozyCiselnikArchive} from './page/Dials/user/archiv/VolneVozyCiselnikArchive';
import {InzerceCiselnikArchiveView} from './page/Dials/user/archiv/InzerceCiselnikArchiveView';
import {PrepravaHistoryViewPartNew} from './page/Dials/user/prohlizeni/PrepravaHistoryViewPart';
import {ArchivPoznamkyDial} from './page/Dials/user/archiv/ArchivPoznamkyDial';
import {VolneVozyViewHistoryPartNew} from './page/Dials/user/prohlizeni/VolneVozyViewHistoryPart';
import {InzerceCiselnikArchive} from './page/Dials/user/archiv/InzerceCiselnikArchive';
import InzerceHistoryViewPart from './page/Dials/user/prohlizeni/InzerceHistoryViewPart';
import {ModalTrasaForm} from './page/Dials/user/kilometrovnik/ModalTrasaForm';
import {getConfig} from '../Config';
import {ProvozovnaDetailMaster} from './page/Dials/master/ProvozovnaDetailMaster';
import {PravniDokumentaceCiselnik} from './page/Dials/common/PravniDokumentaceCiselnik';
import {HereProfilVozidlaDial} from './page/Dials/user/kilometrovnik/HereProfilVozidlaDial';
import {Info} from "./page/Help/Info";
import {CustomerServicePage} from "./page/CustomerServicePage";
import {HereKilometrovnik} from './page/Dials/user/kilometrovnik/HereKilometrovnik';
import {RouteRedirect} from "./page/Dials/common/RouteRedirect";

export type Route = {
    roles?: UserRole[],
    id: string,
    parentId?: string,
    href: string,
    component?: any,
    icon?: any,
    children?: Route[],
    menuExcluded?:boolean,
    routeProps?: { [prop: string]: any }
    routeDefaultState?: { [prop: string]: any }
    newWindow?:boolean

    //helper field
    parent?: Route
    visible?: (data: any) => boolean
    autoOpen?:boolean
	bold?:boolean
}

export enum ComponentMode {
    GridMode, DetailMode
}

export type RouteComponentProps = {
    mode: ComponentMode,
	url: string,
	admin: boolean,
	master: boolean,
    redirect: string,
}

export const routes:Route[] = [
    {
        roles: [],
        id: 'Sections.Start',
        href: "/dashboard",
        component: Dashboard,
        menuExcluded:true,
        icon: <FontAwesomeIcon icon={faIcon.faHome} size="lg"/>
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.Assign',
        href: "/zadat",
        icon: <FontAwesomeIcon icon={faIcon.faPencilAlt} size="lg"/>,
		autoOpen: true,
		bold:true,
        children: [
            {href: "/prepravy", component: PrepravyCiselnik, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy"},
            {href: "/volnevozy", component: VlastniVozyCiselnik, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy"},
            {href: "/inzerce", component: InzerceCiselnik, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Advertising"},
            {href: "/pvi/prepravy", component: PrepravyCiselnik, routeProps: {cacheGroup: "pvi_zadani"}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.PviShort", menuExcluded: true},
            {href: "/pvi/volnevozy", component: VlastniVozyCiselnik, routeProps: {cacheGroup: "pvi_zadani"}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.PviShort", menuExcluded: true},
            {href: "/pvi/inzerce", component: InzerceCiselnik, routeProps: {cacheGroup: "pvi_zadani"}, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.PviShort", menuExcluded: true},
            {href: "/pvi", component: PviCiselnikZadani, icon: <FontAwesomeIcon icon={faIcon.faList} size="lg"/>, id: "Dials.PviShort"},
        ]
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.View',
        href: "/prohlizet",
        icon: <FontAwesomeIcon icon={faIcon.faSearch} size="lg"/>,
		autoOpen: true,
		bold:true,
        children: [
            {href: "/prepravy", component: PrepravyCiselnikView, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy"},
            {href: "/volnevozy", component: VlastniVozyCiselnikView, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy"},
            {href: "/inzerce", component: InzerceViewCiselnik, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Advertising"},
            {href: "/pvi/prepravy", component: PrepravyCiselnikView, routeProps: {id: "pvi_preprava", cacheGroup: "pvi_prohlizeni"}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Pvi", menuExcluded: true},
            {href: "/pvi/volnevozy", component: VlastniVozyCiselnikView, routeProps: {id: "pvi_vozy", cacheGroup: "pvi_prohlizeni"}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.Pvi", menuExcluded: true},
            {href: "/pvi/inzerce", component: InzerceViewCiselnik, routeProps: {id: "pvi_inzerce", cacheGroup: "pvi_prohlizeni"}, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Pvi", menuExcluded: true},
            {href: "/pvi", component: PviCiselnikProhlizeni, icon: <FontAwesomeIcon icon={faIcon.faList} size="lg"/>, id: "Dials.Pvi"},
            {href: "/provozovna", component: ProvozovnaCiselnikUser, icon: <FontAwesomeIcon icon={faIcon.faBuilding} size="lg"/>, id: "Dials.Provozovna", menuExcluded: true}
        ]
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.ArchiveTask',
        href: "/archiv",
        icon: <FontAwesomeIcon icon={faIcon.faArchive} size="lg"/>,
        children: [
            {href: "/prepravy/:nabidkaId/poznamky", routeProps:{nabidkaType: 'P'}, menuExcluded: true, component: ArchivPoznamkyDial, id: "Dials.Notes" },
            {href: "/volnevozy/:nabidkaId/poznamky", routeProps:{nabidkaType: 'V'}, menuExcluded: true, component: ArchivPoznamkyDial, id: "Dials.Notes" },
            {href: "/inzerce/:nabidkaId/poznamky", routeProps:{nabidkaType: 'I'}, menuExcluded: true, component: ArchivPoznamkyDial, id: "Dials.Notes" },
            {href: "/prepravy", component: PrepravyCiselnikArchive, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy"},
            {href: "/volnevozy", component: VolneVozyCiselnikArchive, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy"},
            {href: "/inzerce", component: InzerceCiselnikArchive, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Advertising"},
        ]
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.ArchiveBrowse',
        href: "/archiv-prohlizeni",
        icon: <FontAwesomeIcon icon={faIcon.faFileArchive} size="lg"/>,
        children: [
            {href: "/prepravy/:nabidkaId/poznamky", routeProps:{nabidkaType: 'P', viewArchive: true}, menuExcluded: true, component: ArchivPoznamkyDial, id: "Dials.Notes" },
            {href: "/volnevozy/:nabidkaId/poznamky", routeProps:{nabidkaType: 'V', viewArchive: true}, menuExcluded: true, component: ArchivPoznamkyDial, id: "Dials.Notes" },
            {href: "/inzerce/:nabidkaId/poznamky", routeProps:{nabidkaType: 'I', viewArchive: true}, menuExcluded: true, component: ArchivPoznamkyDial, id: "Dials.Notes" },
            {href: "/prepravy", component: PrepravyCiselnikArchiveView, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy"},
            {href: "/volnevozy", component: VolneVozyCiselnikArchiveView, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy"},
            {href: "/inzerce", component: InzerceCiselnikArchiveView, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Advertising"},
        ]
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.Pass',
        href: "/prejezdy",
        icon: <FontAwesomeIcon icon={faIcon.faMapSigns} size="lg"/>,
        visible: (data) => {return (data?.user as User).typLicence===LicenseType.WITHKM},
        children: [
            {href: "/prepravy", component: PrejezdyPrepravDial, routeDefaultState: {clearFilter: false}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy" },
            {href: "/volnevozy", component: PrejezdyVozidelDial, routeDefaultState: {clearFilter: false}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy" },
        ]
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.Fill',
        href: "/dokladky",
        component: DokladkyDial,
        routeDefaultState: {clearFilter: false},
        icon: <FontAwesomeIcon icon={faIcon.faDolly} size="lg"/>,
        visible: (data) => {return (data?.user as User).typLicence===LicenseType.WITHKM},
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.CollectionService',
        href: "/sberna-sluzba",
        component: SbernaSluzbaPage,
        routeDefaultState: {clearFilter: true},
        icon: <FontAwesomeIcon icon={faIcon.faTruckLoading} size="lg"/>,
        visible: (data) => {return getConfig().geoEnabled && (data?.user as User).typLicence===LicenseType.WITHKM},
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.Company',
        href: "/provozovny",
        component: ProvozovnaCiselnikUser,
        icon: <FontAwesomeIcon icon={faIcon.faBuilding} size="lg"/>
    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.History',
        href: "/historie",
        menuExcluded: true,
        icon: <FontAwesomeIcon icon={faIcon.faHistory} size="lg"/>,
        children: [
            {href: "/prepravy/archiv", menuExcluded: true, component: PrepravaHistoryViewPartNew, routeProps: {archive: true}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.HistoryPrepravy" },
            {href: "/prepravy", menuExcluded: true, component: PrepravaHistoryViewPartNew, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.HistoryPrepravy" },
            {href: "/volnevozy/archiv", menuExcluded: true, component: VolneVozyViewHistoryPartNew, routeProps: { archive: true}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.HistoryVolneVozy" },
            {href: "/volnevozy", menuExcluded: true, component: VolneVozyViewHistoryPartNew, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.HistoryVolneVozy" },
            {href: "/inzerce/archiv", menuExcluded: true, component: InzerceHistoryViewPart,routeProps: {archive: true}, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.HistoryAdvertising"},
            {href: "/inzerce", menuExcluded: true, component: InzerceHistoryViewPart, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.HistoryAdvertising"},
        ]
    },
    {
        roles: [UserRole.ROLE_ADMIN],
        id: "Provozovna.Records",
        href: "/admin/provozovna/records",
        component: ProvozovnaRecordsView,
        menuExcluded: true
    },
    {
        roles: [UserRole.ROLE_ADMIN],
        id: 'Sections.Administration',
        href: "/admin",
        icon: <FontAwesomeIcon icon={faIcon.faUserCog} size="lg"/>,
        children: [
            {
                roles: [UserRole.ROLE_ADMIN],
                id: 'Sections.Admin.History',
                href: "/historie",
                icon: <FontAwesomeIcon icon={faIcon.faHistory} size="lg"/>,
                children: [
                    {href: "/prepravy/archiv", menuExcluded: true, component: PrepravaHistoryViewPartNew, routeProps: {admin: true, archive: true}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.HistoryPrepravy" },
                    {href: "/prepravy", menuExcluded: true, component: PrepravaHistoryViewPartNew, routeProps: {admin: true}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.HistoryPrepravy" },
                    {href: "/volnevozy/archiv", menuExcluded: true, component: VolneVozyViewHistoryPartNew, routeProps: {admin: true, archive: true}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.HistoryVolneVozy" },
                    {href: "/volnevozy", menuExcluded: true, component: VolneVozyViewHistoryPartNew, routeProps: {admin: true}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.HistoryVolneVozy" },
                    {href: "/inzerce/archiv", menuExcluded: true, component: InzerceHistoryViewPart,routeProps: {admin: true, archive: true}, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.HistoryAdvertising"},
                    {href: "/inzerce", menuExcluded: true, component: InzerceHistoryViewPart, routeProps: {admin: true}, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.HistoryAdvertising"},
                    {href: "/uzivatele", component: UzivatelHistoryCiselnik, routeProps: {url: "admin/user-union-view", admin: true, master: false}, icon: <FontAwesomeIcon icon={faIcon.faUser} size="lg"/>, id: "Dials.Users"},
                    {href: "/provozovny", component: ProvozovnaHistoryCiselnik, icon: <FontAwesomeIcon icon={faIcon.faBuilding} size="lg"/>, id: "Dials.Places"},
                    {href: "/firmy", component: FirmaHistoryCiselnik, icon: <FontAwesomeIcon icon={faIcon.faUserTie} size="lg"/>, id: "Dials.Companies"}
                ]
            },
            {
                roles: [UserRole.ROLE_ADMIN],
                id: 'Sections.Admin.Archiv',
                href: "/archiv",
                icon: <FontAwesomeIcon icon={faIcon.faArchive} size="lg"/>,
                children: [
                    {href: "/prepravy", component: PrepravyCiselnikArchiveView, routeProps: {admin: true, archive: true}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy"},
                    {href: "/volnevozy", component: VolneVozyCiselnikArchiveView, routeProps: {admin: true, archive: true}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy"},
                    {href: "/inzerce", component: InzerceCiselnikArchiveView, routeProps: {admin: true, archive: true}, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Advertising"}
                ]
            },
            {
                roles: [UserRole.ROLE_ADMIN],
                id: 'Sections.Admin.Offers',
                href: "/nabidky",
                icon: <FontAwesomeIcon icon={faIcon.faList} size="lg"/>,
                children: [
                    {href: "/prepravy", component: PrepravyCiselnikViewNew, routeProps: {admin: true}, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy" },
                    {href: "/volnevozy", component: VolneVozyCiselnikViewNew, routeProps: {admin: true}, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy" },
                    {href: "/inzerce", component: InzerceCiselnikViewNew, routeProps: {admin: true}, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Advertising"},
                ]
            },
            {
                roles: [UserRole.ROLE_ADMIN],
                id: 'Sections.Search',
                href: "/search",
                icon: <FontAwesomeIcon icon={faIcon.faSearch} size="lg"/>,
                children: [
                    {href: "/prepravy", component: PrepravyCiselnikSearch, icon: <FontAwesomeIcon icon={faIcon.faBoxes} size="lg"/>, id: "Dials.Prepravy"},
                    {href: "/volnevozy", component: VlastniVozyCiselnikSearch, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.VolneVozy"},
                    {href: "/inzerce", component: InzerceCiselnikSearch, icon: <FontAwesomeIcon icon={faIcon.faShoppingCart} size="lg"/>, id: "Dials.Advertising"},
                ]
            },
            {href: "/uzivatele", component: UzivatelCiselnik, routeProps: {admin: true}, icon: <FontAwesomeIcon icon={faIcon.faUser} size="lg"/>, id: "Dials.Users"},
            {href: "/provozovny", component: ProvozovnaCiselnik, icon: <FontAwesomeIcon icon={faIcon.faBuilding} size="lg"/>, id: "Dials.Places"},
            {href: "/firmy", component: FirmaCiselnik, icon: <FontAwesomeIcon icon={faIcon.faUserTie} size="lg"/>, id: "Dials.Companies"},
            {href: "/thunder-news", component: ThunderNewsAdmin, icon: <FontAwesomeIcon icon={faIcon.faEnvelope} size="lg"/>, id: "Dials.ThunderMessages"},
            {href: "/news", component: NewsAdmin, icon: <FontAwesomeIcon icon={faIcon.faMailBulk} size="lg"/>, id: "Dials.Messages"},
            {href: "/staty", component: StatCiselnik, icon: <FontAwesomeIcon icon={faIcon.faFlag} size="lg"/>, id: "Dials.Countries"},
            {href: "/kilometrovnik", component: KilometrovnikCiselnik, icon: <FontAwesomeIcon icon={faIcon.faRoad} size="lg"/>, id: "Dials.Kilometrovnik"},
            {href: "/meny", component: CurrencyCiselnik, icon: <FontAwesomeIcon icon={faIcon.faCoins} size="lg"/>, id: "Dials.Currencies"},
            {href: "/zakazanaSlova", component: ZakazanaSlovaCiselnik, icon: <FontAwesomeIcon icon={faIcon.faHandPaper} size="lg"/>, id: "Dials.ZakazanaSlova"},
            {href: "/hlidanaSlovaFirma", component: HlidanaSlovaCiselnik, icon: <FontAwesomeIcon icon={faIcon.faMask} size="lg"/>, id: "Dials.HlidanaSlova"},
            {href: "/reporty/texty", component: ReportTextsDial, icon: <FontAwesomeIcon icon={faIcon.faLanguage} size="lg"/>, id: "Dials.ReportTexts"},
            {href: "/parametry", component: SystemParameterCiselnik, icon: <FontAwesomeIcon icon={faIcon.faKeyboard} size="lg"/>, id: "Dials.Params"},
            {href: "/joby", component: JobCiselnik, icon: <FontAwesomeIcon icon={faIcon.faClock} size="lg"/>, id: "Dials.Jobs"},
            {href: "/kilometrovnik-hlaseni", component: KilometrovnikHlaseniDial, icon: <FontAwesomeIcon icon={faIcon.faExclamationTriangle} size="lg"/>, id: "Dials.KilometrovnikHlaseniAdmin"},
            {href: "/diag", component: DiagPage, icon: <FontAwesomeIcon icon={faIcon.faDiagnoses} size="lg"/>, id: "Dials.DiagPage"}
        ]
    },
    {
        roles: [UserRole.ROLE_ADMIN],
        id: 'Sections.Stiznosti.Title',
        href: "/stiznosti",
        icon: <FontAwesomeIcon icon={faIcon.faExclamationTriangle} size="lg"/>,
        children: [
            {href: "/nepohodlna-osoba", component: NepohodlnaOsobaDial, icon: <FontAwesomeIcon icon={faIcon.faUserSlash} size="lg"/>, id: "Dials.NepohodlneOsoby" },
            {href: "/dluh-pripad/:pripadId/faktury", component: DluhFaktDial, menuExcluded: true, id: "Dials.Faktury" },
            {href: "/dluh-pripad", component: DluhPripadDial, icon: <FontAwesomeIcon icon={faIcon.faBriefcase} size="lg"/>, id: "Dials.Pripady" },
            {href: "/dluh-nadpripad/:nadpripadId/poznamky", component: DluhNadpripadPoznamkyDial, menuExcluded: true, id: "Dials.Notes" },
            {href: "/dluh-nadpripad", component: DluhNadpripadDial, icon: <FontAwesomeIcon icon={faIcon.faGavel} size="lg"/>, id: "Dials.Nadpripady" },
            {
                roles: [UserRole.ROLE_ADMIN],
                id: 'Sections.Stiznosti.Statistiky',
                href: "/statistiky",
                icon: <StackedLineChartIcon />,
                children: [
                    {href: "/trikrat-a-dost", component: TrikratADostForm, icon: <FontAwesomeIcon icon={faIcon.faUsersSlash} size="lg"/>, id: "Dials.TriADost" }
                ]
            }]
    },
    {
        roles: [UserRole.ROLE_ADMIN],
        id: 'Sections.Logs',
        href: getConfig().logsUrl,
        icon: <FontAwesomeIcon icon={faIcon.faLaptopCode} size="lg"/>,
        newWindow: true

    },
    {
        roles: [UserRole.ROLE_ADMIN],
        id: 'Sections.Soubory',
        href: "/admin/soubory",
        component: DluhSoubDial,
        menuExcluded:true,

    },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.User.Kilometrovnik',
        href: "/kilometrovnik",
        icon: <FontAwesomeIcon icon={faIcon.faRoad} size="lg"/>,
        visible: (data) => {
			return getConfig().geoEnabled && (data?.user as User).typLicence === LicenseType.WITHKM;
		},
        children: [
            {href: "/vozy", component: ProfilVozidlaDial, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.ProfilyVozidel" },
            {href: "/trasy", component: TrasaDial, icon: <FontAwesomeIcon icon={faIcon.faRoute} size="lg"/>, id: "Dials.Trasy" },
            {href: "/trasa", component: ModalTrasaForm, icon: <FontAwesomeIcon icon={faIcon.faRoute} size="lg"/>, menuExcluded: true, id: "Dials.Trasy" },
            {href: "/kilometrovnik-hlaseni", component: KilometrovnikHlaseniForm, icon: <FontAwesomeIcon icon={faIcon.faExclamationTriangle} size="lg"/>, id: "Dials.KilometrovnikHlaseni"},
            {href: "/:profilId/ceny-myta", component: CenyMytaDial, menuExcluded: true, id: "CenaMyta.Title" },
        ]
    },
	{
		roles: [UserRole.ROLE_USER],
		id: "Dials.ProfilyVozidel",
		href: "/hereprofilvozidla",
		component: HereProfilVozidlaDial,
		menuExcluded: false,
		icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>,
		visible: (data) => {
			return getConfig().hereEnabled && (data?.user as User).typLicence === LicenseType.WITHKM;
		},
	},
	// {
	// 	roles: [UserRole.ROLE_USER],
	// 	id: "Sections.User.Kilometrovnik",
	// 	href: "/herekilometrovnik",
	// 	component: HereKilometrovnik,
	// 	menuExcluded: false,
	// 	icon: <FontAwesomeIcon icon={faIcon.faRoad} size="lg"/>,
	// 	visible: (data) => {
	// 		return getConfig().hereEnabled;
	// 	},
	// },
    {
        roles: [UserRole.ROLE_USER],
        id: 'Sections.User.Kilometrovnik',
        href: "/kilometrovnik",
        icon: <FontAwesomeIcon icon={faIcon.faRoad} size="lg"/>,
        visible: (data) => {
            return getConfig().hereEnabled && (data?.user as User).typLicence === LicenseType.WITHKM;
        },
        children: [
            {href: "/trasa", component: RouteRedirect, routeProps: {redirect: '/kilometrovnik/trasy/new'}, icon: <FontAwesomeIcon icon={faIcon.faRoute} size="lg"/>, id: "Dials.VypocetTrasy" },
            {href: "/trasy", component: HereKilometrovnik, icon: <FontAwesomeIcon icon={faIcon.faRoute} size="lg"/>, id: "Dials.UlozeneTrasy" },
            // {href: "/profily", component: HereProfilVozidlaDial, icon: <FontAwesomeIcon icon={faIcon.faTruck} size="lg"/>, id: "Dials.ProfilyVozidel" },
        ]
    },
    {
        id: 'Sections.Zpravy',
        href: "/zpravy",
        component: ThunderNews,
        menuExcluded:true,
        icon: <FontAwesomeIcon icon={faIcon.faMailBulk} size="lg"/>
    },
    {
        roles: [],
        id: 'Sections.Help',
        href: "/help",
        component: Help,
        menuExcluded:true,
        children:[
            {href: "/info", component: Info, id: "Informations.Info" },
            {href: "/system", component: SystemInformation, id: "Informations.System" },
            {href: "/announcement", component: Announcement, id: "Announcement" },
        ]
    },
    {
        roles: [UserRole.ROLE_MASTER],
        id: 'Sections.Master',
        href: "/master",
        icon: <FontAwesomeIcon icon={faIcon.faUserSecret} size="lg"/>,
        autoOpen: false,
        children: [
            {href: "/provozovna", component: ProvozovnaDetailMaster, icon: <FontAwesomeIcon icon={faIcon.faBuilding} size="lg"/>, id: "Dials.Provozovna"},
            {href: "/smlouvy", component: PravniDokumentaceCiselnik, icon: <FontAwesomeIcon style={{marginLeft: "1px"}} icon={faIcon.faFileInvoice} size="lg"/>, id: "Dials.Smlouvy"},
            {href: "/uzivatele", component: UzivatelCiselnikMasterRole, routeProps: {master: true}, icon: <FontAwesomeIcon icon={faIcon.faUser} size="lg"/>, id: "Dials.Users"},
            {
                roles: [UserRole.ROLE_MASTER],
                id: 'Sections.History',
                href: "/historie",
                icon: <FontAwesomeIcon icon={faIcon.faHistory} size="lg"/>,
                menuExcluded: true,
                children: [
                    {href: "/uzivatele", component: UzivatelHistoryCiselnik, routeProps: {url: "master/user-union-view", admin: false, master: true}, icon: <FontAwesomeIcon icon={faIcon.faUser} size="lg"/>, id: "Dials.Users"},
                ]
            },
        ]
    },
    {
        roles: [],
        id: 'Sections.Support',
        href: "/podpora",
        component: CustomerServicePage,
        icon: <FontAwesomeIcon icon={faIcon.faQuestionCircle} size="lg"/>
    },
];

export const useProtectedRoutes = (filter:(r: Route)=>boolean=()=>true) => {
    const {hasUserRole, user} = useAppContext();
    const [hideInzerce] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === SystemParamKey.HIDE_INZERCE));
    const hasRole = (r: Route) => hasUserRole(r.roles || []);
    const inzerceCheck = (r: Route) => hasUserRole(UserRole.ROLE_ADMIN) || !getBoolean(hideInzerce.value) || (getBoolean(hideInzerce.value) && !r.href.startsWith("/inzerce"));
    const filterRoute = (r:Route) => {
        return hasRole(r) && inzerceCheck(r) && filter(r) && (!exist(r.visible) || r.visible({user: user}));
    };
    const deepFilter = (routes:Route[]):Route[] => {
        if(routes.length ===0) return null;
        const filteredLevel = [...routes.filter(filterRoute)];
        return filteredLevel.map(fl=>({...fl, children:deepFilter(fl.children ?? [])}));
    };
    return deepFilter(routes);
};

export const useFlatRoutes = ():Route[] => {
    const navList = useProtectedRoutes();
    const nestedChildren = (routes:Route[]):any => {
        return routes?.filter(n=>n.children).map(
            r => {
                const children = r.children.map<Route>(c => ({
                    ...c,
                    href: c.href.startsWith("//") ? c.href : `${r.href}${c.href}`,
                    parentId: r.id
                }));
                return [...nestedChildren(children).flat(), ...children];
            }
        )
    };

    const children = nestedChildren(navList);
    const topLevel = navList.filter(n => !n.children || n.component);
    return [...children.flat(), ...topLevel];
};

export function useCurrentRoute() {
    const flatRoutes = useFlatRoutes();
    const {pathname} = useLocation();
    const currentNav = useFlatRoutes().filter(cfg => cfg.href === pathname);
    return currentNav.length === 0 ? flatRoutes[0] : currentNav[0];
}
