import React, {
    Dispatch,
    MutableRefObject,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {Button, useMediaQuery} from "@material-ui/core";
import {DGContext} from "./raal_components/grid/DataGrid";
import DataStorage from "../common/DataStorage";
import * as DG from "./raal_components/grid/DataGrid.d";
import {Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useRateLimitedLogic} from "../common/component/hooks/SharedHooks";
import {CrudOperationType, CrudUpdate, CrudUpdateEvent} from "./model/CrudUpdate";
import {getCurrentTabId} from "../common/utils/unque-tab-id";
import {useStompSubscribe} from "../common/utils/Websocket";
import {useAppContext} from "./context/AppContext";
import {FormControlLabel, FormGroup, Grid, Switch, Tooltip} from "@mui/material";
import {StandaloneField} from "../common/component/form/StandaloneField";
import {FormItemAge, FormItemAgeOptions, ItemAge} from "../common/component/form/FormItemAge";
import {PubSub} from "use-pubsub-js";
import {RefreshAlert} from "./RefreshAlert";
import {useTableCache} from "./raal_components/grid/MTCache";
import {PushNotificationFlag} from "./PushNotificationFlag";

export type AutoRefreshState = {
    enabled:boolean
    count:number
    totalAdded:number //Not accurate when a lot of changes come at the same time
    temporarily: boolean
    pushNotification: boolean // flag pre prehratie/neprehratie push notifikacie
}

export type AutoRefreshStateHandler = {
    enabled:boolean,
    count:number,
    setState: Dispatch<SetStateAction<AutoRefreshState>>
    refresh: (data: CrudUpdateEvent) => void
    resetCounter: () => void
    decreaseCounter:() => void
    disableAutorefresh: (temporarily?: boolean) => void
    enableAutorefresh: (preventRefreshTable?: boolean) => void
}

export const ARContext = React.createContext<[AutoRefreshState, Dispatch<SetStateAction<AutoRefreshState>>]>(null);

export type AutoRefreshIndicatorProps = {
    filterData?: (data: any) => void
    defaultValue?: ItemAge
    lastBrowseDateDataKey?: string
    hideItemAge?: boolean
}

export const AutoRefreshIndicator = (props: AutoRefreshIndicatorProps) => {
    const [state, setState] = useContext(ARContext);
    const {checkDataChanged} = useAppContext();
    const {table} = useContext(DGContext);
    const isLarge = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
    const {t} = useTranslation();
    const {enabled, count} = state;
    useEffect(()=>{
        if (enabled && count > 0) {
            setState(state => ({...state, count:0}));
            table.current?.refresh();
        }
        if (!enabled) {
            table.current?.focusEditRow();
        }
    }, [enabled, count, setState, table]);

    const alertRef = useRef(null);

    // Synchronizacia countera NOVA DATA a RefreshAlert pocitadla
    const resetRefreshAlert = () => {
        if (alertRef.current) alertRef.current.setValue(0)
    }

    return <Grid container justifyContent={"space-between"} style={{columnGap: "10px", rowGap: "10px"}}>
        <Grid item style={{display: "flex"}}>
            <Grid container alignItems={"center"} justifyContent={"flex-start"}>
                <Grid item>
                    <RefreshAlert ref={alertRef}/>
                </Grid>
                <Grid item>
                    {props.lastBrowseDateDataKey == 'preprava-view-browse-date' &&
                        <PushNotificationFlag type='prepravaPushNotification' />
                    }
                    {props.lastBrowseDateDataKey == 'vozy-view-browse-date' &&
                        <PushNotificationFlag type='vozidloPushNotification' />
                    }
                </Grid>
                <Grid item>
                    <FormGroup>
                        <FormControlLabel
                            control={<Tooltip title={t(`AutoRefresh.${enabled}`)} placement={"bottom-start"}>
                                <Switch onChange={!enabled ? () => checkDataChanged(()=> {
                                    table.current?.onResetLastTabIndex();
                                    !enabled && table.current?.onRefreshTable();
                                    setState(state => ({...state, enabled: !enabled, count:0, temporarily: false}))
                                    resetRefreshAlert()
                                    }): () => {
                                    !enabled && table.current?.refresh(undefined, undefined, true);
                                    setState(state => ({...state, enabled: !enabled, count:0, temporarily: false}));
                                    table.current?.setLastBrowsedDateKey();
                                    resetRefreshAlert()
                                // @ts-ignore
                                }} checked={enabled} color={"secondary"} /></Tooltip>}
                            label={`${t(`AutoRefresh.title`)}`}
                            />
                    </FormGroup>
                </Grid>
                <Grid item>
                    {!enabled&&<Button onClick={() => checkDataChanged(()=> {
                        table.current?.onResetLastTabIndex();
                        setState(state => ({...state, count:0}));
                        table.current?.refresh(undefined, undefined, true);
                        table.current?.setLastBrowsedDateKey();
                    })} variant={"contained"} color={"secondary"} type={"button"} disabled={state.count===0}>{t("AutoRefresh.NewData")} ({count})</Button>
                }
                </Grid>
            </Grid>
        </Grid>
        {!props.hideItemAge ? <Grid style={{width: "min-content", flexGrow: 1, maxWidth: isLarge ? "400px" : "100%"}} item>
            <StandaloneField<FormItemAgeOptions>
                type={"Custom"}
                key={"ageFilter"}
                customComponent={FormItemAge}
                customComponentOptions={{
                    spacing: 1, defaultValue: props.defaultValue, lastBrowseDateDataKey: props.lastBrowseDateDataKey,
                    filterData: (data) => checkDataChanged(() => props.filterData(data))
                }}
            />
        </Grid> : null}
    </Grid>
};

const key = '_autorefresh';
// eslint-disable-next-line
export const AutoRefreshContext = <T extends object, F extends object>(props:PropsWithChildren<DG.Props<T>>&{arState?: MutableRefObject<AutoRefreshStateHandler>}) => {
    const {table, editing,  name} = useContext(DGContext);
    const allowAutorefresh = props.stomp?.toggleable;
    const { getTableCache } = useTableCache(props.id || props.endpoint, props?.cache?.group);

    const [state, setState] = useState<AutoRefreshState>(() => {
        const refreshState = allowAutorefresh ? (DataStorage.get(`${name}${key}`) ?? `false`) === 'true' : false;
        PubSub.publish('autorefreshChanged', refreshState);
        return {enabled: refreshState, count:0, totalAdded:0, temporarily: false, pushNotification: true};
    });
    const {enabled} = state;

    useEffect(() => {
        const lastCacheTimestamp = getTableCache()?.data?.timestamp;
        if(lastCacheTimestamp != null){
            table.current?.modifiedCount(lastCacheTimestamp).then(value => {
                setState(state => ({...state, count: value, totalAdded: 0}));
            })
        }
    }, []);

    const resetCounter = useCallback(() => {
        setState(state => ({...state, count: 0, totalAdded: 0}));
    }, [setState]);

    const decreaseCounter = useCallback(() => {
        setState(state => ({...state, count: state.count - 1}));
    }, [setState]);

    const disableAutorefresh = useCallback((temporarily: boolean = false) => {
        if (allowAutorefresh && enabled) {
            setState(state => ({...state, enabled: false, temporarily: temporarily, count: 0}));
        }
    }, [setState, enabled, allowAutorefresh]);


    const enableAutorefresh = useCallback((preventRefreshTable?: boolean) => {
        if (allowAutorefresh && !enabled && state.temporarily) {
            if (!preventRefreshTable) table.current?.onRefreshTable();
            setState(state => ({...state, enabled: true, count: 0, temporarily: false}));
        }
    }, [setState, enabled, table, state, allowAutorefresh])

    const fun = useCallback(async (data: CrudUpdateEvent) => {
        if (!allowAutorefresh) return;

        let isRemoveOperation = !data?.stompProcessingOnly && data?.crudOperationType === CrudOperationType.REMOVE;
        let isCreateOperation = !data?.stompProcessingOnly && data?.crudOperationType === CrudOperationType.CREATE;
        let isUpdateOperation = !data?.stompProcessingOnly && data?.crudOperationType === CrudOperationType.UPDATE;

        const count = await table.current?.modifiedCount();
        let newTotalAdded = state.totalAdded + ((isCreateOperation || isUpdateOperation) && count > state.count ? 1 : 0);

        if(!data?.stompProcessingOnly) {
            setState((state) => ({...state, count: count , totalAdded: newTotalAdded, pushNotification: !isRemoveOperation}));
        }

        await table.current?.refresh(enabled ? {calculateModifiedCount: false} : undefined, data)

        // eslint-disable-next-line
    }, [setState, table, editing, enabled]);

    const logic = useRateLimitedLogic({fun: fun, throttling: true, randomizeRateLimitDelay: true, randomizedInitialDelay: undefined});

    const stompCallback = useCallback((data: CrudUpdate) => {
            const stompProcData = CrudUpdateEvent.of(data,  true);

            if(props.stomp?.allowStompUiUpdates ?? false) {
                //immediate processing without server calls to make ui more responsive
                fun(stompProcData).then().catch((e) => {
                    console.error(e);
                });
            }

            if(data?.tabId!==getCurrentTabId() || (!enabled)) {
                //nominal processing with possible server call
                logic(false, CrudUpdateEvent.of(data, false)).then();
            }
        }, [logic, enabled, props.stomp, fun]
    );

    useStompSubscribe<CrudUpdate>(props.stomp?.topic, {clazz: CrudUpdate, callback: stompCallback, userOnly: props.stomp?.userOnly===true});

    /*Možná způsobovalo problémy s nastavení výchozího stavu
    useEffect(()=>{
        DataStorage.clear(`${name}${key}`);
    }, [name]);*/

    useEffect(()=>{
        if (allowAutorefresh) DataStorage.set(`${name}${key}`, enabled);
        // eslint-disable-next-line
    }, [enabled, name]);

    useEffect(() => {
        props.arState.current = {...state, setState, refresh: fun, resetCounter, disableAutorefresh, enableAutorefresh, decreaseCounter};
        PubSub.publish('autorefreshChanged', state.enabled);
        // eslint-disable-next-line
    }, [state, fun])

    return (
        <ARContext.Provider value={[state, setState]}>
            {props.children}
        </ARContext.Provider>
    );
};
