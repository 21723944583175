import {Box, Tooltip} from "@mui/material";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import * as React from "react";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {httpEndpointCustom} from "../common/utils/HttpUtils";

const useStyles = makeStyles((theme) =>
    createStyles({
        notification: {
            transition: "0.3s all ease",
            display: "flex",
            borderRadius: "50%",
            padding: "4px",
            marginRight: "10px",
        },
        notificationDisabled: {
            backgroundColor: theme.palette.type === 'dark' ? "#333333" : "#e6e6e6",
        },
        notificationEnabled: {
            backgroundColor: theme.palette.type === 'dark' ? "#b15800" : "#ffbf77",
        },
        notificationLocked: {
            backgroundColor: theme.palette.type === 'dark' ? "#bb0000" : "#ff4d4d",
        },
    }),
);

export function PushNotificationFlag({type}: { type: string }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [notificationEnabled, setNotificationEnabled] = useState(false);
    const [notificationLocked, setNotificationLocked] = useState(false);

    useEffect(() => {
        const result = httpEndpointCustom(`user/settings/${type}`,
            {
                method: "GET",
            }).then(response => {
            setNotificationEnabled(response.json === "true" || response.json === true);
        }).catch(error => {
            console.log('error', error)
        });
        // if (result?.response.status > 201) {
        //     showSnack({title: "Error", severity: "error"});
        // } else {
        //     console.log('result', result);
        // }
    }, []);

    const toggleNotification = () => {
        const newState = !notificationEnabled;
        const result = httpEndpointCustom(`user/settings/${type}`,
            {
                method: "PUT",
                body: String(newState),
            }).then(response => {
            setNotificationEnabled(newState);
            setNotificationLocked(false);
        }).catch(error => {
            console.log('error', error)
        });
    }

    return <Box
        className={`${classes.notification} ${notificationEnabled ? (notificationLocked ? classes.notificationLocked : classes.notificationEnabled) : classes.notificationDisabled}`}>
        <Tooltip
            title={t(notificationEnabled ? (notificationLocked ? "PushNotificationFlag.TooltipInactive" : "PushNotificationFlag.TooltipOn") : "PushNotificationFlag.TooltipOff")}
            key={notificationEnabled ? "notificationOn" : "notificationOff"}
            onClick={toggleNotification}
            placement={"bottom-start"}
        >
            {notificationEnabled && !notificationLocked ? <PhonelinkRingIcon fontSize={"small"}/> : <PhoneAndroidIcon fontSize={"small"}/>}
        </Tooltip>

    </Box>

}