import React from "react";
import {Waypoint} from "../../../web/model/Waypoint";
import {Vicinity} from "../../component/form/FormVicinityContainer";

export function calculateDokladkyOsmPlaceResultInForm(activeIsUserSetEmptyRef: React.MutableRefObject<boolean | null>, activeWaypointRef: React.MutableRefObject<Waypoint | null>, value: Vicinity | null): string | null {
    let osmPlaceResult: string | null = null;

    if (activeIsUserSetEmptyRef.current === true) {
        osmPlaceResult = null;
    } else {
        osmPlaceResult = activeWaypointRef?.current?.nazevMista ?? value?.osmPlace;
    }

    if (!osmPlaceResult && value) {
        osmPlaceResult = activeWaypointRef?.current?.nazevMista;
    }

    return osmPlaceResult;
}

export function calculateDokladkyOsmPlaceResultInColumn(activeIsUserSetEmptyRef: React.MutableRefObject<boolean | null>, activeWaypointRef: React.MutableRefObject<Waypoint | null>, value: Vicinity | null): string | null {
    let osmPlaceResult: string | null = null;

    // Pokud je aktivní příznak "prázdné okolí", vrátíme null
    if (activeIsUserSetEmptyRef.current === true) {
        osmPlaceResult = null;
    } else {
        // Jinak zkusíme načíst osmPlace z aktuální hodnoty
        osmPlaceResult = value?.osmPlace;
    }

    // Pokud není osmPlace a příznak "prázdné okolí" není aktivní, použijeme waypoint
    if (!osmPlaceResult && value && activeIsUserSetEmptyRef.current !== true) {
        osmPlaceResult = activeWaypointRef?.current?.nazevMista;
    }

    return osmPlaceResult;
}

export function calculateDokladkyRadiusResultInForm(osmPlaceResult: string | null, activeRadiusRef: React.MutableRefObject<number | null>): number | null {
    // Pokud existuje osmPlaceResult, vracíme hodnotu z activeRadiusRef, jinak null
    return osmPlaceResult ? (activeRadiusRef.current ?? null) : null;
}

export function calculateDokladkyRadiusResultInColumn(osmPlaceResult: string | null, activeRadiusRef: React.MutableRefObject<number | null>): number | null {
    // Pokud existuje osmPlaceResult, vracíme hodnotu z activeRadiusRef, jinak null
    return osmPlaceResult ? (activeRadiusRef.current ?? null) : null;
}


export function handleDokladkyOsmPlaceChange(tempValue: Vicinity, activeRadiusRef: React.MutableRefObject<number | null>, activeWaypointRef: React.MutableRefObject<Waypoint | null>, nullVicinity: {
    osmPlace: string | null;
    radius: string | null
}, setLocalVicinity: React.Dispatch<React.SetStateAction<{
    osmPlace: string | null;
    radius: string | null
}>>, onChange: (value: Vicinity | null) => void,): void {


    onChange(tempValue);

    if (tempValue == null) {
        setLocalVicinity(nullVicinity); // Nastavení na výchozí hodnotu

        if (activeRadiusRef?.current) {
            activeRadiusRef.current = null;
        }
        if (activeWaypointRef?.current) {
            activeWaypointRef.current = null;
        }

        return;
    }

    if (activeRadiusRef?.current) {
        activeRadiusRef.current = null;
    }

    // Nastavení lokální vicinity
    setLocalVicinity({
        osmPlace: tempValue.osmPlace ?? null, radius: null, // Radius zůstává null
    });
}

export function handleDokladkyRadiusChangeInForm(v: number | null, props: any, name: string, activeIsEmptyOkoliLastSearchRef: React.MutableRefObject<boolean | null>, activeRadiusRef: React.MutableRefObject<number | null>, defaultValue: Vicinity): Vicinity {
    let tmpValue: Vicinity;

    // Pokud uživatel dříve vyčistil okolí a radius se mění, nastavíme příznak na false
    if (activeIsEmptyOkoliLastSearchRef?.current === true && props.data[name]?.radius !== v) {
        activeIsEmptyOkoliLastSearchRef.current = false;
    }

    // Pokud se radius mění, aktualizujeme hodnotu
    if (props.data[name]?.radius !== v) {
        tmpValue = activeIsEmptyOkoliLastSearchRef?.current === true ? {
            ...props.data[name],
            radius: v
        } : {...props.data[name], ...defaultValue, radius: v};
    } else {
        tmpValue = {...props.data[name], radius: v};
    }

    // Uložíme nový radius do referenční proměnné
    activeRadiusRef.current = v;

    return tmpValue;
}

export function handleDokladkyRadiusChangeInColumn(v: number | null, value: Vicinity, activeIsEmptyOkoliLastSearchRef: React.MutableRefObject<boolean | null>, activeRadiusRef: React.MutableRefObject<number | null>, defaultValue: Vicinity): Vicinity {
    let tmpValue: Vicinity;

    // Pokud uživatel dříve vyčistil okolí a radius se mění, nastavíme příznak na false
    if (activeIsEmptyOkoliLastSearchRef?.current === true && value?.radius !== v) {
        activeIsEmptyOkoliLastSearchRef.current = false;
    }

    // Pokud se radius mění, aktualizujeme hodnotu
    if (value?.radius !== v) {
        tmpValue = activeIsEmptyOkoliLastSearchRef?.current === true ? {
            ...value,
            radius: v
        } : {...value, ...defaultValue, radius: v};
    } else {
        tmpValue = {...value, radius: v};
    }

    // Uložíme nový radius do referenční proměnné
    activeRadiusRef.current = v;

    return tmpValue;
}