import React, {ReactElement} from 'react';
import { Avatar, createStyles, Grid, ListItemAvatar, ListItemText, Theme, Tooltip, useMediaQuery } from '@material-ui/core';
import {isMobile} from 'react-device-detect';
import Phone from '@material-ui/icons/Phone';
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {invoke} from "../utils/Invoke";
import { PhoneForwarded, PhoneInTalk, Sms, WhatsApp } from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import { pushNotificationCall } from '../../web/MobileApp';
import { useAppContext } from '../../web/context/AppContext';
import {exist, formatPrice, isMobileDevice, parsePhoneNumberLocal} from '../utils/Util';
import {useCodeBookDetailContextNew} from "../../web/raal_components/controller/CodeBookDetail";
import {Preprava} from "../../web/model/Preprava";
import {Vozidlo} from "../../web/model/Vozidlo";
import numeral from "numeral";
import {sizeViewFormat} from "../../web/page/Dials/user/_vp/PrepravaAVozidlaShared";
import {useTranslationByLangAndParam} from "../../web/raal_components/LanguageUtil";
import {useCiselnikValues} from "../../web/context/DataContext";
import {CiselnikTyp} from "../../web/model/Ciselnik";
import {getLanguageCode} from "./EmailClient";
import {useLocation} from "react-router";
import {InzeratViewDetail} from "../../web/model/Inzerat";
import {VPAbstract} from "../../web/model/PrepravaVozidlo";
import {httpEndpointCustom} from "../utils/HttpUtils";

const useStylesIcon = makeStyles((theme:Theme) =>
    createStyles({
        phoneNumberDial: {
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                left: theme.spacing(0)
            },
            '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
                left: theme.spacing(0)
            }
        },
        phoneNumberDialRelative: {
            position: "relative"
        },
        actions: {
            position: 'absolute',
            top: "-3.0rem",
			left: "2rem",
			zIndex: 100
        },
        actionsMobile: {
            flexWrap: "wrap",
            width: "10rem",
            position: 'absolute',
            top: "-4rem",
            left: "2rem",
            zIndex: 100
        },
        fab: {
            backgroundColor: "#2eac00",
            '&:hover': {
                backgroundColor: "#2eac00"
            },
            width: "2.5rem",
            height: "2.5rem",
        },
        root: {
            zIndex: 1
        },
        phoneItem: {
            width: "3.5rem",
            position: "relative"
        },
        phoneNumberContainer: {
            position: "relative"
        },
        viber: {
            textDecoration: "none !important",
            color: "rgba(0, 0, 0, 0.54) !important",
            width: 24,
            height: 24,
            zIndex: 50
        },
        iconColor: {
            color: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
        }
    }),
);

const useStylesText = makeStyles((theme) =>
    createStyles({
        fab: {
            color: "#1976d2",
            textDecoration: "underline",
            width: "auto",
            height: "auto",
            backgroundColor: "transparent",
            '&:hover': {
                backgroundColor: "transparent",
                color: "#1976d2",
                textDecoration: "underline",
                border: 0,
                boxShadow: "none"
            },
            '&:active': {
                backgroundColor: "transparent",
                color: "#1976d2",
                textDecoration: "underline",
                border: 0,
                boxShadow: "none"
            },
            border: 0,
            boxShadow: "none",
            zIndex: 5,
            transition: "none !important"
        },
        actions: {
            position: 'absolute',
            left: "5.5rem",
            zIndex: 10
        },
        phoneItem: {
            position: "relative"
        },
        iconColor: {
            color: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
        }
    }),
);

type Button = {
    text: string,
    icon: ReactElement,
    call: () => void
}

type CurrentPage = 'PREPRAVY' | 'VOLNEVOZY' | '';

export const PhoneNumberDial = (
    {phoneNumber = '-', showDials = true, hideIcon = false, hidePhoneNumber = false}
        :
        {phoneNumber?:string, showDials?: boolean, hideIcon?: boolean, hidePhoneNumber?: boolean}) => {
    const {t} = useTranslation();
    const { t2 } = useTranslationByLangAndParam();
	const {user} = useAppContext();
    const classes = useStylesIcon();
    const classesText = useStylesText();
    const [open, setOpen] = React.useState(false);
    const codeBookDetailContext = useCodeBookDetailContextNew<Preprava | Vozidlo>();
    const data = codeBookDetailContext ? codeBookDetailContext.data : null;
    const {druhyJoined} = useCiselnikValues(CiselnikTyp.R);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const generateDesktopLink = (number: string) => {
        return `viber://chat?number=${encodeURIComponent(
            number.replace(/[^0-9+]/g, '')
        )}`
    }

    const getPrice = () => {
        const price = data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? null;
        return data.jednotka ? price + "/" + data.jednotka : price
    }

    const buttons: Button[] = [
        {text: t("Contacts.Call"), icon: <PhoneForwarded/>, call: () => {
            window.open("tel:" + phoneNumber, '_self');
        }},
        {text: t("Contacts.SMS"), icon: <Sms />, call: () => {
            window.open("sms:" + phoneNumber, '_self');
        }},
        {text: t("Contacts.WhatsApp"), icon: <WhatsApp />, call: () => {
            window.open("https://wa.me/" + phoneNumber.substr(1) + '?text=' + sendWhatsAppMessage(), '_blank');
        }},
        {text: t("Contacts.Viber"), icon: <PhoneInTalk />, call: () => {
            window.location.href = generateDesktopLink(phoneNumber);
        }}
    ];

	const phoneNumberCall = (e: MouseEvent | any) => {
		// Když má uživatel zaregistrované telefonní číslo, tak má i token. A tomhle případě rovnou poslat push notifikaci pro vytočení tel. čísla na mobilu.
		if (user.fcmToken && !isMobile) {
			e.preventDefault();
			(async () => {
				try {
					await pushNotificationCall(phoneNumber);
				} catch (e) {
					console.error("Failed to execute async function", e);
				}
			})();
			e.stopPropagation();
		}
	}

    const sendWhatsAppMessage = () => {
        if (exist(data) && (data instanceof InzeratViewDetail || data instanceof VPAbstract)) {
            let body = '';
            const lang = getLanguageCode(data?.uzivatel?.provozovna?.pStat);
            if (data instanceof InzeratViewDetail) {
                let inzeratData: InzeratViewDetail = data as InzeratViewDetail;
                let bodyBegin = `${t2("Mail.InzeratBodyBegin", lang, [user.provozovna.kod])}`;
                body = `
${bodyBegin}

${t2("Inzerce.AdText", lang)}: ${inzeratData.popis ? inzeratData.popis : '-'}
${t2("Inzerce.Price", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("PVI.Modified", lang)}: ${inzeratData.modifiedOn ? inzeratData.modifiedOn?.format("L LT") : '-'}
`.trim();
            } else {
                let bodyBegin = '';
                if (data instanceof Preprava) {
                    bodyBegin = `${t2("Mail.PrepravaBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
                }
                if (data instanceof Vozidlo) {
                    bodyBegin = `${t2("Mail.VozidloBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
                }

                body = `
${bodyBegin}

${t2("Mail.KodZadavateleNabidky", lang)}: ${data.uzivatel?.provozovna?.kod}
${t2("Preprava.odkud", lang)}: ${data.regOd}:${data.pscOdkud}:${data.odkud}
${t2("Preprava.kam", lang)}: ${data.regKam}:${data.pscKam}:${data.kam}
${t2("RAALKratkyKody.N", lang)} / ${t2("RAALKratkyKody.S", lang)} / ${t2("RAALKratkyKody.J", lang)}: ${data.naves ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${data.souprava ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${data.jine ? t2("YesNo.true", lang) : t2("YesNo.false", lang)}
${t2("Preprava.delka", lang)}: ${data.delka ? numeral(data.delka).format(sizeViewFormat) : '-'}
${t2("Preprava.vaha", lang)}: ${data.vaha ? numeral(data.vaha).format(sizeViewFormat) : '-'}
${t2("Preprava.druhy", lang)}: ${data.druhy && data.druhy.length > 0 ? druhyJoined(data.druhy) : '-'}
${t2("Preprava.datOd", lang)}: ${data.datOd?.format("L LT")}
${t2("Preprava.datDo", lang)}: ${data.datDo?.format("L LT")}
${t2("Preprava.cena", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("Preprava.verejnaPozn", lang)}: ${data.verejnaPozn ? data.verejnaPozn : '-'}
`.trim();
            }
            const encodedBody = encodeURIComponent(body);

            return encodedBody;
        } else {
            return '';
        }
    }

    return (
        <Grid container justifyContent={"flex-start"} alignItems={"center"}>
            <Grid item >
                <Grid container justifyContent={"flex-start"} alignItems={"center"} className={classesText.phoneItem}>
					{!hideIcon && <ListItemAvatar><Avatar className={"avatar-contact"}><Phone/></Avatar></ListItemAvatar>}
                    <SpeedDial
                        ariaLabel="phone"
                        className={showDials ? classes.phoneNumberDial : classes.phoneNumberDialRelative}
                        classes={{
                            root: classes.root,
                            actions: isDesktop || hideIcon ? hideIcon ? classesText.actions : classes.actions : classes.actionsMobile,
                            fab: classesText.fab
                        }}
                        icon={
							<Grid item>
								<Grid container alignItems={"center"} className={classes.phoneNumberContainer}>
									{!hideIcon &&
										<ListItemText>
                                            {showDials ? hidePhoneNumber ? '' :
                                                <a href={`tel.:${phoneNumber}`} style={{userSelect: "text"}}
                                                   onClick={(e: any) => phoneNumberCall(e)}>{parsePhoneNumberLocal(phoneNumber)}</a> : '-'}
                                        </ListItemText>
                                    }
                                    {hideIcon &&
                                        <span>
											<a href={`tel.:${phoneNumber}`} style={{userSelect: "text"}} onClick={(e: any) => phoneNumberCall(e)}>{parsePhoneNumberLocal(phoneNumber)}</a>
										</span>
									}
								</Grid>
							</Grid>
						}
                        onClose={handleClose}
                        onOpen={(_, reason) => {
                            if (reason === 'focus') {
                                return
                            }
                            setOpen(true)
                        }}
                        onClick={(e) => {
                            e.preventDefault();
							phoneNumberCall(e);
							handleOpen();
                            e.stopPropagation();
                        }}
                        open={open}
                        direction={"right"}>
                        { showDials && buttons.map((btn, index) => (
                            <SpeedDialAction
                                title={btn.text}
                                key={index}
                                icon={btn.icon}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleClose();
                                    invoke(btn.call)
                                }}
                            />
                        ))}
                    </SpeedDial>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const InlinePhoneNumberDial = (
    {phoneNumber = '-', showDials = true, hideIcon = false, hidePhoneNumber = false, rowData, isInzerce}
        :
        {phoneNumber?:string, showDials?: boolean, hideIcon?: boolean, hidePhoneNumber?: boolean, rowData: any, isInzerce?: boolean}) => {
    const {t} = useTranslation();
    const { t2 } = useTranslationByLangAndParam();
    const {user} = useAppContext();
    const classes = useStylesIcon();
    const classesText = useStylesText();
    const [open, setOpen] = React.useState(false);
    const {druhyJoined} = useCiselnikValues(CiselnikTyp.R);
    const {pathname} = useLocation();
    const currentPage: CurrentPage = pathname.includes('/prepravy') ? 'PREPRAVY' : pathname.includes('/volnevozy') ? 'VOLNEVOZY' : '';

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const generateDesktopLink = (number: string) => {
        return `viber://chat?number=${encodeURIComponent(
            number.replace(/[^0-9+]/g, '')
        )}`
    }

    const getPrice = () => {
        const price = rowData.cena && rowData.currency?.currencyCode ? formatPrice(rowData.cena, rowData.currency.currencyCode) : rowData.cena ?? null;
        return rowData.jednotka ? price + "/" + rowData.jednotka : price;
    }

    const buttons: Button[] = [
        {text: t("Contacts.Call"), icon: <PhoneForwarded/>, call: () => {
                window.open("tel:" + phoneNumber, '_self');
            }},
        {text: t("Contacts.SMS"), icon: <Sms />, call: () => {
                window.open("sms:" + phoneNumber, '_self');
            }},
        {text: t("Contacts.WhatsApp"), icon: <WhatsApp />, call: async () => {
                const message = await sendWhatsAppMessage();
                window.open("https://wa.me/" + phoneNumber.substr(1) + '?text=' + message, '_blank');
            }},
        {text: t("Contacts.Viber"), icon: <PhoneInTalk />, call: () => {
                window.location.href = generateDesktopLink(phoneNumber);
            }}
    ];

    const phoneNumberCall = (e: MouseEvent | any) => {
        // Když má uživatel zaregistrované telefonní číslo, tak má i token. A tomhle případě rovnou poslat push notifikaci pro vytočení tel. čísla na mobilu.
        if (user.fcmToken && !isMobile) {
            e.preventDefault();
            (async () => {
                try {
                    await pushNotificationCall(phoneNumber);
                } catch (e) {
                    console.error("Failed to execute async function", e);
                }
            })();
            e.stopPropagation();
        }
    }

    const sendWhatsAppMessage = async () => {
        let body = '';
        let lang = 'DEFAULT'

        const provozovnaLangResponse = await httpEndpointCustom(`user/provozovnainfo/getstat?kod=${rowData.provozovna}`)

        if (provozovnaLangResponse.response.ok) {
            lang = getLanguageCode(provozovnaLangResponse.json.pstat);
        }

        if (isInzerce) {
            let bodyBegin = `${t2("Mail.InzeratBodyBegin", lang, [user.provozovna.kod])}`;
            body = `
${bodyBegin}
                        
${t2("Inzerce.AdText", lang)}: ${rowData.popis ? rowData.popis : '-'}
${t2("Inzerce.Price", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("PVI.Modified", lang)}: ${rowData.modifiedOn ? rowData.modifiedOn?.format("L LT") : '-'}
`.trim();
        } else {
            let bodyBegin = '';
            if (currentPage == "PREPRAVY") {
                bodyBegin = `${t2("Mail.PrepravaBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
            }
            if (currentPage == "VOLNEVOZY") {
                bodyBegin = `${t2("Mail.VozidloBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
            }

            body = `
${bodyBegin}
                        
${t2("Mail.KodZadavateleNabidky", lang)}: ${rowData.provozovna}
${t2("Preprava.odkud", lang)}: ${rowData.regOd}:${rowData.pscOdkud}:${rowData.odkud}
${t2("Preprava.kam", lang)}: ${rowData.regKam}:${rowData.pscKam}:${rowData.kam}
${t2("RAALKratkyKody.N", lang)} / ${t2("RAALKratkyKody.S", lang)} / ${t2("RAALKratkyKody.J", lang)}: ${rowData.naves ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${rowData.souprava ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${rowData.jine ? t2("YesNo.true", lang) : t2("YesNo.false", lang)}
${t2("Preprava.delka", lang)}: ${rowData.delka ? numeral(rowData.delka).format(sizeViewFormat) : '-'}
${t2("Preprava.vaha", lang)}: ${rowData.vaha ? numeral(rowData.vaha).format(sizeViewFormat) : '-'}
${t2("Preprava.druhy", lang)}: ${rowData.druhy && rowData.druhy.length > 0 ? druhyJoined(rowData.druhy) : '-'}
${t2("Preprava.datOd", lang)}: ${rowData.datOd?.format("L LT")}
${t2("Preprava.datDo", lang)}: ${rowData.datDo?.format("L LT")}
${t2("Preprava.cena", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("Preprava.verejnaPozn", lang)}: ${rowData.verejnaPozn ? rowData.verejnaPozn : '-'}
                    `.trim();
        }

        return encodeURIComponent(body);
    }

        return (
            <Grid>
                <Grid item>
                    <Grid className={classesText.phoneItem}>
                        {!hideIcon && <ListItemAvatar><Avatar className={"avatar-contact"}><Phone/></Avatar></ListItemAvatar>}
                        <SpeedDial
                            ariaLabel="phone"
                            className={showDials ? classes.phoneNumberDial : classes.phoneNumberDialRelative}
                            classes={{
                                root: classes.root,
                                actions: isDesktop || hideIcon ? hideIcon ? classesText.actions : classes.actions : classes.actionsMobile,
                                fab: classesText.fab
                            }}
                            icon={
                                <Grid item>
                                    <Grid className={classes.phoneNumberContainer}>
                                        {!hideIcon &&
                                            <ListItemText>
                                                <a href={`tel.:${phoneNumber}`} onClick={(e: any) => phoneNumberCall(e)}>{parsePhoneNumberLocal(phoneNumber)}</a>
                                            </ListItemText>
                                        }
                                        {hideIcon &&
                                            <span>
											<a href={`tel.:${phoneNumber}`} onClick={(e: any) => phoneNumberCall(e)}>{parsePhoneNumberLocal(phoneNumber)}</a>
										</span>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            onClose={handleClose}
                            onOpen={(_, reason) => {
                                if (reason === 'focus') {
                                    return
                                }
                                setOpen(true)
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                phoneNumberCall(e);
                                handleOpen();
                                e.stopPropagation();
                            }}
                            open={open}
                            direction={"right"}>
                            { showDials && buttons.map((btn, index) => (
                                <SpeedDialAction
                                    title={btn.text}
                                    key={index}
                                    icon={btn.icon}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleClose();
                                        invoke(btn.call)
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Grid>
                </Grid>
            </Grid>
        )
}
