import * as React from "react";
import {useRef} from "react";
import {FormInputType} from "../../../../raal_components/form/Form";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {Vozidlo} from "../../../../model/Vozidlo";
import {
    exportableFields,
    exportableFieldsAdminView,
    exportableFieldsView,
    getExportableProps,
    getFilterVersion,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {VPFilter, VPList, VPListPrepravaAdmin, VPListVozidloAdmin} from "../../../../model/PrepravaVozidlo";
import {useViewColumns} from "../_vp/VPColumns";
import {FilterForm} from "../_vp/VPFilter";
import {ExtendedView, ExtendedViewNew} from "../_vp/VPExtendedView";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {TFunction} from "i18next";
import {SelectProps} from "../../../../../common/component/form/FormSelect";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {useEnumCiselnikSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {GenericMap} from "../../../../../index.d";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {globalStyles, useThemeContext} from "../../../../context/ThemeModeContext";
import {InvalDuvodVozidlo} from "../../../../model/CommonTypes";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {SystemParamKey} from "../../../../model/SystemParameter";
import {exist} from "../../../../../common/utils/Util";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useAppContext} from "../../../../context/AppContext";
import {DetailFormNew} from "../_vp/VPDetailForm";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ComponentMode} from "../../../../routes";
import {VolneVozyViewAdminPartNew} from "./VolneVozyViewAdminPart";
import {VolneVozyViewHistoryPartNew} from "./VolneVozyViewHistoryPart";
import Cancel from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {invalidateVozidlo, validateVozidlo} from "../../../../../common/logic/vozidlo-logic";
import {useClearVozidloViewRecords, useZaznamOznaceniStyle } from "../Oznaceni";

export const CISELNIK_DRUH = CiselnikTyp.R;
export const PARAM_RANGE_KEY = SystemParamKey.VOZIDLA_DATE_RANGE;
export const PARAM_ADVANCE_KEY = SystemParamKey.VOZIDLO_DATE_ADVANCE_MAX;
export const DEFAULT_RANGE_VALUE = 7;
export const DEFAULT_ADVANCE_VALUE = 15;
export const PARAM_MAX_DRUHU_KEY = SystemParamKey.VOZIDLA_DRUHY_MAX;

export interface VlastniVozyCiselnikViewProps {
    admin?: boolean;
    archive?: boolean;
    viewArchive?: boolean,
    filter?: GenericMap;
    isTabbed?: boolean;
    pvi?: boolean
    mode?: ComponentMode
    /**
     * Defines custom base path.
     */
    basePath?: string
    minimumTableHeight?: number
    id?: string
    cacheGroup?: string
}

export function prepareCols(createColumns: () => Column<VPList<any>>[], admin: boolean, archive: boolean, viewArchive: boolean, historyView: boolean, t: TFunction, yesNoSelect: SelectProps<any>, locFun: (locKey: string, en: any, code: any) => string, invalDuvSelect: { formatOption: (value: any) => { label: string; value: any }; formatValue: (value: any) => any; options: string[] }) {
    let addCols = createColumns() as any;
    if (admin) {
        addCols.push(
            {
                title: t("PVI.Valid"),
                field: 'valid',
                render: (data: VPListVozidloAdmin) => <CheckMark checked={data.valid}/>,
                filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
            },
            {
                title: t("PVI.InvalDuv"),
                field: 'invalDuv',
                render: (row: VPListVozidloAdmin) => locFun("Enumerations.InvalDuvodVozidlo", InvalDuvodVozidlo, row.invalDuv),
                filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                cellStyle: {minWidth: 180},
                headerStyle: {minWidth: 180}
            },
            {
                title: t("User.Title"),
                field: 'uzivatelText',
                render: (data: VPListPrepravaAdmin) => data.uzivatel?.toString() ?? "",
                filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                cellStyle: {minWidth: 180},
                headerStyle: {minWidth: 180}
            }
        );
        if (archive && !historyView) {
            addCols.push(
                {
                    title: t("Archive.Archived"),
                    field: 'deletedOn',
                    render: (data: VPListPrepravaAdmin) => (data.deletedOn)?.format("L LT") ?? "",
                    defaultSort: "desc",
                    filterProps: () => ({
                        type: FormInputType.Custom,
                        customComponent: FormDateRange,
                        customComponentOptions: {timeFormat: false},
                        name: 'deletedOnRange'
                    }),
                    cellStyle: {minWidth: 150},
                    headerStyle: {minWidth: 150},
                }
            );
        }
    }
    return addCols;
}

/**
 * TODO - Přepsáno do New, ale uvnitř není stejná logika jako v ExtendedViewNew, bude třeba ještě projít a sloučit.
 * @param props
 * @constructor
 */
export function VlastniVozyCiselnikView(props: VlastniVozyCiselnikViewProps) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const refreshFun = useRef(() => {
    });
    const oznacenoFilter = useRef(!props.admin);
    const [createColumns] = useViewColumns(CISELNIK_DRUH, oznacenoFilter.current, refreshFun, props.archive ? undefined : "user/vozidlo", props.admin ? ["datIns"] : [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, props.admin, {
        archive: props.archive,
        viewArchive: props.viewArchive,
        typ: NabidkaType.VOZIDLO,
        refactor: true
    }, true);
    const dateKey = "vozy-view-browse-date";
    const endpoint = props.archive && props.viewArchive ? (props.admin ? "admin/vozidlo-prohlizeni-archiv" : "user/vozidlo-prohlizeni-archiv") :
        props.archive && !props.viewArchive ? (props.admin ? "admin/vozidlo-archiv" : "user/vozidlo-archiv") :
            (props.admin ? "admin/vozidloview" : "user/vozidloview");
    const invalDuvSelect = useEnumCiselnikSelect({
        isClearable: true,
        ciselnikTyp: CiselnikTyp.V,
        enm: InvalDuvodVozidlo,
        enmName: "Enumerations.InvalDuvodVozidlo"
    });
    const yesNoSelect = useYesNoSelect({isClearable: true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const dtGrid = useRef<DataGridExposed<VPListVozidloAdmin, VPFilter>>();
    const [showConfirm] = useConfirmDialog();
    const prepravaId = useHashId();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user, false);
    const userArchiveZadani = !props.viewArchive && props.archive && !props.admin
    const allowStomp = !props.archive && !props.viewArchive;
    const clearVozidloViewRecords =
        useClearVozidloViewRecords(() => {dtGrid.current?.table()?.refresh({}, null, true)})
    const nabidkaOznaceni = useZaznamOznaceniStyle(endpoint);

    return <Dial<VPListVozidloAdmin, VPFilter, Vozidlo>
        mode={props.mode}
        isTabbed={props.isTabbed}
        tabDetailUrl= {props.basePath ? props.basePath : undefined}
        lastBrowsedDateKey={dateKey}
        isModal={!props.admin && !exist(prepravaId)}
        logActivity
        gridRef={dtGrid}
        onGridMount={() => {
            refreshFun.current = () => dtGrid.current?.table()?.refresh();
            oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
        }}

        onFilterChanged={(data) => {
            oznacenoFilter.current = data?.oznaceno ?? false;
        }}
        getModificationDate={data => data.modifiedOn}

        config={{
            id: props.id,
            cache: {group: props.cacheGroup},
            initialPageSize: 100,
            minimumTableHeight: props.minimumTableHeight,
            lockSupport: {enabled: false},
            requiredColumns: ['odkud', 'kam'],
            overflowHidden: true,
            watchChanges: !props.admin && !props.archive && !props.viewArchive,
            defaultHiddenColumns: ['palety', 'sirka', 'vyska', 'lozPlocha', 'objem', 'klasifikace'],
            alternativeButtons: props.admin ? undefined : [clearVozidloViewRecords],
            exportConfig: {
                exportable: true,
                exportDetailOnly: !((props.archive && !props.viewArchive) || props.admin),
                exportAll: props.admin,
                endpoint: endpoint,
                fileName: props.archive || props.viewArchive ? "export_archiv_volne_vozy" : "export_volne_vozy",
                translationPrefix: ['Preprava', 'User', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: getExportableProps(userArchiveZadani ? exportableFields : props.admin ? exportableFieldsAdminView : exportableFieldsView, props.archive || props.viewArchive),
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod,
                                ...(props.archive || props.viewArchive) && {archived: props.viewArchive ? data.datIns : data.deletedOn}
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodVozidlo", InvalDuvodVozidlo, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: (props.admin) ? {
                rowStyle: (data: any) => {
                    if (!data.valid)
                        return globalStyles.rowStyleAlert;
                    if (data.stav === InvalDuvodVozidlo.DUPLICITA && props.admin) {
                        return globalStyles.rowStyleDuplicated;
                    }
                    return undefined;
                }
            } : {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = nabidkaOznaceni.getStyle(data);
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            actions: props.admin && !props.archive ? [(data:VPListVozidloAdmin) => ({
                hidden: data?.invalDuv===InvalDuvodVozidlo.ZNEPLATNENO_OPERATOREM,
                icon: () => <Cancel/>,
                onClick: async () => {
                    showConfirm({
                        body: t("Preprava.InvalidVozidloConfirm"),
                        onConfirm: async () => {
                            try {
                                dtGrid.current.table().setLoading(true);
                                await invalidateVozidlo(data.id);
                                dtGrid.current.table().refresh();
                            }  finally {
                                dtGrid.current.table().setLoading(false);
                            }
                        }
                    });
                }
            }),
                (data:VPListVozidloAdmin) => ({
                    hidden: data?.invalDuv!==InvalDuvodVozidlo.ZNEPLATNENO_OPERATOREM,
                    icon: () => <Check/>,
                    onClick: () => {
                        showConfirm({
                            body: t("Preprava.ValidVozidloConfirm"),
                            onConfirm: async () => {
                                try {
                                    dtGrid.current.table().setLoading(true);
                                    await validateVozidlo(data.id);
                                    dtGrid.current.table().refresh();
                                } finally {
                                    dtGrid.current.table().setLoading(false);
                                }
                            }
                        });
                    }
                })
            ] : [],
            stomp: allowStomp && {
                topic: props.pvi ? `/provozovna/${user.provozovna.kod}/crud-vozidlo` : `/crud-vozidlo`,
                toggleable: !props.admin,
                allowStompUiUpdates: true
            },
            version: getFilterVersion(),
            hideDefaultDummyAction: false,
            tableTitle: t("Dials.VolneVozyProhlizet").toUpperCase(), endpoint: endpoint, clazz: VPListVozidloAdmin, filtering: true,
            hideAddNewActions: true,
            defaultQueryParameters: props.filter,
            columns: prepareCols(createColumns, props.admin, props.archive, props.viewArchive, false, t, yesNoSelect, locFun, invalDuvSelect),
        }}

        hideSaveButton={true}
        hideNewButtonOnEdit={true}
        crudConfig={{editEnabled: false, removeEnabled: props.viewArchive, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{
            lastBrowseDateDataKey: dateKey,
            typCiselniku: CISELNIK_DRUH,
            showOznaceno: true,
            preprava: false,
            viewing: true,
            defaultRange: DEFAULT_RANGE_VALUE,
            typParametru: PARAM_RANGE_KEY,
            typAdvance: PARAM_ADVANCE_KEY,
            defaultAdvance: DEFAULT_ADVANCE_VALUE,
            admin: props.admin,
            archive: props.archive,
            archiveZadani: userArchiveZadani
        }}/>}
        modalHeaderName={() => t("Dials.VolneVozy")}
        filterClazz={VPFilter}
        clazzDetail={Vozidlo}
        layoutDetail={() => <TabHelperNew<Vozidlo> render={(data) =>
            <>
                {!userArchiveZadani ?
                    <ExtendedView ciselnikTyp={CISELNIK_DRUH} data={data} admin={props.admin} archive={props.archive}
                                  viewArchive={props.viewArchive}/> : undefined}
                {props.admin ? <VolneVozyViewAdminPartNew vozidlo={data} archive={props.archive}/> : undefined}
            </>}/>}
        layoutForm={userArchiveZadani ? () => <DetailFormNew<Vozidlo> {...{
            typParametru: PARAM_RANGE_KEY,
            typCiselniku: CISELNIK_DRUH,
            preprava: false,
            defaultRange: DEFAULT_RANGE_VALUE,
            maxDruhyKey: PARAM_MAX_DRUHU_KEY,
            advanceParam: PARAM_ADVANCE_KEY,
            defaultAdvanceValue: DEFAULT_ADVANCE_VALUE,
            archiveZadani: true
        }}/> : undefined}
        isDetailReadOnly={userArchiveZadani}
        formDisabled={() => userArchiveZadani}
        tabs={[
            props.admin || userArchiveZadani ? {
                title: t("Preprava.History"),
                render: () => <TabHelperNew<Vozidlo>
                    render={(data) => <VolneVozyViewHistoryPartNew vozidlo={data} archive={props.archive}
                                                                admin={props.admin} mode={ComponentMode.GridMode}/>}/>
            } : undefined
        ].filter(i => i)}
    />;
}

export interface VlastniVozyCiselnikViewPropsNew {
    admin?: boolean;
    filter?: GenericMap;
    isTabbed?: boolean;
    pvi?: boolean
    mode?: ComponentMode
}

export function VolneVozyCiselnikViewNew(props: VlastniVozyCiselnikViewPropsNew) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const refreshFun = useRef(() => {
    });
    const oznacenoFilter = useRef(!props.admin);
    const [createColumns] = useViewColumns(CISELNIK_DRUH, oznacenoFilter.current, refreshFun, "user/vozidlo", props.admin ? ["datIns"] : [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, props.admin, {
        typ: NabidkaType.VOZIDLO,
        refactor: true
    });
    const dateKey = props.admin ? "vozy-admin-view-browse-date" : "vozy-view-browse-date";
    const endpoint = props.admin ? "admin/vozidloview" : "user/vozidloview";
    const invalDuvSelect = useEnumCiselnikSelect({
        isClearable: true,
        ciselnikTyp: CiselnikTyp.V,
        enm: InvalDuvodVozidlo,
        enmName: "Enumerations.InvalDuvodVozidlo"
    });
    const yesNoSelect = useYesNoSelect({isClearable: true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const dtGrid = useRef<DataGridExposed<VPListVozidloAdmin, VPFilter>>();
    const [showConfirm] = useConfirmDialog();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user, false);
    const allowStomp = !props.admin;
    const clearVozidloViewRecords =
        useClearVozidloViewRecords(() => { dtGrid.current?.table()?.refresh()})
    const {mode} = useThemeContext();
    const nabidkaOznaceni = useZaznamOznaceniStyle(endpoint);

    return <Dial<VPListVozidloAdmin, VPFilter, Vozidlo>
        mode={props.mode}
        lastBrowsedDateKey={dateKey}
        logActivity
        gridRef={dtGrid}
        onGridMount={() => {
            refreshFun.current = () => dtGrid.current?.table()?.refresh();
            oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
        }}

        onFilterChanged={(data) => {
            oznacenoFilter.current = data?.oznaceno ?? false;
        }}
        getModificationDate={data => data.modifiedOn}

        config={{
            lockSupport: {enabled: false},
            requiredColumns: ['odkud', 'kam'],
            overflowHidden: true,
            watchChanges: !props.admin,
            alternativeButtons: props.admin ? undefined : [clearVozidloViewRecords],
            exportConfig: {
                exportable: true,
                exportDetailOnly: !props.admin,
                exportAll: props.admin,
                endpoint: endpoint,
                fileName: "export_volne_vozy",
                translationPrefix: ['Preprava', 'User', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: getExportableProps(props.admin ? exportableFieldsAdminView : exportableFieldsView),
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodVozidlo", InvalDuvodVozidlo, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: (props.admin) ? {
                rowStyle: (data: any) => {
                    if (!data.valid)
                        return globalStyles.rowStyleAlert;
                    if (data.stav === InvalDuvodVozidlo.DUPLICITA && props.admin) {
                        return globalStyles.rowStyleDuplicated;
                    }
                    return undefined;
                }
            } : {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = nabidkaOznaceni.getStyle(data);;
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            actions: props.admin ? [(data:VPListVozidloAdmin) => ({
                hidden: data?.invalDuv===InvalDuvodVozidlo.ZNEPLATNENO_OPERATOREM,
                icon: () => <Cancel/>,
                onClick: async () => {
                    showConfirm({
                        body: t("Preprava.InvalidVozidloConfirm"),
                        onConfirm: async () => {
                            try {
                                dtGrid.current.table().setLoading(true);
                                await invalidateVozidlo(data.id);
                                dtGrid.current.table().refresh();
                            }  finally {
                                dtGrid.current.table().setLoading(false);
                            }
                        }
                    });
                }
            }),
                (data:VPListVozidloAdmin) => ({
                    hidden: data?.invalDuv!==InvalDuvodVozidlo.ZNEPLATNENO_OPERATOREM,
                    icon: () => <Check/>,
                    onClick: () => {
                        showConfirm({
                            body: t("Preprava.ValidVozidloConfirm"),
                            onConfirm: async () => {
                                try {
                                    dtGrid.current.table().setLoading(true);
                                    await validateVozidlo(data.id);
                                    dtGrid.current.table().refresh();
                                } finally {
                                    dtGrid.current.table().setLoading(false);
                                }
                            }
                        });
                    }
                })
            ] : [],
            stomp: allowStomp && {
                topic: props.pvi ? `/provozovna/${user.provozovna.kod}/crud-vozidlo` : `/crud-vozidlo`,
                toggleable: !props.admin,
                allowStompUiUpdates: true
            },
            version: getFilterVersion(),
            hideDefaultDummyAction: false,
            tableTitle: t("Dials.VolneVozy"), endpoint: endpoint, clazz: VPListVozidloAdmin, filtering: true,
            hideAddNewActions: true,
            defaultQueryParameters: props.filter,
            columns: prepareCols(createColumns, props.admin, false, false, false, t, yesNoSelect, locFun, invalDuvSelect),
        }}

        hideSaveButton={true}
        hideNewButtonOnEdit={true}
        crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{
            lastBrowseDateDataKey: dateKey,
            typCiselniku: CISELNIK_DRUH,
            showOznaceno: true,
            preprava: false,
            viewing: true,
            defaultRange: DEFAULT_RANGE_VALUE,
            typParametru: PARAM_RANGE_KEY,
            typAdvance: PARAM_ADVANCE_KEY,
            defaultAdvance: DEFAULT_ADVANCE_VALUE,
            admin: props.admin
        }}/>}
        filterClazz={VPFilter}
        clazzDetail={Vozidlo}
        layoutDetail={() => <TabHelperNew<Vozidlo> render={(data) =>
            <>
                <ExtendedViewNew ciselnikTyp={CISELNIK_DRUH} data={data} admin={props.admin}/>
                {props.admin ? <VolneVozyViewAdminPartNew vozidlo={data}/> : undefined}
            </>}/>}
        tabs={[
            props.admin ? {
                title: t("Preprava.History"),
                render: () => <TabHelperNew<Vozidlo>
                    render={(data) => <VolneVozyViewHistoryPartNew vozidlo={data} admin={props.admin}
                                                                   mode={ComponentMode.GridMode}/>}/>
            } : undefined
        ].filter(i => i)}
    />;
}
