import { Dial } from '../../../../raal_components/dial/Dial';
import React from 'react';
import { FormInputType } from '../../../../raal_components/form/Form';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '../../../../routes';
import { useParams } from 'react-router-dom';
import { HereProfilVozidla, HereProfilVozidlaFilter, newVehicle } from '../../../../model/HereProfilVozidla';
import { HereProfilVozidlaForm } from './HereProfilVozidlaForm';

/**
 * Dialog zobrazující tabulku se seznamem profilů vozidel pro Here kilometrovník.
 * @param props
 * @constructor
 */
export function HereProfilVozidlaDial(props: RouteComponentProps) {
    const {t} = useTranslation();
    const {id} = useParams<{ id?: string }>()

    return <Dial<HereProfilVozidla, HereProfilVozidlaFilter>
        focusFieldWhenMounted={true}
        mode={props.mode}
        config={
            {
                id: "here-profil-vozidla",
                clazz: HereProfilVozidla,
                endpoint: "user/hereprofilvozidla",
                tableTitle: t("Here.Dial.TitulekTabulky").toUpperCase(),
                columns: [
                    {
                        title: t("Here.Dial.Nazev"),
                        field: 'nazev',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    },
                    {
                        title: t("Here.Dial.Mena"),
                        field: 'currency',
                        defaultSort:"asc"
                    },
					{
						title: t("Here.Dial.Vyska"),
						field: 'vehicle.height',
						render: (data: HereProfilVozidla) => `${data.vehicle?.height ? data.vehicle?.height/100 : ''}`,
						sorting: false,
					},
					{
						title: t("Here.Dial.Sirka"),
						field: 'vehicle.width',
						render: (data: HereProfilVozidla) => `${data.vehicle?.width ? data.vehicle?.width/100 : ''}`,
						sorting: false,
					},
					{
						title: t("Here.Dial.Delka"),
						field: 'vehicle.length',
						render: (data: HereProfilVozidla) => `${data.vehicle?.length ? data.vehicle?.length/100 : ''}`,
						sorting: false,
					},
					{
						title: t("Here.Dial.CelkovaVaha"),
						field: 'vehicle.grossWeight',
						render: (data: HereProfilVozidla) => `${data.vehicle?.grossWeight ? data.vehicle?.grossWeight / 1000 : ''}`,
						sorting: false,
					}
                ]
            }
        }
        createInstance={() => {
            const pv = new HereProfilVozidla();
			pv.vehicle = newVehicle();
            return pv;
        }}
		onDetailLoaded={(data) => {
			// WORKAROUND
			// V případě, že z backendu přijde json pro formulář, kde nejsou některé atributy, tak se validuje existence změn "špatně".
			// Mapper na backendu je nastaven, tak že v případě null hodnoty json atribut nepřijde.
			// FormField, ale jakmile se do něj klikne vyplní pole null hodnotou. A máme rozdíl.
			// Funkce deepEqual v Util.txt, které kontroluje rozdíli zahlásí změnu. Ale reálně uživatel "pouze" klinul do prázného pole.
			// V onValueChanged v Form.tsx je jiný obsah this.originData a this.data.
			// Při kliku na křížek je potom zobrazen formulář chcete uložit nebo ne.
			// TODO Nějak divně se zaznamenávají změněná pole v changedFields CodebookForm.tsx.
			data.vehicle.height = data.vehicle.height || null;
			data.vehicle.width = data.vehicle.width || null;
			data.vehicle.length = data.vehicle.length || null;
			data.vehicle.grossWeight = data.vehicle.grossWeight || null;
			data.vehicle.weightPerAxle = data.vehicle.weightPerAxle || null;
			data.vehicle.weightPerAxleGroup = data.vehicle.weightPerAxleGroup || null;
			data.vehicle.trailerCount = data.vehicle.trailerCount || null;
			data.vehicle.axleCount = data.vehicle.axleCount || null;
			data.vehicle.trailerAxleCount = data.vehicle.trailerAxleCount || null;
			data.vehicle.tunnelCategory = data.vehicle.tunnelCategory || null;
			data.vehicle.hazardousGoods = data.vehicle.hazardousGoods || null;
			data.vehicle.type = data.vehicle.type || null;
			data.vehicle.payloadCapacity = data.vehicle.payloadCapacity || null;
		}}
        allowDelete={true}
        // preventCloseAfterSave={id === 'new'}
        crudConfig={{editEnabled: true, removeEnabled:true, addEnabled:false}}
        layoutForm={() => {
            return <HereProfilVozidlaForm/>
        }}
    />
}
