import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../context/AppContext";
import * as React from "react";
import {useRef} from "react";
import {useViewColumns} from "../_vp/VPColumns";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useEnumCiselnikSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {VPFilter, VPListPrepravaAdmin} from "../../../../model/PrepravaVozidlo";
import {exportableFields, getFilterVersion, useVPPdfLayout} from "../_vp/PrepravaAVozidlaShared";
import {Dial} from "../../../../raal_components/dial/Dial";
import {Preprava} from "../../../../model/Preprava";
import {FilterForm} from "../_vp/VPFilter";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY,
    prepareCols,
    PrepravyCiselnikViewProps
} from "../prohlizeni/PrepravyCiselnikView";
import {DetailFormNew} from "../_vp/VPDetailForm";
import {PARAM_MAX_DRUHU_KEY} from "../prohlizeni/VlastniVozyCiselnikView";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {PrepravaHistoryViewPartNew} from "../prohlizeni/PrepravaHistoryViewPart";
import {ComponentMode} from "../../../../routes";
import {useClearZaznamOznaceni, useZaznamOznaceni, useZaznamOznaceniStyle, ZaznamOznaceniTyp} from "../Oznaceni";

export function PrepravyCiselnikArchive(props: PrepravyCiselnikViewProps) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const refreshFun = useRef(() => {});
    let [createColumns] = useViewColumns(CISELNIK_DRUH, false, refreshFun, undefined, [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, props.admin, {archive: true, viewArchive: false, typ: NabidkaType.PREPRAVA, refactor: true}, true);
    const dateKey = "preprava-archive-browse-date";
    const endpoint = "user/preprava-archiv";
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: CiselnikTyp.P, enm: InvalDuvodPreprava, enmName: "Enumerations.InvalDuvodPreprava"});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const dtGrid = useRef<DataGridExposed<VPListPrepravaAdmin, VPFilter>>();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user);
    const zaznamOznaceni = useZaznamOznaceniStyle(ZaznamOznaceniTyp.ARCHIV_ZADANI_PREPRAVA);
    const clearZaznamOznaceni = useClearZaznamOznaceni(
        useZaznamOznaceni("user", ZaznamOznaceniTyp.ARCHIV_ZADANI_PREPRAVA),
        () => {dtGrid.current?.table()?.refresh()}
    );

    return <Dial<VPListPrepravaAdmin, VPFilter, Preprava>
        mode={props.mode}
        lastBrowsedDateKey={dateKey}
        logActivity
        gridRef={dtGrid}
        onGridMount={()=>{
            refreshFun.current = () => dtGrid.current?.table()?.refresh();
        }}
        config={{
            lockSupport: {enabled: false},
            overflowHidden: true,
            requiredColumns: ['odkud','kam','souprava','naves','jine','delka','vaha','druhy','druh','datum'],
            alternativeButtons: [clearZaznamOznaceni],
            exportConfig: {
                exportable: true,
                endpoint: endpoint,
                fileName: "export_archiv_prepravy",
                translationPrefix: ['Preprava', 'User', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: exportableFields,
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod,
                                ...{archived: data.deletedOn}
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = zaznamOznaceni.getStyle(data);
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            version:getFilterVersion(),
            tableTitle: t("Dials.PrepravyArchivZadat").toUpperCase(), endpoint: endpoint, clazz: VPListPrepravaAdmin, filtering: true,
            hideAddNewActions: true,
            columns: prepareCols(createColumns, false, true, true, false, t, yesNoSelect, locFun, invalDuvSelect),
            hideDefaultDummyAction:false,
            defaultQueryParameters: props.filter,
        }}
        hideSaveButton
        hideNewButtonOnEdit
        clazzDetail={Preprava}
        crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, showOznaceno:true, preprava: true, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, admin: false, archive: true, archiveZadani: true}}/>}
        layoutForm={() => <DetailFormNew<Preprava> {...{typParametru:PARAM_RANGE_KEY, typCiselniku:CISELNIK_DRUH, preprava:true, defaultRange:DEFAULT_RANGE_VALUE, maxDruhyKey:PARAM_MAX_DRUHU_KEY, advanceParam: PARAM_ADVANCE_KEY, defaultAdvanceValue: DEFAULT_ADVANCE_VALUE, archiveZadani : true}} />}
        filterClazz={VPFilter}
        isDetailReadOnly
        formDisabled={() => true}
        tabs={[
            {
                title: t("Preprava.History"),
                render: ()=><TabHelperNew<Preprava> render={(data) => <PrepravaHistoryViewPartNew preprava={data} archive mode={ComponentMode.GridMode}/>}/>
            }
        ]}
    />;
}
