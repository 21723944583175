import {IDClass} from "../../model/CommonTypes";
import {
    CBDetailContext,
    CBDetailContextNew,
    CBHelper,
    CodeBookControllerProps,
    CodeBookControllerState,
    Data,
    GridData,
    ModalDetailExposed,
    ModalDetailExposedNew,
    Props,
    TabConfig
} from "./CodeBookController.d";
import {
    default as React,
    Dispatch,
    MutableRefObject,
    Ref,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../context/AppContext";
import {LockChangeResult} from "../../model/LockResult";
import _ from "lodash";
import {cloneClassObject, exist} from "../../../common/utils/Util";
import {useDidMount, useMountedEffect} from "../../../common/component/hooks/SharedHooks";
import {CodeBookTab, CodeBookTabbedModal, CodeBookTabbedModalNew} from "../form/CodeBookTabbedModal";
import {CodeBookForm} from "./CodebookForm";
import {
    getBasePath,
    useCodeBookControllerContext,
    useCodeBookControllerContextNew,
    useHashId
} from "./CodeBookController";
import {useHistory, useLocation} from "react-router";
import {Mapper} from "../../../common/utils/objectmapper/Mapper";
import {useFetchDetail} from "../../../common/utils/HttpUtils";
import {AppActivitySubType} from "../../model/Aktivita";
import {invoke} from "../../../common/utils/Invoke";
import {Loading} from "../../../common/component/Loading";
import {getDetailEndpoint, getDetailId, useHistoryCustom} from "./NavigationHelper";
import {LockStatus, useDetailLocker} from "./DetailLock";
import {DATA_TEMPLATE_NULLED_VALUES, useDataTemplateStorage} from "../DataTemplate";
import { useTableCache } from "../grid/MTCache";
import {OznaceniTyp} from "../../page/Dials/user/Oznaceni";

export const CodeBookDetailContext = React.createContext<CBDetailContext>(null);
/**
 * Context pouze nad detailem.  Akce (setData) se aplikuje pouze na detailu.
 */
export const useCodeBookDetailContext = <D extends object>() => useContext(CodeBookDetailContext) as unknown as CBDetailContext<D>;


/**
 * Hook zapouzdrujici vetsinu logiky celeho kontrolleru, jsou zde funkce na zobrazeni a schovani detailu dle url ci lokalniho stavu (zalezi na nastaveni "isTabbed"),
 * take je zde funkce na nacteni detailu zaznamu, fetchDetail posila na serveru i parameter type (row|detail), ktery slouzi k logovani activity o zobrazeni zaznamu (resi si kontrolery na BE samostatne).
 * vsechny funkce a promenne se predavaji do CodeBookControllerContext a lze k nim pristoupit pouzitim hacku useCodeBookControllerContext, pozor: zavolane akce, prerenderovavaji cely kontext, takze jak detail tak i grid
 * @param state
 * @param setState
 * @param props
 * @param detailRef
 */
export function useDetail<D extends IDClass, T extends IDClass>(state:CodeBookControllerState<D>, setState:Dispatch<SetStateAction<CodeBookControllerState<D>>>, props:CodeBookControllerProps<T, any, D>, detailRef?:MutableRefObject<ModalDetailExposed>) {
    const {push} = useHistory();
    const hashId = useHashId((props.isTabbed || props.isFormGrid) ?? false);
    const {pathname, state:locState} = useLocation<{data:GridData<D>, previousPage?: string, forcePreviousPage?:string, entity?:string}>();
    const clazz = (props.clazzDetail ?? props.config.clazz) as {new(): D};
    const mapper = useMemo(()=>new Mapper({constructor: clazz} ), [clazz]);
    const {fetch:_fetch} = useFetchDetail<D>(clazz, props.config.endpointDetail ?? props.config.endpoint);
    const {setDataChanged} = useAppContext();
    const {logActivity} = props;
    const {t} = useTranslation();
    const detailLocker = useDetailLocker(props.config);
    const fetchDetail = useCallback(async (type:AppActivitySubType, id:string|number) => {
        return await _fetch({
            arg:id,
            params:logActivity ? {"type":type} : null
        });
    }, [_fetch, logActivity]);

    const lockModalDetail = useCallback(async () => {
        detailRef.current.lockModalDetail();
    }, [detailRef])

    const {createInstance} = props;
    const createNew = useCallback(() => {
        return createInstance ? createInstance() : new clazz();
    }, [createInstance, clazz]);
    const {isTabbed, isModal, isFormGrid} = props;
    const { modifyCacheRecord } = useTableCache(props.config.id || props.config.endpoint, props.config?.cache?.group);

    const showDetail = useCallback(async (id:string, data:GridData<D> = {}, allowUnlock?: boolean, compareTabId?: boolean) => {

        if(!isTabbed && !isModal && !isFormGrid) {
            push(`${pathname}/${id}`, {
                data: {
                    data: data.data ? mapper.writeValueAsJson(data.data, {springSupport: false}) : null,
                    origin: data.origin ? mapper.writeValueAsJson(data.origin, {springSupport: false}) : null,
                    merge: data.merge,
                },
                forcePreviousPage: locState?.forcePreviousPage,
                entity: locState?.entity
            });
        } else if (isModal || isFormGrid) {
            const formData = await reloadModal(id, data);
            const locked = exist(data?.data?.lockUserInfo)
                ? {userInfo: data.data.lockUserInfo, value: false}
                : props.config?.lockSupport?.enabled && id !== 'new' ? await detailLocker.lock(id) : undefined;
            detailRef.current.showModalDetail(formData?.data, formData?.origin,  id !== 'new', locked, allowUnlock, compareTabId);
        } else {
            setState({edited: true, id: id, loadingDetailFailed: false});
        }
        // eslint-disable-next-line
    }, [isTabbed, isModal, isFormGrid, mapper, push, setState, locState?.forcePreviousPage]);

    const hideDetail = useCallback(async (eventType?: string) => {
        const continueFun = () => {
            if (locState?.forcePreviousPage && !isTabbed && !isModal && !isFormGrid) {
                push(locState.forcePreviousPage, {useCurrentPage: true});
            } else if (!isTabbed && !isModal && !isFormGrid) {
                const path = locState?.previousPage ? locState?.previousPage : getBasePath(pathname, hashId)
                push(path, {useCurrentPage: true});
            } else if (isModal) {
                if (eventType === 'newAndNext') {
                    detailRef.current.clearModalDetail();
                } else {
                    detailRef.current.hideModalDetail(false, props.config?.lockSupport?.enabled, eventType);
                }
            } else if (isFormGrid) {
                detailRef.current.hideModalDetail(false, props.config?.lockSupport?.enabled, eventType);
                setState({useCurrentPage: true});
            } else {
                setState({edited: false, useCurrentPage: true});
            }

            if (eventType !== 'newAndNext') {
                setTimeout(() => setDataChanged(false), 100);
            }
        }

        if ((detailLocker?.getLockStatus() === LockStatus.LOCKED) && !isModal && !isFormGrid) {
            const lockResult = await detailLocker.unlock(state.id,
                {title: `${t("Errors.CouldNotCancelEdit")}`, severity: "error"}
            );
            if (lockResult.value) continueFun();
        } else {
            continueFun();
        }

        // eslint-disable-next-line
    }, [isTabbed, isFormGrid, isModal, pathname, push, setState, locState, setDataChanged, state, detailRef, props.config?.lockSupport]);

    const {onDetailLoaded} = props;

    const reloadModal = useCallback(async(id: string, data: GridData<D>) => {
        const locationData:GridData<D> = data;
        // @ts-ignore
        const gridData:GridData<D> = locationData ? {
            merge:locationData.merge,
            data:mapper.readValue(locationData.data),
            origin:locationData.origin?mapper.readValue(locationData.origin):null,
        }:{};
        if("new" !== id) {
            try {
                let detail = await fetchDetail(AppActivitySubType.Detail, id);
                const _origin = cloneClassObject(detail);
                if(onDetailLoaded) {
                    onDetailLoaded(detail)
                }

                if(gridData.merge) {
                    detail = cloneClassObject(detail, gridData.data);
                }

                return {data:detail, origin:_origin};
            } catch(e) {
                console.error(e);
            }
        } else {
            const onEntityCreated = (newData:D) => {
                if(exist(locState?.entity)) {
                    const entity = mapper.readValue(locState.entity);
                    copyEntityProperties(newData, entity);
                }
                (newData as unknown as CBHelper).validateOnLoad = false;
                let newEntity = newData;
                if(gridData.merge) {
                    newEntity = cloneClassObject(newData, gridData.data);
                }
                return {data:newEntity, origin: cloneClassObject(newData)};
            };

            let newEntity = createNew();

            if(newEntity instanceof Promise) {
                return await newEntity.then(onEntityCreated);
            } else {
                return onEntityCreated(newEntity);
            }

        }
        // eslint-disable-next-line
    }, [onDetailLoaded, createNew, fetchDetail, mapper]);

    const copyEntityProperties = (dest:any, src: any) => {
        _.assign(dest, src);
        dest.id = undefined;
    }

    const reloadDetail = useCallback(async() => {
        const locationData:GridData<D> = locState?.data;
        // @ts-ignore
        const gridData:GridData<D> = locationData ? {
            merge:locationData.merge,
            data:mapper.readValue(locationData.data),
            origin:locationData.origin?mapper.readValue(locationData.origin):null,
        }:{};
        if("new" !== state.id) {
            try {
                setState(state=>({...state, loadingReload: true, loadingDetailFailed:false}));
                let detail = await fetchDetail(AppActivitySubType.Detail, state.id);

                modifyCacheRecord(state.id, (item) => {
                    if(Array.isArray(item?.uzivatelOznaceni)) item.uzivatelOznaceni.push(OznaceniTyp.ZOBRAZENO);
                    return item;
                })

                const _origin = cloneClassObject(detail);
                if(onDetailLoaded) {
                    onDetailLoaded(detail)
                }

                if(gridData.merge) {
                    detail = cloneClassObject(detail, gridData.data);
                }

                setState(state=>({...state, edited:true, formData:{data:detail, origin:_origin}}));
            } catch(e: any) {
                console.error(e);
                setState(state=>({...state, loadingDetailFailed:true, loadingReload: false, response: e.response, formData:{data:undefined, origin:undefined}}));
            }
        } else {
            const onEntityCreated = (newData:D) => {
                if(exist(locState?.entity)) {
                    const entity = mapper.readValue(locState.entity);
                    copyEntityProperties(newData, entity);
                }
                (newData as unknown as CBHelper).validateOnLoad = false;
                let newEntity = newData;
                if(gridData.merge) {
                    newEntity = cloneClassObject(newData, gridData.data);
                }
                setState(state=>({...state, edited:false, formData:{data:newEntity, origin: locState?.entity ? new clazz() : cloneClassObject(newData)}}));
            };

            let newEntity = createNew();

            if(newEntity instanceof Promise) {
                await newEntity.then(onEntityCreated);
            } else {
                onEntityCreated(newEntity);
            }

        }
        // eslint-disable-next-line
    }, [state.id, onDetailLoaded, createNew, fetchDetail, locState, mapper, setState, props.config?.lockSupport]);

    useEffect(()=>{
        if("undefined" === hashId || "null" === hashId) {
            hideDetail();
        } else if(hashId && state.id!==hashId) {
            setState({id:hashId});
        }
        // eslint-disable-next-line
    }, [hashId, hideDetail, setState]);

    useEffect(()=>{
        if(Boolean(state.id)) {
            invoke(reloadDetail);
        }
    }, [state.id, reloadDetail]);
    return {fetchDetail, showDetail, hideDetail, hashId, reloadDetail, detailLocker, lockModalDetail};
}

/**
 * Komponenta zobrazujici detail zaznamu pri kliku v gridu, resi tabovani, zobrazeni formulare ci detailu
 * @param localization
 * @param onSuccess
 * @param props
 * @constructor
 */
export function CodeBookDetail<Detail extends IDClass>({localization, onSuccess, ...props}: Props<any, any, Detail>&{detailRef?:Ref<ModalDetailExposed>}) {
    const {t} = useTranslation();
    const {formData, edited, hideDetail, reload, dataGridRef, detailLocker} = useCodeBookControllerContext<Detail>();
    const {checkDataChanged, setDataChanged} = useAppContext();
    const [s, setS] = useState(false);
    const [{data, origin, edit, locked, allowUnlock, compareTabId}, setDetailData] = useState<Data<Detail>>({});
    const focusField = useRef(props.focusFieldWhenMounted);
    const clazz = (props.clazzDetail ?? props.config.clazz) as {new(): Detail};
    const setData = useCallback((data:Detail)=>{
        setDetailData({...formData, ...data})
    }, [formData]);

    const setModalData = useCallback((d:Detail)=>{
        setDetailData({data: d, origin: origin, edit: edit, locked: locked, allowUnlock: allowUnlock, compareTabId: compareTabId})
    }, [origin, edit, locked, allowUnlock, compareTabId]);

    useImperativeHandle(props.detailRef, () => ({
        showModalDetail,
        hideModalDetail,
        reloadDetail,
        unlockModalDetail,
        lockModalDetail,
        clearModalDetail
    }))

    const lockModalDetail = useCallback(() => {
        setDetailData({data: origin, origin: origin, edit: edit, locked: {value: false}, allowUnlock: true, compareTabId: compareTabId})
    }, [origin, setDetailData, edit, compareTabId])

    const unlockModalDetail = useCallback(() => {
        setDetailData({});
    }, [setDetailData])

    const showModalDetail = (data: Detail, origin: any, edit: boolean, locked: LockChangeResult, allowUnlock?: boolean, compareTabId?: boolean) => {
        dataGridRef.current?.disableAutorefresh(true);
        dataGridRef.current?.onDetailOpen();
        setTimeout(() => setDetailData({data, origin, edit, locked, allowUnlock, compareTabId} ?? {}), 100);
    }

    const clearModalDetail = () => {
        setDetailData({data: _.cloneDeep(origin), origin: _.cloneDeep(origin), edit: edit, locked: locked, allowUnlock: allowUnlock, compareTabId: compareTabId});
        setDataChanged(false);
    }

    const hideModalDetail = useCallback((edit: boolean, lockSupport: boolean, eventType?: string) => {
        if (lockSupport && exist(data?.id) && data.id !== 'new' && !isLocked() && eventType !== 'remove') {
            detailLocker.unlock(getDetailId(props.config, data)).then(() => {
                setDetailData({});
            })
        }else{
            setDetailData({});
        }
        dataGridRef.current?.onDetailClosed();
        // eslint-disable-next-line
    },[data, getDetailId, dataGridRef.current])

    useMountedEffect(()=>{
        setDetailData(formData ?? {});
    }, [formData]);

    const reloadDetail = () => setS(!s);

    const tabChanged = (reloadData?: boolean) => {
        if (reloadData) {
            reload();
        }
        setDataChanged(false);
    }

    const isLocked = useCallback(() => {
        if (detailLocker && detailLocker.getLockStatus())
            return detailLocker.getLockStatus() === LockStatus.LOCKED_EXT;

        if (locked)
            return !(locked && locked.value === true);

        return false;
    }, [detailLocker, locked])

    const { clearTableCache } = useTableCache(props.config.id || props.config.endpoint, props.config?.cache?.group);
    const tabs:CodeBookTab<Detail>[] = (() => {
        const newTabs:TabConfig<Detail>[] = [...props.tabs ?? []];
        if(props.layoutForm) {
            newTabs.unshift({title: (props.firstTabName && data) ? props.firstTabName(data) : t("Default.TabZakladni"), reloadData: props.reloadData, render:() => {
                    return (
                        <CodeBookForm<Detail>
                            getDetailEndpoint={(data: Detail) => getDetailEndpoint(props.config, data)}
                            customReload={reload}
                            localization={localization}
                            focusFieldWhenMounted={focusField.current}
                            focusedFieldWhenMounted={props.focusedFieldWhenMounted}
                            excludeFieldsForDirtyCheck={props.excludeFieldsForDirtyCheck}
                            excludeFieldsForTimeCheck={props.excludeFieldsForTimeCheck}
                            excludeFieldsForIntegerCheck={props.excludeFieldsForIntegerCheck}
                            origin={origin}
                            data={data}
                            lockSupport={props.config?.lockSupport}
                            setData={(data)=>{
                                focusField.current = false;
                                setData(data);
                            }}
                            formDisabled={props.config?.lockSupport?.enabled ? (edited, data) => {
                                return props.formDisabled(edited, data) || isLocked()
                            } : props.formDisabled}
                            beforeSend={props.beforeSend}
                            formLock={() => props.config?.lockSupport?.enabled ? isLocked() : false}
                            edited={edited ?? edit ?? false}
                            getModificationDate={props.getModificationDate}
                            preventCloseAfterSave={props.preventCloseAfterSave}
                            onSuccess={(data, event, res) => {
                                const result = onSuccess&&onSuccess(data, event, res);
                                clearTableCache();
                                if(!result) {
                                    if (props.preventCloseAfterSave) {
                                        setDetailData({
                                            data: _.cloneDeep(data),
                                            origin: _.cloneDeep(data),
                                            edit,
                                            locked,
                                            allowUnlock,
                                            compareTabId
                                        });
                                    } else {
                                        hideDetail(event?.type);
                                    }
                                }
                            }}
                            url={props.config.endpoint}
                            clazz={clazz}
                            render={props.layoutForm}
                            hideNewButtonOnEdit={props.hideNewButtonOnEdit}
                            hideSaveButton={props.hideSaveButton}
                            hideEditButton={props.hideEditButton}
                            saveAndNextButton={props.saveAndNextButton}
                            buttonSaveDisabledFunc={props.buttonSaveDisabledFunc}
                            buttonNewDisabledFunc={props.buttonNewDisabledFunc}
                            hideCustomButtons={props.hideCustomButtons}
                            useFormSaveButtons={props.useFormSaveButtons}
                            validate={props.validate}
                            createParameters={props.createParameters}
                            customButtons={props.customButtons}
                            stopImmediatePropagation={props.stopImmediatePropagation}
                            allowUnlock={allowUnlock}
                            allowDelete={props.allowDelete}
                            compareTabId={compareTabId}
                            detailLocker={detailLocker}
                            lockModalDetail={() => lockModalDetail()}
                            formFieldOverrides={props.formFieldOverrides}
                            handlers={props.handlers}
                            formProps={{
                                saveResponseSetter: props.saveResponseSetter
                            }}
                            templatesEnabled={props.config.templatesEnabled}
                        />
                    );
                }})
        } else if(props.layoutDetail) {
            newTabs.unshift({title: (props.firstTabName&&data) ? props.firstTabName(data) : t("Default.TabZakladni"), render:() => (
                    props.layoutDetail()
                )})
        } else if(props.config.masterDetail && props.clazzDetail?.name===props.config?.clazz?.name) {
            newTabs.unshift({title: (props.firstTabName&&data) ? props.firstTabName(data) : t("Default.TabZakladni"), render:() => (
                    props.config.masterDetail(data as any)
                )})
        }
        return newTabs.map(m=>({
            disabled:() => m.disabled&&m.disabled(data, edited ?? edit),
            render:() => m.render&&m.render(),
            title:m.title,
            reloadData: m.reloadData,
            countEndPoint: m.countEndPoint && typeof m.countEndPoint === 'function' ? m.countEndPoint(data) : m.countEndPoint,
            params: m.params&&m.params(data),
            countFce: m.countFce&&m.countFce
        }));
    })();
    return (
        <CodeBookDetailContext.Provider value={{data, setData, edited: edited ?? edit, reload: reloadDetail, setModalData, origin}}>
            <CodeBookTabbedModal handleClose={() => checkDataChanged(() => hideDetail()) } handleTabChanged={tabChanged} data={data} show={Boolean(data)} tabs={tabs} />
        </CodeBookDetailContext.Provider>
    );
}

/**************************************************************************************************/

export const CodeBookDetailContextNew = React.createContext<CBDetailContextNew>(null);
/**
 * Context pouze nad detailem.  Akce (setData) se aplikuje pouze na detailu.
 */
export const useCodeBookDetailContextNew = <D extends object>() => useContext(CodeBookDetailContextNew) as CBDetailContextNew<D>;

export const CodeBookDetailNew = <Detail extends IDClass>({localization, onSuccess, ...props}: Props<any, any, Detail>&{detailRef?:Ref<ModalDetailExposedNew>}) => {
    const {t} = useTranslation();
    const {edited, detailLocker, fetchDetail, replaceDetailForm} = useCodeBookControllerContextNew<Detail>();
    const clazz = (props.clazzDetail ?? props.config.clazz) as {new(): Detail};
    const mapper = useMemo(()=>new Mapper({constructor: clazz} ), [clazz]);
    const {pathname, state:locState} = useLocation<{data:GridData<Detail>, previousPage?: string, forcePreviousPage?:string, entity?:string, isReplaced?: boolean}>();
    const {checkDataChanged, setDataChanged, navigationHistory} = useAppContext();
    const [s, setS] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [{data, origin, edit, locked, allowUnlock, compareTabId}, setDetailData] = useState<Data<Detail>>({});
    const focusField = useRef(props.focusFieldWhenMounted);
    const id = useHashId();
    const {push, replace} = useHistoryCustom();
    const {goBack} = useHistory();
    const history = useHistory();
    const dataTemplateStorage = useDataTemplateStorage();
    const appliedDataTemplate = useRef(false);
    const { modifyCacheRecord, clearTableCache } = useTableCache(props.config.id || props.config.endpoint, props.config?.cache?.group);

    useDidMount(()=>{
        history.listen(() => {
            // Pokud jde uživatel pryč z detailu přes go back/forward prohlížeče
            if (history.action === "POP") {
                if (checkDataChanged()) setDataChanged(false);

                let pathname = history.location.pathname;
                if(pathname === getBasePath(pathname, id) && detailLocker.getLockStatus() === LockStatus.LOCKED){
                    detailLocker.unlock(id);
                }
            }
        })
    });

    const fetchData = async () => {
        const locationData:GridData<Detail> = locState?.data;
        // @ts-ignore
        const gridData:GridData<D> = locationData ? {
            merge:locationData.merge,
            data:mapper.readValue(locationData.data),
            origin:locationData.origin?mapper.readValue(locationData.origin):null,
        }:{};
        if (id !== 'new' && id !== 'undefined' && id !== 'null') {
            const locked = props.config?.lockSupport?.enabled ? await detailLocker.lock(id) : undefined;

            modifyCacheRecord(id, (item) => {
                if(Array.isArray(item?.uzivatelOznaceni)) item.uzivatelOznaceni.push(OznaceniTyp.ZOBRAZENO);
                return item;
            })

            await fetchDetail(AppActivitySubType.Detail, id).then(result => {
                const _origin = cloneClassObject(result);
                if(gridData.merge) {
                    result = cloneClassObject(result, gridData.data);
                }
                setDetailData({
                    data: result,
                    origin: _origin,
                    edit: true,
                    locked: locked,
                    allowUnlock: true,
                    compareTabId: compareTabId
                });
                props.onDetailLoaded&&props.onDetailLoaded(result);
                setShowLoading(false);
            }).catch(() => {
                replace(getBasePath(pathname, id))
                setShowLoading(false);
            })
        } else {
            let dataTemplate = dataTemplateStorage.applyTemplate();

            const onEntityCreated = (newData:Detail) => {
                if(exist(locState?.entity)) {
                    const entity = mapper.readValue(locState.entity);
                    copyEntityProperties(newData, entity);
                }
                (newData as unknown as CBHelper).validateOnLoad = false;
                let newEntity = newData;

                if (dataTemplate != null) {
                    appliedDataTemplate.current = true;
                    newEntity = mapper.readValue({...newEntity, ...dataTemplate, ...DATA_TEMPLATE_NULLED_VALUES});
                }

                if(gridData.merge) {
                    newEntity = cloneClassObject(newData, gridData.data);
                }
                setDetailData({
                    data: newEntity,
                    origin: locState?.entity ? new clazz() : cloneClassObject(newData),
                    edit: false,
                    locked: locked,
                    allowUnlock: true,
                    compareTabId: compareTabId
                });
            };

            let newEntity = createNew();

            if(newEntity instanceof Promise) {
                await newEntity.then(onEntityCreated);
            } else {
                onEntityCreated(newEntity);
            }
            setShowLoading(false);
        }
    }

    const handleClose = () => {
        // TODO Udelat lepe a nejak univerzalne, kdyz bude cas. Bohuzel podminka, kdyz byl na dashboardu jedna do historie nejde z duvodu jiz predchoziho redirectu.
        if (navigationHistory[navigationHistory.length-2] == '/kilometrovnik/trasa') {
            setDataChanged(false);
            history.replace('/dashboard');
            return;
        }
        // #4609
        const prejezdPrepravaVozyRegex = /^\/(prohlizet|prejezdy|zadat|archiv)\/(prepravy|volnevozy)?\/\d+$/;
        const dokladkaRegex = /^\/dokladky\/\d+$/;
        const zadaniPVI = /^\/zadat\/pvi\/(prepravy|volnevozy)?\/\d+$/;
        const prohlizeniPVI = /^\/prohlizet\/pvi\/(prepravy|volnevozy)?\/\d+$/;
        if (prejezdPrepravaVozyRegex.test(navigationHistory[navigationHistory.length-2])
            || dokladkaRegex.test(navigationHistory[navigationHistory.length-2])
            || zadaniPVI.test(navigationHistory[navigationHistory.length-2])
            || prohlizeniPVI.test(navigationHistory[navigationHistory.length-2])) {
            setDataChanged(false);
            history.goBack();
            return;
        }

        if(appliedDataTemplate && !dataTemplateStorage.hasDataChanged(data)){
            hideDetail().then();
        } else {
            checkDataChanged(() => hideDetail());
        }
    }

    useEffect(() => {
        console.log("CodeBookDetailNew mounted");
        const fetchDetailData = async() => {
            await fetchData();
        }
        if (!showLoading) setShowLoading(true);
        if (id === 'undefined' || id === 'null') push('/')
        invoke(fetchDetailData);
        // eslint-disable-next-line
    }, [])

    const createNew = useCallback(() => {
        return props.createInstance ? props.createInstance() : new clazz();
        // eslint-disable-next-line
    }, [props.createInstance, clazz]);

    const copyEntityProperties = (dest:any, src: any) => {
        _.assign(dest, src);
        dest.id = undefined;
    }

    const setData = useCallback((d:Detail)=>{
        setDetailData({data: d, origin: origin, edit: edit, locked: locked, allowUnlock: allowUnlock, compareTabId: compareTabId})
    }, [origin, edit, locked, allowUnlock, compareTabId]);

    useImperativeHandle(props.detailRef, () => ({
        /*showModalDetail,
        hideModalDetail,
        unlockModalDetail,
        lockModalDetail,
        clearModalDetail*/
        reloadDetail
    }))

    const reloadDetail = () => {
        setS(!s)
    }

    const hideDetail = useCallback(async (eventType?: string) => {
        const continueFun = (locState: any) => {
            if(locState?.forcePreviousPage) {
                push(locState.forcePreviousPage, {useCurrentPage: true});
            }else if (locState?.previousPages && locState.previousPages.length !== 0) {
                const arr = [...locState.previousPages]
                push(arr.pop(), {useCurrentPage: true, previousPages: arr});
            } else{
                const path = locState?.previousPage ? locState?.previousPage : getBasePath(pathname, id);
                push(path, {useCurrentPage: true});
            }

            if (eventType !== 'newAndNext') {
                setTimeout(()=> setDataChanged(false), 100);
            }
        }

        // #4609
        if (eventType === 'new') {
            const prejezdPrepravaVozyRegex = /^\/(prohlizet|prejezdy|zadat|archiv)\/(prepravy|volnevozy)?\/\d+$/;
            const dokladkaRegex = /^\/dokladky\/\d+$/;
            const zadaniPVI = /^\/zadat\/pvi\/(prepravy|volnevozy)?\/\d+$/;
            const prohlizeniPVI = /^\/prohlizet\/pvi\/(prepravy|volnevozy)?\/\d+$/;
            if (prejezdPrepravaVozyRegex.test(navigationHistory[navigationHistory.length-2])
                || dokladkaRegex.test(navigationHistory[navigationHistory.length-2])
                || zadaniPVI.test(navigationHistory[navigationHistory.length-2])
                || prohlizeniPVI.test(navigationHistory[navigationHistory.length-2])) {
                setDataChanged(false);
                history.goBack();
                return;
            }
        }

        // V pripade zmazania zaznamu sa chceme vratit na tabulku
        // pricom nechceme zavolat unlock nabidky
        if (eventType == 'remove') {
            goBack()
            detailLocker.deleted();
            return;
        }

        if (!props.config?.lockSupport || detailLocker.getLockStatus() === LockStatus.LOCKED) {
            const lockResult = await detailLocker.unlock(id,
                {title: `${t("Errors.CouldNotCancelEdit")}`, severity: "error"}
            );
            if (lockResult.value) continueFun(locState);
        } else {
            continueFun(locState);
        }

        // eslint-disable-next-line
    }, [pathname, push, locState, setDataChanged, props.config?.lockSupport]);

    const isLocked = useCallback(() => {
        if (detailLocker && detailLocker.getLockStatus())
            return detailLocker.getLockStatus() === LockStatus.LOCKED_EXT;

        if (locked)
            return !(locked && locked.value === true);

        return false;
    }, [detailLocker, locked])

    const fetchNewData = async () => {
        setShowLoading(true);
        await fetchData();
    }

    const tabs:CodeBookTab<Detail>[] = (() => {
        const newTabs:TabConfig<Detail>[] = [...props.tabs ?? []];
        if(props.layoutForm) {
            newTabs.unshift({title: (props.firstTabName && data) ? props.firstTabName(data) : t("Default.TabZakladni"), reloadData: props.reloadData, render:() => {
                    return (
                        <CodeBookForm<Detail>
                            getDetailEndpoint={(data: Detail) => getDetailEndpoint(props.config, data)}
                            customReload={reloadDetail}
                            localization={localization}
                            focusFieldWhenMounted={focusField.current}
                            focusedFieldWhenMounted={props.focusedFieldWhenMounted}
                            excludeFieldsForDirtyCheck={props.excludeFieldsForDirtyCheck}
                            excludeFieldsForTimeCheck={props.excludeFieldsForTimeCheck}
                            excludeFieldsForIntegerCheck={props.excludeFieldsForIntegerCheck}
                            origin={origin}
                            data={data}
                            lockSupport={props.config?.lockSupport}
                            setData={(data)=>{
                                focusField.current = false;
                                setData(data);
                            }}
                            formDisabled={props.config?.lockSupport?.enabled ? (edited, data) => {
                                return props.formDisabled(edited, data) || isLocked()
                            } : props.formDisabled}
                            beforeSend={props.beforeSend}
                            formLock={() => props.config?.lockSupport?.enabled ? isLocked() : false}
                            edited={edited ?? edit ?? false}
                            getModificationDate={props.getModificationDate}
                            preventCloseAfterSave={props.preventCloseAfterSave}
                            onSuccess={async (data, event, res) => {
                                clearTableCache();
                                const result = onSuccess&&onSuccess(data, event, res);
                                if(!result) {
                                    if (props.preventCloseAfterSave) {
                                        if (id !== 'new') {
                                            setShowLoading(true);
                                            await fetchData();
                                        } else {
                                            replace(`${getBasePath(pathname, id)}/${data.id}`);
                                            replaceDetailForm();
                                        }
                                    } else {
                                        hideDetail(event?.type);
                                    }
                                }
                                props.afterSuccess&&props.afterSuccess(data, id !== 'new');
                            }}
                            url={props.config.endpoint}
                            clazz={clazz}
                            render={props.layoutForm}
                            hideNewButtonOnEdit={props.hideNewButtonOnEdit}
                            hideSaveButton={props.hideSaveButton}
                            hideEditButton={props.hideEditButton}
                            saveAndNextButton={props.saveAndNextButton}
                            buttonSaveDisabledFunc={props.buttonSaveDisabledFunc}
                            buttonNewDisabledFunc={props.buttonNewDisabledFunc}
                            hideCustomButtons={props.hideCustomButtons}
                            useFormSaveButtons={props.useFormSaveButtons}
                            validate={props.validate}
                            createParameters={props.createParameters}
                            customButtons={props.customButtons}
                            stopImmediatePropagation={props.stopImmediatePropagation}
                            allowUnlock={allowUnlock}
                            allowDelete={props.allowDelete}
                            compareTabId={compareTabId}
                            detailLocker={detailLocker}
                           // lockModalDetail={() => lockModalDetail()}
                            formFieldOverrides={props.formFieldOverrides}
                            handlers={props.handlers}
                            formProps={{
                                saveResponseSetter: props.saveResponseSetter
                            }}
                            hideDetail={hideDetail}
                            templatesEnabled={props.config.templatesEnabled}
                        />
                    );
                }})
        } else if(props.layoutDetail) {
            newTabs.unshift({title: (props.firstTabName&&data) ? props.firstTabName(data) : t("Default.TabZakladni"), render:() => (
                    props.layoutDetail()
                )})
        } else if(props.config.masterDetail && props.clazzDetail?.name===props.config?.clazz?.name) {
            newTabs.unshift({title: (props.firstTabName&&data) ? props.firstTabName(data) : t("Default.TabZakladni"), render:() => (
                    props.config.masterDetail(data as any)
                )})
        }
        return newTabs.map(m=>({
            disabled:() => m.disabled&&m.disabled(data, edited ?? edit),
            render:() => m.render&&m.render(),
            title:m.title,
            reloadData: m.reloadData,
            countEndPoint: m.countEndPoint && typeof m.countEndPoint === 'function' ? m.countEndPoint(data) : m.countEndPoint,
            params: m.params&&m.params(data),
            countFce: m.countFce&&m.countFce
        }));
    })();
    return (
        showLoading ? <Loading show={showLoading} title={t("Default.Loading")} fullscreen /> :
        <CodeBookDetailContextNew.Provider value={{data, setData, origin, edited: edited ?? edit, reload: reloadDetail, fetchData: fetchNewData, closeDetail: hideDetail}}>
            <CodeBookTabbedModalNew handleClose={() => handleClose()} data={data} tabs={tabs} />
        </CodeBookDetailContextNew.Provider>
    );
}
