import {Grid} from "@material-ui/core";
import React, {useRef} from "react";
import {StandaloneField} from "../../../../../common/component/form/StandaloneField";
import {FormInputType} from "../../../../raal_components/form/Form";
import {DynamicInputFontSize} from "../../../../../common/component/DynamicFontSize";

interface PrejezdyDokladkyIndikatorPrimarniFiltrProps {
    osmPlace?: string;
    waypointNazev?: string;
    radius?: number; // vykresleni hodnoty radius neni povinne
    titleKey: string; // Klíč pro překlad nadpisu
    radiusIndicatorStartKey?: string; // Klíč pro překlad textu, ktery je pred hodnotou radius indikátoru
    radiusIndicatorEndKey?: string; // Klíč pro překlad text, ktery je za hodnotou radius indikátoru
    isVPFilter?: boolean; // true -> komponenta se vykresluje v rozsirenem filtru (VPFilter)
    dokladkyTitleRadius?: string
    prejezdMinValue?: number // hodnota P-min
    prejezdMaxValue?: number // hodnota P-max
    prejezdMinTextStart?: string
    prejezdMinTextEnd?: string
    prejezdMinTextEndOnlyMinValue?: string
    prejezdMaxTextStart?: string
    prejezdMaxTextStartOnlyMaxValue?: string
    prejezdMaxTextEnd?: string
    isPrejezdy?: boolean
    isDokladky?: boolean
    prejezdTitleOdkud?: string
    prejezdTitleOdkudOnlyValueMin?: string
    prejezdTitleOdkudOnlyValueMax?: string
    prejezdTitleKam?: string
    prejezdTitleKamOnlyValueMin?: string
    prejezdTitleKamOnlyValueMax?: string
    isOkoliOdkud?: boolean
    isOkoliKam?: boolean
}

const PrejezdyDokladkyIndikatorPrimarniFiltr: React.FC<PrejezdyDokladkyIndikatorPrimarniFiltrProps> = ({
                                                                                                           osmPlace,
                                                                                                           waypointNazev,
                                                                                                           radius,
                                                                                                           titleKey,
                                                                                                           radiusIndicatorStartKey,
                                                                                                           radiusIndicatorEndKey,
                                                                                                           isVPFilter,
                                                                                                           dokladkyTitleRadius,
                                                                                                           prejezdMinValue,
                                                                                                           prejezdMaxValue,
                                                                                                           prejezdMinTextStart,
                                                                                                           prejezdMinTextEnd,
                                                                                                           prejezdMinTextEndOnlyMinValue,
                                                                                                           prejezdMaxTextStart,
                                                                                                           prejezdMaxTextStartOnlyMaxValue,
                                                                                                           prejezdMaxTextEnd,
                                                                                                           isPrejezdy,
                                                                                                           isDokladky,
                                                                                                           prejezdTitleOdkud,
                                                                                                           prejezdTitleOdkudOnlyValueMin,
                                                                                                           prejezdTitleOdkudOnlyValueMax,
                                                                                                           prejezdTitleKam,
                                                                                                           prejezdTitleKamOnlyValueMin,
                                                                                                           prejezdTitleKamOnlyValueMax,
                                                                                                           isOkoliOdkud,
                                                                                                           isOkoliKam
                                                                                                       }) => {
    const delitelProFormatPrejezdValue: number = 1000
    const emptyString: string = ""
    const localeStringCountry = "cs-CZ"

    const formatPrejezdValue = (value?: number, startText = "", endText = ""): string => value ? startText + (value / delitelProFormatPrejezdValue).toLocaleString(localeStringCountry) + endText : "";

    const formatPrejezdValues = (): string => {
        if (!prejezdMinValue && !prejezdMaxValue) return emptyString;

        const minValueText = formatPrejezdValue(prejezdMinValue, prejezdMinTextStart, prejezdMaxValue ? prejezdMinTextEnd : prejezdMinTextEndOnlyMinValue);

        const maxValueText = formatPrejezdValue(prejezdMaxValue, prejezdMinValue ? prejezdMaxTextStart : prejezdMaxTextStartOnlyMaxValue, prejezdMaxTextEnd);

        return minValueText + maxValueText;
    };

    const formatDokladkyRadius = (): string => (osmPlace || waypointNazev) && radius != null ? (radiusIndicatorStartKey || emptyString) + radius.toLocaleString(localeStringCountry) + (radiusIndicatorEndKey || emptyString) : emptyString;

    const value = isPrejezdy ? (osmPlace || waypointNazev) + formatPrejezdValues() : isDokladky ? (osmPlace || waypointNazev) + formatDokladkyRadius() : (osmPlace || waypointNazev);

    const getPrejezdTitle = (): string => {
        if (isOkoliOdkud) {
            if (prejezdMinValue && prejezdMaxValue) return prejezdTitleOdkud;
            if (prejezdMinValue) return prejezdTitleOdkudOnlyValueMin;
            if (prejezdMaxValue) return prejezdTitleOdkudOnlyValueMax;
            return emptyString
        } else if (isOkoliKam) {
            if (prejezdMinValue && prejezdMaxValue) return prejezdTitleKam;
            if (prejezdMinValue) return prejezdTitleKamOnlyValueMin;
            if (prejezdMaxValue) return prejezdTitleKamOnlyValueMax;
            return emptyString
        } else return emptyString
    };

    const getDokladkyTitle = (): string => radius ? dokladkyTitleRadius : emptyString;

    const title = (titleKey) + (isPrejezdy ? getPrejezdTitle() : isDokladky ? getDokladkyTitle() : "");

    // Vytvoření reference na obalový element, kde se vykresluje text
    const textRef = useRef<HTMLDivElement>(null);

    return (<>
            {isVPFilter ? (<Grid item lg={6} sm={6} xs={12}>
                    <div ref={textRef} style={{width: "100%"}}>
                        <StandaloneField
                            variant={"outlined"}
                            value={value}
                            type={FormInputType.Text}
                            title={title}
                            disabled={true}
                        />
                    </div>
                    <DynamicInputFontSize text={value} target={textRef} minFontSize={8}/>
                </Grid>) : (<Grid item lg={4} md={10} sm={12} xs={12}>
                    <div ref={textRef} style={{width: "100%"}}>
                        <StandaloneField
                            variant={"outlined"}
                            value={value}
                            type={FormInputType.Text}
                            title={title}
                            disabled={true}
                        />
                    </div>
                    <DynamicInputFontSize text={value} target={textRef} minFontSize={8}/>
                </Grid>)}
        </>);
};

export default PrejezdyDokladkyIndikatorPrimarniFiltr;