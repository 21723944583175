import * as React from "react";
import {useRef} from "react";
import {FormInputType} from "../../../../raal_components/form/Form";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {Preprava} from "../../../../model/Preprava";
import {
    exportableFields,
    exportableFieldsAdminView,
    exportableFieldsView,
    getExportableProps,
    getFilterVersion,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {VPFilter, VPList, VPListPrepravaAdmin} from "../../../../model/PrepravaVozidlo";
import {useViewColumns} from "../_vp/VPColumns";
import {FilterForm} from "../_vp/VPFilter";
import {ExtendedView, ExtendedViewNew} from "../_vp/VPExtendedView";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {useEnumCiselnikSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {TFunction} from "i18next";
import {SelectProps} from "../../../../../common/component/form/FormSelect";
import {GenericMap} from "../../../../../index.d";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {globalStyles} from "../../../../context/ThemeModeContext";
import Cancel from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {invalidatePreprava, validatePreprava} from "../../../../../common/logic/preprava-logic";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {SystemParamKey} from "../../../../model/SystemParameter";
import {exist} from "../../../../../common/utils/Util";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useAppContext} from "../../../../context/AppContext";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ComponentMode} from "../../../../routes";
import {PrepravaViewAdminPartNew} from "./PrepravaViewAdminPart";
import {PrepravaHistoryViewPartNew} from "./PrepravaHistoryViewPart";
import {DetailFormNew} from "../_vp/VPDetailForm";
import {useClearPrepravaViewRecords, useZaznamOznaceniStyle } from "../Oznaceni";

export const CISELNIK_DRUH = CiselnikTyp.R;
export const PARAM_RANGE_KEY = SystemParamKey.PREPRAVA_DATE_RANGE;
export const PARAM_ADVANCE_KEY = SystemParamKey.PREPRAVA_DATE_ADVANCE_MAX;
export const DEFAULT_RANGE_VALUE = 7;
export const DEFAULT_ADVANCE_VALUE = 15;
const PARAM_MAX_DRUHU_KEY = SystemParamKey.PREPRAVY_DRUHY_MAX;

export interface PrepravyCiselnikViewProps {
    admin?: boolean;
    archive?: boolean;
    viewArchive?: boolean,
    filter?: GenericMap;
    isTabbed?:boolean;
    pvi?: boolean
    mode?: ComponentMode
    /**
     * Defines custom base path.
     */
    basePath?: string
    minimumTableHeight?: number
    id?: string
    cacheGroup?: string
}

export function prepareCols(createColumns: () => Column<VPList>[], admin: boolean, archive: boolean, viewArchive: boolean, historyView: boolean, t: TFunction, yesNoSelect: SelectProps<any>, locFun: (locKey: string, en: any, code: any) => string , invalDuvSelect: { formatOption: (value: any) => { label: string; value: any }; formatValue: (value: any) => any; options: string[] }) {
    let addCols = createColumns() as any;
    if (admin) {
        addCols.push(
            {
                title: t("PVI.Valid"),
                field: 'valid',
                render: (data: VPListPrepravaAdmin) => <CheckMark checked={data.valid}/>,
                filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
            },
            {
                title: t("PVI.InvalDuv"),
                field: 'invalDuv',
                render: (row: VPListPrepravaAdmin) => locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, row.invalDuv),
                filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                cellStyle: {minWidth: 180},
                headerStyle: {minWidth: 180}
            },
            {
                title: t("User.Title"),
                field: 'uzivatelText',
                render:(data:VPListPrepravaAdmin)=> data.uzivatel?.toString() ?? "",
                filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                cellStyle: {minWidth: 180},
                headerStyle: {minWidth: 180}
            }
        );
        if (archive && !historyView) {
            addCols.push(
                {
                    title: t("Archive.Archived"),
                    field: 'deletedOn',
                    render: (data: VPListPrepravaAdmin) => (data.deletedOn)?.format("L LT") ?? "",
                    defaultSort: "desc",
                    filterProps: () => ({
                        type: FormInputType.Custom,
                        customComponent: FormDateRange,
                        customComponentOptions: {timeFormat: false},
                        name: 'deletedOnRange'
                    }),
                    cellStyle: {minWidth: 150},
                    headerStyle: {minWidth: 150},
                }
            );
        }
    }
    return addCols;
}

/**
 * TODO - Přepsáno do New, ale uvnitř není stejná logika jako v ExtendedViewNew, bude třeba ještě projít a sloučit.
 * @param props
 * @constructor
 */
export function PrepravyCiselnikView(props: PrepravyCiselnikViewProps = {admin: false}) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const oznacenoFilter = useRef(!props.admin);
    const refreshFun = useRef(() => {});
    let [createColumns] = useViewColumns(CISELNIK_DRUH, oznacenoFilter.current, refreshFun, props.archive ? undefined : "user/preprava", props.admin ? ["datIns", "uzivatel"] : [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, props.admin, {archive: props.archive, viewArchive: props.viewArchive, typ: NabidkaType.PREPRAVA, refactor: true}, true);
    const dateKey = "preprava-view-browse-date";
    const endpoint =
            props.archive && props.viewArchive ? (props.admin ? "admin/preprava-prohlizeni-archiv" : "user/preprava-prohlizeni-archiv") :
            props.archive && !props.viewArchive ? (props.admin ? "admin/preprava-archiv" : "user/preprava-archiv") :
            (props.admin ? "admin/prepravaview" : "user/prepravaview");
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: CiselnikTyp.P, enm: InvalDuvodPreprava, enmName: "Enumerations.InvalDuvodPreprava"});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const dtGrid = useRef<DataGridExposed<VPListPrepravaAdmin, VPFilter>>();
    const [showConfirm] = useConfirmDialog();
    const prepravaId = useHashId();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user);
    const userArchiveZadani = !props.viewArchive && props.archive && !props.admin
    const allowStomp = !props.archive && !props.viewArchive;
    const clearPrepravaViewRecords =
        useClearPrepravaViewRecords(() => {dtGrid.current?.table()?.refresh({}, null, true)});
    const nabidkaOznaceni = useZaznamOznaceniStyle(endpoint);

    return <Dial<VPListPrepravaAdmin, VPFilter, Preprava>
        mode={props.mode}
        isTabbed={props.isTabbed}
        tabDetailUrl= {props.basePath ? props.basePath : undefined}
        lastBrowsedDateKey={dateKey}
        isModal={!props.admin && !exist(prepravaId)}
        logActivity
        gridRef={dtGrid}
        onGridMount={()=>{
            refreshFun.current = () => dtGrid.current?.table()?.refresh();
            oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
        }}
        onFilterChanged={(data) => {
            oznacenoFilter.current = data?.oznaceno ?? false;
        }}
        config={{
            id: props.id,
            cache: {group: props.cacheGroup},
            initialPageSize: 100,
            minimumTableHeight: props.minimumTableHeight,
            lockSupport: {enabled: false},
            overflowHidden: true,
            requiredColumns: ['odkud','kam'],
            defaultHiddenColumns: ['palety', 'sirka', 'vyska', 'lozPlocha', 'objem', 'klasifikace'],
            watchChanges: !props.admin && !props.archive && !props.viewArchive,
            alternativeButtons: props.admin ? undefined : [clearPrepravaViewRecords],
            exportConfig: {
                exportable: true,
                exportDetailOnly: !((props.archive && !props.viewArchive) || props.admin),
                exportAll: props.admin,
                endpoint: endpoint,
                fileName: props.archive || props.viewArchive ? "export_archiv_prepravy" : "export_prepravy",
                translationPrefix: ['Preprava', 'User', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: getExportableProps(userArchiveZadani ? exportableFields : props.admin ? exportableFieldsAdminView : exportableFieldsView, props.archive || props.viewArchive),
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod,
                                ...((props.archive || props.viewArchive)) && {archived: props.viewArchive ? data.datIns : data.deletedOn}
                            }
                        }
                    }
                 ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: (props.admin) ? {
                rowStyle: (data: any) => {
                    if (!data.valid)
                        return globalStyles.rowStyleAlert;
                    if(data.stav===InvalDuvodPreprava.DUPLICITA && props.admin){
                        return globalStyles.rowStyleDuplicated;
                    }
                    return undefined;
                }
            } : {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = nabidkaOznaceni.getStyle(data);
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            actions: props.admin && !props.archive ? [(data:VPListPrepravaAdmin) => ({
                hidden: data?.invalDuv===InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM,
                icon: () => <Cancel/>,
                onClick: async () => {
                    showConfirm({
                        body: t("Preprava.InvalidPrepravaConfirm"),
                        onConfirm: async () => {
                            try {
                                dtGrid.current.table().setLoading(true);
                                await invalidatePreprava(data.id);
                                dtGrid.current.table().refresh();
                            }  finally {
                                dtGrid.current.table().setLoading(false);
                            }
                        }
                    });
                }
            }),
                (data:VPListPrepravaAdmin) => ({
                    hidden: data?.invalDuv!==InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM,
                    icon: () => <Check/>,
                    onClick: () => {
                        showConfirm({
                            body: t("Preprava.ValidPrepravaConfirm"),
                            onConfirm: async () => {
                                try {
                                    dtGrid.current.table().setLoading(true);
                                    await validatePreprava(data.id);
                                    dtGrid.current.table().refresh();
                                } finally {
                                    dtGrid.current.table().setLoading(false);
                                }
                            }
                        });
                    }
                })
            ] : [],
            stomp: allowStomp && {
                topic: props.pvi ? `/provozovna/${user.provozovna.kod}/crud-preprava` : `/crud-preprava`,
                toggleable: !props.admin,
                allowStompUiUpdates: true
            },
            version:getFilterVersion(),
            tableTitle: t("Dials.PrepravyProhlizet").toUpperCase(), endpoint: endpoint, clazz: VPListPrepravaAdmin, filtering: true,
            hideAddNewActions: true,
            columns: prepareCols(createColumns, props.admin, props.archive, props.viewArchive, false, t, yesNoSelect, locFun, invalDuvSelect),
            hideDefaultDummyAction:false,
            defaultQueryParameters: props.filter,
        }}
        hideSaveButton
        hideNewButtonOnEdit
        clazzDetail={Preprava}
        crudConfig={{editEnabled: false, removeEnabled: props.viewArchive, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, showOznaceno:true, preprava: true, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, admin: props.admin, archive: props.archive, archiveZadani: userArchiveZadani}}/>}
        layoutDetail={()=><TabHelperNew<Preprava> render={(data)=>
            <>
                {!userArchiveZadani ? <ExtendedView ciselnikTyp={CISELNIK_DRUH} data={data} admin={props.admin} archive={props.archive}
                               viewArchive={props.viewArchive}/> : undefined}
                {props.admin ? <PrepravaViewAdminPartNew preprava={data} archive={props.archive}/> : undefined}
            </>}/>}
        modalHeaderName={() => t("Dials.Prepravy")}
        layoutForm={userArchiveZadani ? () => <DetailFormNew<Preprava> {...{typParametru:PARAM_RANGE_KEY, typCiselniku:CISELNIK_DRUH, preprava:true, defaultRange:DEFAULT_RANGE_VALUE, maxDruhyKey:PARAM_MAX_DRUHU_KEY, advanceParam: PARAM_ADVANCE_KEY, defaultAdvanceValue: DEFAULT_ADVANCE_VALUE, archiveZadani : true}} /> : undefined}
        isDetailReadOnly={userArchiveZadani}
        formDisabled={() => userArchiveZadani}
        filterClazz={VPFilter}
        tabs={[
            props.admin || userArchiveZadani ? {
                title: t("Preprava.History"),
                render: ()=><TabHelperNew<Preprava> render={(data) => <PrepravaHistoryViewPartNew preprava={data} archive={props.archive} admin={props.admin} mode={ComponentMode.GridMode}/>}/>
            } : undefined
        ].filter(i => i)}
    />;
}

export interface PrepravyCiselnikViewPropsNew {
    admin?: boolean;
    filter?: GenericMap;
    isTabbed?:boolean;
    pvi?: boolean
    mode?: ComponentMode
}

export function PrepravyCiselnikViewNew(props: PrepravyCiselnikViewPropsNew) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const oznacenoFilter = useRef(!props.admin);
    const refreshFun = useRef(() => {});
    let [createColumns] = useViewColumns(CISELNIK_DRUH, oznacenoFilter.current, refreshFun, "user/preprava", props.admin ? ["datIns", "uzivatel"] : [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, props.admin, {typ: NabidkaType.PREPRAVA, refactor: true}, true);
    const dateKey = props.admin ? "preprava-admin-view-browse-date" : "preprava-view-browse-date";
    const endpoint = props.admin ? "admin/prepravaview" : "user/prepravaview";
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: CiselnikTyp.P, enm: InvalDuvodPreprava, enmName: "Enumerations.InvalDuvodPreprava"});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const dtGrid = useRef<DataGridExposed<VPListPrepravaAdmin, VPFilter>>();
    const [showConfirm] = useConfirmDialog();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user);
    const allowStomp = !props.admin;
    const clearPrepravaViewRecords =
        useClearPrepravaViewRecords(() => {dtGrid.current?.table()?.refresh()});
    const nabidkaOznaceni = useZaznamOznaceniStyle(endpoint);

    return <Dial<VPListPrepravaAdmin, VPFilter, Preprava>
        mode={props.mode}
        lastBrowsedDateKey={dateKey}
        logActivity
        gridRef={dtGrid}
        onGridMount={()=>{
            refreshFun.current = () => dtGrid.current?.table()?.refresh();
            oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
        }}
        onFilterChanged={(data) => {
            oznacenoFilter.current = data?.oznaceno ?? false;
        }}
        config={{
            lockSupport: {enabled: false},
            overflowHidden: true,
            requiredColumns: ['odkud','kam'],
            watchChanges: !props.admin,
            alternativeButtons: props.admin ? undefined : [clearPrepravaViewRecords],
            exportConfig: {
                exportable: true,
                exportDetailOnly: !props.admin,
                exportAll: props.admin,
                endpoint: endpoint,
                fileName: "export_prepravy",
                translationPrefix: ['Preprava', 'User', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: getExportableProps(props.admin ? exportableFieldsAdminView : exportableFieldsView),
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: (props.admin) ? {
                rowStyle: (data: any) => {
                    if (!data.valid)
                        return globalStyles.rowStyleAlert;
                    if(data.stav===InvalDuvodPreprava.DUPLICITA && props.admin){
                        return globalStyles.rowStyleDuplicated;
                    }
                    return undefined;
                }
            } : {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = nabidkaOznaceni.getStyle(data);
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            actions: props.admin ? [(data:VPListPrepravaAdmin) => ({
                hidden: data?.invalDuv===InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM,
                icon: () => <Cancel/>,
                onClick: async () => {
                    showConfirm({
                        body: t("Preprava.InvalidPrepravaConfirm"),
                        onConfirm: async () => {
                            try {
                                dtGrid.current.table().setLoading(true);
                                await invalidatePreprava(data.id);
                                dtGrid.current.table().refresh();
                            }  finally {
                                dtGrid.current.table().setLoading(false);
                            }
                        }
                    });
                }
            }),
                (data:VPListPrepravaAdmin) => ({
                    hidden: data?.invalDuv!==InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM,
                    icon: () => <Check/>,
                    onClick: () => {
                        showConfirm({
                            body: t("Preprava.ValidPrepravaConfirm"),
                            onConfirm: async () => {
                                try {
                                    dtGrid.current.table().setLoading(true);
                                    await validatePreprava(data.id);
                                    dtGrid.current.table().refresh();
                                } finally {
                                    dtGrid.current.table().setLoading(false);
                                }
                            }
                        });
                    }
                })
            ] : [],
            stomp: allowStomp && {
                topic: props.pvi ? `/provozovna/${user.provozovna.kod}/crud-preprava` : `/crud-preprava`,
                toggleable: !props.admin,
                allowStompUiUpdates: true
            },
            version:getFilterVersion(),
            tableTitle: t("Dials.Prepravy"), endpoint: endpoint, clazz: VPListPrepravaAdmin, filtering: true,
            hideAddNewActions: true,
            columns: prepareCols(createColumns, props.admin, false, false, false, t, yesNoSelect, locFun, invalDuvSelect),
            hideDefaultDummyAction:false,
            defaultQueryParameters: props.filter,
        }}
        hideSaveButton
        hideNewButtonOnEdit
        clazzDetail={Preprava}
        crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, showOznaceno:true, preprava: true, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, admin: props.admin}}/>}
        layoutDetail={()=><TabHelperNew<Preprava> render={(data)=>
            <>
                <ExtendedViewNew ciselnikTyp={CISELNIK_DRUH} data={data} admin={props.admin}/>
                {props.admin ? <PrepravaViewAdminPartNew preprava={data}/> : undefined}
            </>}/>}
        filterClazz={VPFilter}
        tabs={[
            props.admin ? {
                title: t("Preprava.History"),
                render: ()=><TabHelperNew<Preprava> render={(data) => <PrepravaHistoryViewPartNew preprava={data} admin={props.admin} mode={ComponentMode.GridMode}/>}/>
            } : undefined
        ]}
    />;
}
