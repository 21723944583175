import * as React from "react";
import {useEffect, useState} from "react";
import {User, UserFilter, UserRole, UserRoleFilter} from "../../../model/User";
import {FormButton, FormField, FormInputType, useForm} from "../../../raal_components/form/Form";
import {Dial} from "../../../raal_components/dial/Dial";
import {
    createProvozovnaTelCislaContactSelect,
    createProvozovnaEmailyContactSelect,
    useEnumeratedSelect,
    useProvozovnaSelect,
    useRoleSelect,
    useYesNoSelect,
    useRoleSelectFilter
} from "../../../raal_components/SelectOptions";
import {useTranslation} from "react-i18next";
import {FirmaDetailView} from "./FirmaDetailView";
import {ProvozovnaDetailView} from "../ProvozovnaDetailView";
import {IDClass, LicenseType, Region, RegionList, RegionOrder} from "../../../model/CommonTypes";
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import {exist, int2ip, parsePhoneNumberLocal} from "../../../../common/utils/Util";
import {Column, FilteringProps} from "../../../raal_components/grid/DataGrid.d";
import {TabConfig} from "../../../raal_components/controller/CodeBookController.d";
import {CheckMark} from "../../../../common/component/CheckMark";
import {Provozovna} from "../../../model/Provozovna";
import {globalStyles, useStyleContext} from "../../../context/ThemeModeContext";
import {useFetchCustom} from "../../../../common/utils/HttpUtils";
import {showSnack} from "../../../../common/component/SnackContainer";
import Divider from "@material-ui/core/Divider";
import {Firma} from "../../../model/Firma";
import {FieldError} from "../../../../common/component/form/ValidationError";
import AddIcon from '@material-ui/icons/AssignmentIndOutlined';
import {CodeBookForm} from "../../../raal_components/controller/CodebookForm";
import {LicenceRequest} from "../../../model/LicenseRequest";
import {MuiDetailTabModal} from "../../../../common/component/MuiDetailTabModal";
import {
    FocusableAutocomplete,
    FormSimpleList,
    SimpleListComponentProps
} from "../../../../common/component/form/FormSimpleList";
import {useAppContext} from "../../../context/AppContext";
import {useCountryPhoneCode} from "./StatCiselnik";
import {FormDateRange, FormDateRangeOptions} from "../../../../common/component/form/FormDateRange";
import {Link, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {FormFieldOverrides} from "../../../raal_components/form/FormField";
import {useCodeBookDetailContextNew} from "../../../raal_components/controller/CodeBookDetail";
import {TabHelperNew} from "../../../raal_components/controller/TabHelper";
import {ComponentMode, RouteComponentProps} from "../../../routes";
import {useCodeBookControllerContextNew} from "../../../raal_components/controller/CodeBookController";
import {GenericMap} from "../../../../index.d";

export type UserEditLayoutProps = {
    provozovna?:Provozovna
    firma?:number
	master?:boolean
}

// TODO udelat to jinak nez admin/master/user
export function useUzivatelTabs(isHistory: Boolean = false, admin: boolean = false, master: boolean = false):TabConfig<User>[] {
    const {t} = useTranslation();
    return isHistory ? undefined : [
		{
			title: t("CommonGrid.History"),
			render:()=><TabHelperNew<User> render={(data, edited) => {
				return edited&&<UzivatelCiselnik historyTab url={master ? `master/user-union-view` : `admin/user-union-view`} mode={ComponentMode.GridMode} master={master} admin={admin}/>
			}}/>,
			disabled:(data, edited) => !exist(data?.provozovna) || !edited
		},
		// {
		// 	title: t("DluhSoub.Title"),
		// 	render: () => <TabHelperNew<User> render={(data, edited) =>
		// 		edited&&<DluhSoubDial url={(master ? 'master' : 'admin') + uzivatel_soubor.replace("{userId}", data?.id?.toString())} mode={ComponentMode.GridMode} master={master}/>
		// 	}/>,
		// 	disabled: (data, edited) => !edited
		// },
        {
            title: t("Firma.Title"),
            render:()=><TabHelperNew<User> render={(data) => {
                return <FirmaDetailView firmaId={data?.provozovna?.firma.id?.toString()} master={master}/>
            }}/>,
            disabled:(data) => !exist(data?.provozovna) || !exist(data?.provozovna?.firma)
        },
        ...(admin ? [{
            title: t("Provozovna.Title"),
            render:()=><TabHelperNew<User> render={(data) => {
                return <ProvozovnaDetailView provozovnaId={data && data.provozovna && data.provozovna.id} adminView={admin} master={master}/>
            }}/>,
            disabled:(data: User) => !exist(data?.provozovna)
        }] : [])
    ]
}

export function UzivatelHistoryCiselnik(props: RouteComponentProps) {
    return <UzivatelCiselnik url={props.url} forceHistoryView mode={props.mode} admin={props.admin} master={props.master}/>
}

export type UzivatelCiselnikProps = {
    url?:string
    formFieldOverrides?:FormFieldOverrides[]
    hideAddNewActions?:boolean
    filtering?:FilteringProps
    additionalColumns?:Column<User>[]
    historyTab?: boolean
    forceHistoryView?: boolean
    admin?: boolean
    master?: boolean
    mode?: ComponentMode
    provozovna?:Provozovna
    excludeRoles?: UserRoleFilter[]
}

export function UzivatelCiselnik({url="admin/user", hideAddNewActions=false, filtering, formFieldOverrides, admin = false, master = false, mode, ...props} : UzivatelCiselnikProps) {
    const {t} = useTranslation();
    const isHistory = props.forceHistoryView || props.historyTab;
    // TODO udelat to jinak nez admin/master/user
    const tabs = useUzivatelTabs(isHistory, admin, master);
    const [showLicence, setShowLicence] = useState(false);
    const provozovnaSelect = useProvozovnaSelect({isClearable:true});
    const rolesSelect = useRoleSelectFilter({isClearable:true, isMulti:false}, props.excludeRoles);
    const licencesProps = useEnumeratedSelect(LicenseType, "Enumerations.LicenceTyp", "number", {isClearable:true});
    const regionProps = useEnumeratedSelect(Region, "Enumerations.Region", "number", {isClearable:true}, null, RegionList);
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const {pathname, state} = useLocation<any>();
    const {id} = useParams<IDClass>();
    const defaultQueryParameters : GenericMap = exist(props.provozovna?.id) || exist(props.historyTab) ? {
        parentId: exist(props.historyTab) ? id : undefined,
        provozovna: props.provozovna?.id
    } : undefined;

    return (
        <>
            <LicenceForm
                open={showLicence}
                onSaved={() => setShowLicence(false)}
                onClose={() => setShowLicence(false) }
            />
            {!showLicence&&<Dial<User, UserFilter>
                mode={mode}
                getModificationDate={u => u.datCas}
                config={{
                    options: {
                        rowStyle: (data: any) => {
                            if (data.roles && data.roles.indexOf(UserRole.ROLE_MASTER) > -1) return globalStyles.rowStyleUserMaster;
                            return undefined;
                        }
                    },
                    hideAddNewActions: isHistory || hideAddNewActions,
                    version: 2,
                    clazz: User,
                    tableTitle: t("Dials.Users").toUpperCase(),
                    endpoint: url,
                    exportConfig: {
                        exportable: admin,
                        exportAll: admin,
                        endpoint: url,
                        fileName: "export_uzivatele",
                        translationPrefix: ['User', 'Default'],
                        formats: ["csv"],
                        exportableProps: [
                            {
                                type: ["csv"],
                                fields: master ? ["id", "login", "provozovna", "roles", "region", "typLicence", "jmeno", "email", "phoneNumbers", "enabled", "dispecer", "kdoZmenil", "datCas"]
									: ["id", "login", "provozovna", "roles", "region", "typLicence", "jmeno", "email", "phoneNumbers", "enabled", "dispecer", "kdoZmenil", "datCas", "lastAction"]
                            }
                        ],
                        formattedProps: [
                            {
                                type: ["csv"],
                                field: "roles",
                                format: (data) => {
                                    return data?.length !==0 ? data.map((d: any) => t('Enumerations.Roles.' + d)).join(', '): null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "provozovna",
                                format: (data) => {
                                    return data ? data.kod : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "phoneNumbers",
                                format: (data) => {
                                    return data?.length !== 0 ? data.map((d: any) => `${d.slice(0, 4)} ${d.slice(4)}`).join(', ').replaceAll('+', '') : null;
                                }
                            },
							{
								type: ["csv"],
								field: "lastAction",
								format: (data) => {
									return data ? t(`Enumerations.LastAction.${data}`) : null;
								}
							},
							{
								type: ["csv"],
								field: "typLicence",
								format: (data) => {
									return data ? t(`Enumerations.LicenceTyp.${data}`) : null;
								}
							}
                        ],
                        extendedProps: [
                            {
                                type: ["csv"],
                                translationPrefix: ["Provozovna"],
                                addExtendedProps: (data) => {
                                    const isDispecer = (data.roles?.indexOf(UserRole.ROLE_USER) ?? - 1) > -1 && exist(data.jmeno) && ((exist(data.phoneNumbers) ? data.phoneNumbers.length : 0) + (exist(data.email) ? 1 : 0)) >= 2;
                                    return {
                                        dispecer: isDispecer ? t('Default.Yes') : null
                                    }
                                }
                            }
                        ],
                        defaultQueryParameters: defaultQueryParameters
                    },
                    alternativeButtons: isHistory || !admin ? undefined : [{
                        icon:()=><AddIcon />,
                        tooltip:t("User.ToolTipGenerateUsers"),
                        onClick: () => setShowLicence(true)
                    }],
                    defaultQueryParameters: defaultQueryParameters,
                    columns: [
                        !exist(props.historyTab) && isHistory ? {
                            title: t("User.Id"),
                            field: 'parentId',
                            filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
                            render: (data: User) => data.userId ? <Link to={{
                                pathname: `/admin/uzivatele/${data.userId}`,
                                state: {
                                    forcePreviousPage: pathname
                                }
                            }
                            } onClick={(e) => {e.stopPropagation();}}>{data.userId}</Link> : data.userId,
                        } : undefined,
                        {
                            field: "login", defaultSort: "asc", title: t("User.login"),
                            filterProps: () => ({type: FormInputType.Text}),
                        },
                        {
                            field: "jmeno", title: t("User.jmeno"),
                            filterProps: () => ({type: FormInputType.Text}),
                        },
                        {
                            field: "email", title: t("Default.Email"),
                            filterProps: () => ({type: FormInputType.Text}),
                        },
                        {
                            field: "role", title: t("User.roles"),
                            filterProps: () => ({type: FormInputType.Select, selectProps: rolesSelect}),
                            render:(row: User)=> {return exist(row.roles) && row.roles.map((d: any) => t('Enumerations.Roles.' + d)).join(', ')},
                            sorting: false
                        },
                        {
                            field: "region", title: t("Default.region"),
                            filterProps:() => ({type: FormInputType.Select, selectProps: regionProps}),
                            render:(row: User)=> {return exist(row.region) &&  t(`Enumerations.Region.${row.region}`)},
                            sorting: false
                        },
                        {
                            field: "typLicence", title: t("Default.typLicence"),
                            filterProps:() => ({type: FormInputType.Select, selectProps: licencesProps}),
                            render:(row: User)=> {return exist(row.typLicence) &&  t(`Enumerations.LicenceTyp.${row.typLicence}`)}
                        },
                        {
                            field: "phoneNumbers", title: t("Default.PhoneNumbers"),
                            filterProps:() => ({type: FormInputType.Text}),
                            render:(row: User) => row.phoneNumbers?.map(phoneNumber => parsePhoneNumberLocal(phoneNumber)).join(", ") ?? undefined,
                            sorting: false
                        },
                        { title:t("Default.Enabled"), field:"enabled", render:(data: User)=><CheckMark checked={data.enabled}/>},
                        ...props.additionalColumns ?? [],
                        {
                            field: "dispecer", title: t("Preprava.dispecer"),
                            filterProps:() => ({type: FormInputType.Select, selectProps: yesNoSelect}),
                            render:(row: User) => <CheckMark checked={row.isDispecer()}/>
                        },
						isHistory && {
							field: "lastAction", title: t("User.lastAction"),
							render: (row: User) => {return exist(row.lastAction) &&  t(`Enumerations.LastAction.${row.lastAction}`)}
						},
                        {
                            field: "datCas", title: t("WithUserBase.DatCas"),
                            render: (row: User) => row.datCas?.format("L LT")
                        },
                        {
                            field: "kdoZmenil", title: t("User.KdoZmenil"),
                            render: (row: User) => (row.kdoZmenil?.toString() ?? ''),
                            filterProps: () => ({type: FormInputType.Text})
                        }
                    ].filter(i => exist(i)) as Column<User>[],
                    ...filtering
                }}
                formFieldOverrides={formFieldOverrides}
                crudConfig={{editEnabled: true, removeEnabled:false, addEnabled:false}}
                modalHeaderName={()=>t("Default.ModalZakladni")}
                firstTabName={()=>t("Default.TabZakladni")}
                hideNewButtonOnEdit
                createInstance={() => {
                    const u = new User();
                    u.enabled = false;
                    u.roles = state?.tabDetailData?.roles ?? [];
                    u.provozovna = state?.tabDetailData?.provozovna
                    return u;
                }}
                layoutForm={() => <UzivatelLayoutForm {...{provozovna: state?.tabDetailData?.provozovna, firma:state?.tabDetailData?.firma, master:master}} />}
                filterClazz={UserFilter}
                layoutFilter={() => {
                    return (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"login"} title={t("User.login")} type={"text"} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                    <FormField name="provozovna" title={t("Firma.Provozovna")} type={"select"}
                                               selectProps={provozovnaSelect} disabled={props.provozovna && true} getValue={() => props.provozovna}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"role"} title={t("User.roles")} type={"select"} selectProps={rolesSelect}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"jmeno"} title={t("User.jmeno")} type={"text"}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"region"} title={t("Default.region")} type={"select"} selectProps={regionProps} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"typLicence"} title={t("Default.typLicence")} type={"select"} selectProps={licencesProps} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"email"} title={t("Default.Email")} type={"text"} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"poznamka"} title={t("Default.NoteMaster")} type={"text"}  />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"isDispecer"} title={t("Preprava.dispecer")} type={"select"} selectProps={yesNoSelect}  />
                            </Grid>
                            <Grid item lg={4} sm={12} xs={12}>
                                <FormField<FormDateRangeOptions> title={t("Dispecer.DatCas")} name={"datCasRange"} type={"Custom"}
                                                                 customComponent={FormDateRange} customComponentOptions={{spacing: 2, timeFormat: false}}/>
                            </Grid>
                        </Grid>
                    );
                }}
                tabs={tabs}
                isDetailReadOnly={isHistory}
                formDisabled={() => isHistory || (state?.data?.data?.roles?.length === 0 && master)}
                // TODO udelat to jinak nez admin/master/user
                tabDetailUrl={isHistory && admin ? '/admin/historie/uzivatele' : isHistory && master ? '/master/historie/uzivatele' : null }
            />}
        </>
    );
}

export function UzivatelLayoutForm(props: UserEditLayoutProps): React.ReactElement {
    const {provozovna} = props;
    const {data, edited} = useCodeBookDetailContextNew<User>();
    const {reloadDetailForm} = useCodeBookControllerContextNew();
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const provozovnaSelect = useProvozovnaSelect({isClearable:true, params:{firma:props.firma, podstav6: false}});
    const roleSelect = useRoleSelect();
    const {hasUserRole} = useAppContext();
    const {fetch} = useFetchCustom<boolean>({
        endpoint: `${hasUserRole(UserRole.ROLE_ADMIN) ? "admin/user" : "master/uzivatel"}/generate-new-password?user=${data.id}`
    });
	const [displayNoUserRoleError, setDisplayNoUserRoleError] = useState(false);
    const form = useForm<User>();
    const licencesProps = useEnumeratedSelect(LicenseType, "Enumerations.LicenceTyp", "number", {isClearable:true}, data.provozovna?.typLicence===LicenseType.WITHOUTKM ? [LicenseType.WITHOUTKM] : undefined);
    const regionsProps = useEnumeratedSelect(Region,
        "Enumerations.Region",
        "number",
        {isClearable:true},
        exist(data.provozovna?.typ) ? RegionOrder.slice(RegionOrder.indexOf(data.provozovna?.typ)) : undefined,
        RegionList);
    const generateNewPassword = async () => {
        const result = await fetch({init:{method:"POST"}});
        if(result) {
            showSnack({title:t("GeneratePassword.success"), severity:"success"});
            reloadDetailForm();
        } else {
            showSnack({title:t("GeneratePassword.error"), severity:"error"});
        }

    };
    const {getCountryPhoneCode} = useCountryPhoneCode();

    const [loginFieldDisabled] = useState(!(data.id === undefined));

    const singleLicenseCondition = () => (data.enabled && data.isUser() && (
            (edited && ((data.provozovna?.enabledUsersId?.length ?? 0) === 0 || (data.provozovna?.enabledUsersId?.length === 1 && data.provozovna?.enabledUsersId.indexOf(data.id)>-1))) ||
            (!edited && (data.provozovna?.enabledUsersId?.length ?? 0) === 0)
        ));

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"login"} title={t("User.login")} type={"text"} required textFieldProps={{inputProps: {maxLength: 20, autoComplete: "new-password", disabled: loginFieldDisabled}}} additionalServerErrorKeys={["adminWithoutProvozovna", "userWithProvozovna"]} serverValidation={{translate:t}}/>
                </Grid>
                <Grid item xs={12} sm={3} lg={4}>
                    <FormField name={"newPassword"} title={t("User.Password")} type={passwordVisible ? "text" : "password"} textFieldProps={{inputProps: {autoComplete: "new-password"}}}
                               togglePasswordVisibility={togglePasswordVisibility} disabled={!hasUserRole(UserRole.ROLE_ADMIN)}
                               passwordVisible={passwordVisible} />
                </Grid>
                {form.props.disabled ? undefined : <Grid item xs={12} sm={3} lg={2}>
                    <Tooltip title={t("GeneratePassword.tooltip")}>
                        <span>
                            <Button fullWidth onClick={generateNewPassword} disabled={!edited || !Boolean(data.email)} variant={"contained"} color={"secondary"}>{t("GeneratePassword.title")}</Button>
                        </span>
                    </Tooltip>
                </Grid>}
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name="provozovna" title={t("Firma.Provozovna")} type={"select"} selectProps={provozovnaSelect} forceUpdateWhenDataChanged={["region", "typLicence", "phoneNumbers"]} onWillChange={()=>{
                        data.typLicence = null;
                        data.region = null;
                    }} disabled={exist(provozovna)} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"region"} title={t("Default.region")} type={FormInputType.AutoComplete} selectProps={{...regionsProps, isClearable: true}} serverValidation={{translate:t}} disabled={(f) => (f?.props.getForm().data.roles?.indexOf(UserRole.ROLE_USER) ?? -1) < 0} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"typLicence"} title={t("Default.typLicence")} type={FormInputType.AutoComplete} selectProps={{...licencesProps, isClearable: true}} serverValidation={{translate:t}} disabled={(f) => (f?.props.getForm().data.roles?.indexOf(UserRole.ROLE_USER) ?? -1) < 0}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField forceUpdateWhenDataChanged={["region", "typLicence", "phoneNumbers", "enabled"]} name={"roles"} title={t("User.roles")} type={"select"} selectProps={roleSelect} onWillChange={(f, v) => {
                        if((v?.indexOf(UserRole.ROLE_USER) ?? -1) < 0) {
                            data.typLicence = null;
                            data.region = null;
                        }
                        data.enabled = v && v.length !== 0;
						setDisplayNoUserRoleError(Array.isArray(v) && v.length === 0 && !v.includes(UserRole.ROLE_USER) && data.countOfUsersWithUserRole === 1);
                    }}/>
					{displayNoUserRoleError && <Typography color="error">{t("User.NoUserRoleError")}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6} lg={6} style={{display: "flex"}}>
                    <FormField name={"enabled"} title={t("Default.Enabled")} disabled={(f) => f?.props.getForm().data.roles?.length === 0} type={"checkbox"} forceUpdateWhenDataChanged={["phoneNumbers"]}  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"jmeno"} forceUpdateWhenDataChanged={["phoneNumbers"]} title={t("User.jmeno")} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}} serverValidation={{translate:t}} required={props.master}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    {exist(data.provozovna) && singleLicenseCondition()
                       ? <FormField name={"email"} title={t("Default.Email")} type={FormInputType.AutoComplete} selectProps={createProvozovnaEmailyContactSelect({isClearable: true, params: {provozovnaId: data.provozovna.id}})}/>
                       : <FormField name={"email"} title={t("Default.Email")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}}/>
                    }
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField disabled name={"datCas"} title={t("Dispecer.DatCas")} type={"datetime"}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <FormField name={"phoneNumbers"} title={t("Provozovna.telCislaKontaktu")} type="CustomWithRef" customComponent={FormSimpleList} customComponentOptions={() => {
                        return {
                            maxLines: 2,
                            maxLength: 16,
                            preFillValue: () => singleLicenseCondition() ? "" : getCountryPhoneCode(data?.provozovna?.pStat ?? data?.provozovna?.firma?.r1Stat),
                            valueExtractor: (v: string) => v,
                            valueConstructor: (v: string) => v,
                            showFlagAdornment: true,
                            inputType: "phone",
                            componentProvider: exist(data.provozovna) && singleLicenseCondition() ?
                                (props: SimpleListComponentProps) => <FocusableAutocomplete selectProps={createProvozovnaTelCislaContactSelect({isClearable: true, params: {provozovnaId: data.provozovna.id}})} {...props}/>
                                : undefined
                        };
                    }} isContainer={true} serverValidation={{translate: t}}/>
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                    <Divider className={classes.hrClass} style={{marginBottom: 10, marginTop: 10}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"poslIpUser"} title={t("User.IPAdresaPosledni")} type={"text"} disabled getValue={
                        (v) => int2ip(v.poslIpUser)
                    }/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"poslPripojUser"} title={t("Provozovna.PoslPripoj")} type={"datetime"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"pocPripojUser"} title={t("Provozovna.PocPripoj")} type={"number"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField disabled name={"kdoZmenil"} title={t("User.KdoZmenil")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}} getValue={((data) => data.kdoZmenil?.toString() ?? '')}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Divider className={classes.hrClass} style={{marginBottom: 10, marginTop: 10}}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <FormField name={"poznamka"} title={t("Default.NoteMaster")} type={"textarea"} placeholder={t("Default.Note")} textFieldProps={{inputProps: {maxLength: 510}}}/>
                </Grid>
            </Grid>
        </>

    );
}

type LicenceFormProps = {
    firma?:Firma,
    provozovna?:Provozovna,
    onSaved: ()=> void,
    onClose: ()=> void,
    open?:boolean
}

export function LicenceForm({provozovna, firma, onSaved, open, onClose}:LicenceFormProps) {
    const [data, setData] = useState(new LicenceRequest())

    useEffect(() => {
        if (provozovna) {
            setData(prevstate => {
                return {
                    ...prevstate,
                    provozovna: provozovna,
                    typLicence: provozovna.typLicence,
                    region: provozovna.typ,
                    users: null
                }
            })
        }else {
            setData(new LicenceRequest())
        }
    }, [provozovna, open])
    const {t} = useTranslation();
    const {classes:cls} = useStyleContext();
    const licencesProps = useEnumeratedSelect(LicenseType, "Enumerations.LicenceTyp", "number", {isClearable:true}, data.provozovna?.typLicence===LicenseType.WITHOUTKM ? [LicenseType.WITHOUTKM] : undefined);
    const regionsProps = useEnumeratedSelect(Region, "Enumerations.Region", "number", {isClearable:true}, exist(data.region) ? RegionOrder.slice(RegionOrder.indexOf(data.region)) : undefined, RegionList);
    const provozovnaProps = useProvozovnaSelect({isClearable:true, autoOpenDisabled: true, params:{firma:firma ? firma : null}});
    const {setDataChanged, checkDataChanged} = useAppContext();
    const url = 'admin/provozovna-action';
    return (
        <MuiDetailTabModal
            open={open}
            onClose={() => checkDataChanged(() => {
                onClose();
                setDataChanged(false);
            })}
            fullScreen={true}
            title={t("Default.TabZakladni")}
        >
            <CodeBookForm hideNewButtonOnEdit data={data} onSuccess={onSaved} clazz={LicenceRequest} url={url} excludeFieldsForDirtyCheck={provozovna !== undefined ? [
                "provozovna",
                "region",
                "typLicence"
            ] : []} hideSaveButton render={() =>
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormField<any, Provozovna> forceUpdateWhenDataChanged={[]} name={"provozovna"} title={t("Firma.Provozovna")} type={FormInputType.AutoComplete} selectProps={provozovnaProps} required onWillChange={(field, value) => {
                                data.typLicence = value?.typLicence;
                                data.region = value?.typ;
                            }} disabled={provozovna !== undefined}/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormField name={"users"} title={t("Licence.GenerateUsers")} type={FormInputType.Number} required validate={value => {
                                if(!value || value <= 0) return [FieldError.Create(t("Licence.NumberGreaterThan", {value: 0}))];
                                return null;
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormField name={"region"} title={t("Default.region")} type={FormInputType.AutoComplete} disabled selectProps={regionsProps} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormField name={"typLicence"} title={t("Default.typLicence")} type={FormInputType.AutoComplete} disabled selectProps={licencesProps} />
                        </Grid>
                    </Grid>

                    <Divider className={cls.hrClass}/>

                    <div className="modal-buttons">
                    <FormButton key={"generateLicenses"} onSend={() => {
                        return {
                            modifyUrl: (originalUrl: string) => `${originalUrl}/generate-users`
                        };
                    }}>{t("Licence.GenerateUsers")}</FormButton>
                    </div>
                </>
            } />
        </MuiDetailTabModal>
    )
}
