import {Geometry} from "./Geometry";
import {JsonIgnore, JsonProperty, Mapped} from "../../common/utils/objectmapper/Mapper";
import {resolvePostCode} from "../../common/component/form/FormNominatim";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";
import {Currency} from "./Currency";
import {UserViewDispecer} from "./Dispecer";
import {User, UserBasic} from "./User";
import {NumberRange} from "../../common/component/form/FormNumberRange";
import {DateRange} from "../../common/component/form/FormDateRange";
import {ItemAge} from "../../common/component/form/FormItemAge";
import {VozidloPrepravaSharedFields} from "../page/Dials/user/_vp/PrepravaAVozidlaShared";
import {BasicUserData, InvalDuvodPreprava, InvalDuvodVozidlo} from "./CommonTypes";
import {Klasifikace} from "./Klasifikace";
import {Waypoint} from "./Waypoint";
import {OsmPlace} from "../../common/component/map/Nominatim";
import {exist} from "../../common/utils/Util";
import {ProfilVozidla} from "./ProfilVozidla";
import {Vicinity} from "../../common/component/form/FormVicinityContainer";
import {OznaceniTyp} from "../page/Dials/user/Oznaceni";

export const convertNumbers = (multiplier:number) => {
    return {
        toJson(value: number): any {
            if(value) {
                return (value * multiplier).toFixed(0);
            }
            return value;
        },
        fromJson(value: number): any {
            if(value){
                return value / multiplier;
            }
            return value;
        }
    }
};

export const convertRangeNumbers = (multiplier:number) => {
    const converter = convertNumbers(multiplier);
    return {
        toJson(value:NumberRange) {
            if(value) {
                return {
                    min: converter.toJson(value.min),
                    max: converter.toJson(value.max)
                };
            }

            return value;
        },
        fromJson(value: any): any {
            return value;
        }
    };
};

export function formatTempPlace(country:string, psc:string, city:string) {
    if(!country && !psc && !city) {
        return null;
    }
    return `${country}:${resolvePostCode(psc)}:${city}`;
}

export class Misto {
    nazevMista: string;
    @JsonProperty({type:{clazz:Geometry}})
    koordinat: Geometry;
    countryCode: string;

    constructor(nazevMista:string = null, koordinat:Geometry = null, countryCode?: string) {
        this.nazevMista = nazevMista;
        this.koordinat = koordinat;
        this.countryCode = countryCode?.toUpperCase();
    }

    toOsmPlace(): OsmPlace {
        const o = {} as OsmPlace;
        o.display_name = this.nazevMista;
        o.lat = this.koordinat.getLatLng().lat
        o.lon = this.koordinat.getLatLng().lng
        return o;
    }
}

export class VPListDetailClass implements Mapped, VozidloPrepravaSharedFields {

    @JsonProperty({converters: MomentConverters})
    datOd: Moment;
    @JsonProperty({converters: MomentConverters})
    datDo: Moment;

    //slouzi jen pro update
    @JsonProperty({type:{clazz:Misto}})
    mistoOdkud:Misto;
    odkudTouched:boolean;
    regOd: string;
    pscOdkud: string;
    //slouzi jen pro update
    @JsonProperty({type:{clazz:Misto}})
    mistoKam:Misto;
    kamTouched:boolean;
    regKam: string;
    pscKam: string;
    //pomocna policka pro logiku mezi 3 policky a nominatimem
    odkudHelper:string;
    kamHelper:string;
    kam: string;
    odkud: string;
    @JsonProperty({type:{isArray:true}})
    druhy: number[];

    datum:string;
    palety: number;

    vaha: number;

    objem: number;

    delka: number;

    sirka: number;

    vyska : number;

    uzivatelOznaceni?: [OznaceniTyp]

    provozovnaOznaceni?: [OznaceniTyp]

    finish(): void {
        this.odkudHelper = formatTempPlace(this.regOd, this.pscOdkud, this.odkud);
        this.kamHelper = formatTempPlace(this.regKam, this.pscKam, this.kam);
    }

    getMista(): Misto[] {
        return [];
    }
}



export class VPAbstract extends VPListDetailClass {
    id:number;

    cena: number;
    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;
	jednotka?: string;
    email?: string;

    lozPlocha: number;

    @JsonProperty({converters: MomentConverters})
    datIns: Moment;
    //pro archivní zaznamy
    @JsonProperty({converters: MomentConverters})
    deletedOn: Moment;
    @JsonProperty({converters: MomentConverters})
    datArch: Moment;
    valid: boolean;
    naklProstorVcelku: boolean;
    neverPozn:string;
    zvedaciCelo: boolean;
    druhyRidic: boolean;
    zakazDokladky: boolean;
    adr: boolean;
    hydraulickaRuka: boolean;
    nakladkaZezadu: boolean;
    nadrozmernyNaklad: boolean;
    nakladkaBokem: boolean;
    nakladkaShora: boolean;
    @JsonProperty({type:{clazz:UserViewDispecer}})
    dispecer: UserViewDispecer;
    @JsonProperty({type:{clazz:User}})
    @JsonIgnore({ignoreSet:true, ignoreGet:false})
    uzivatel: User;
    jine: boolean;
    souprava: boolean;
    naves: boolean;
    @JsonProperty({converters: MomentConverters})
    modifiedOn: Moment;
    verejnaPozn:string;
    rychlyKontakt?:string;
    airDistance?:number;
    pocetEditaci?:number;

    //archiv
    nabidkaId?: number;
    typ?: string;
    @JsonProperty({type:{clazz:UserBasic}})
    owner?: UserBasic;

    initialize() {
        this.naklProstorVcelku = this.naklProstorVcelku ?? false;
        this.zvedaciCelo = this.zvedaciCelo ?? false;
        this.druhyRidic = this.druhyRidic ?? false;
        this.zakazDokladky = this.zakazDokladky ?? false;
        this.adr = this.adr ?? false;
        this.hydraulickaRuka = this.hydraulickaRuka ?? false;
        this.nakladkaZezadu = this.nakladkaZezadu ?? false;
        this.nadrozmernyNaklad = this.nadrozmernyNaklad ?? false;
        this.nakladkaBokem = this.nakladkaBokem ?? false;
        this.nakladkaShora = this.nakladkaShora ?? false;
        this.jine = this.jine ?? false;
        this.souprava = this.souprava ?? false;
        this.naves = this.naves ?? false;
    }
}

export class VPList<T = any> extends VPListDetailClass {

    id:number;
    @JsonProperty({converters: MomentConverters})
    modifiedOn:Moment;

    jine:boolean;
    souprava:boolean;
    adr:boolean;
    naves:boolean;
    cena:number;
	jednotka: string;
    rychlyKontakt:string;
    email: string;

    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;

    @JsonProperty({type:{clazz:BasicUserData}})
    @JsonIgnore({ignoreSet:true, ignoreGet:false})
    uzivatel: BasicUserData;

    @JsonProperty({converters: MomentConverters})
    datIns:Moment;

    @JsonIgnore()
    detail:T;
    firma:string;
    provozovna:string;
    oznaceno?: boolean;
    verejnaPozn: string;

    airDistance: number;

    @JsonProperty({type:{clazz:Klasifikace}})
    klasifikace?: Klasifikace;

    getMista(): Misto[] {
        return []
    }

    //pro historicke zaznamy
    parentId:number;
    parentExists?:boolean;

    pocetEditaci?: number;

    //pro archivní zaznamy
    @JsonProperty({converters: MomentConverters})
    deletedOn: Moment;
    @JsonProperty({converters: MomentConverters})
    datArch: Moment;

    nabidkaId: number;

    typ: string;

    neverPozn: string;

    lozPlocha: number;

    dispecer: BasicUserData

}

export class SbernaSluzbaPreprava<T = any> extends VPList<T> {
    prepravaOrder: number;
}

export class SbernaSluzbaPrepravaSelectionListItem<T = any> extends VPList<T> {}


export class VPListAdmin<T = any> extends VPList<T> {
    valid?: boolean;
}

export class VPListPrepravaAdmin<T = any> extends VPListAdmin<T> {
    @JsonProperty({type:{enum:InvalDuvodPreprava}})
    invalDuv: InvalDuvodPreprava
    entityType?: string
}

export class PrejezdVPList<T = any> extends VPList<T>  {
    prejezd?: number;
    prejezdOd?: number;
    prejezdKam?: number;
}

export class DokladkaList<T = any> extends VPList<T> {
    vyhodnost: number;
    zajizdka: number;
}

export class VPListVozidloAdmin<T = any> extends VPListAdmin<T> {
    @JsonProperty({type:{enum:InvalDuvodVozidlo}})
    invalDuv: InvalDuvodVozidlo
    entityType?: string
}

export class VPListPrepravaHistory<T = any> extends VPListPrepravaAdmin<T> {
}

export class VPListVozidloHistory<T = any> extends VPListVozidloAdmin<T> {
}
// @JsonProperty({converters:convertRangeNumbers(100)}) - používá se pro nasobení zadané hodnoty ve filtru
export class VPFilter extends VPAbstract {

    delkaRange?:NumberRange;

    vahaRange?:NumberRange;

    sirkaRange?:NumberRange;

    vyskaRange?:NumberRange;

    airDistanceRange?:NumberRange;

    paletyRange?:NumberRange;

    vpRange?: DateRange

    lozPlochaRange?:NumberRange;

    objemRange?:NumberRange;

    cenaRange?:NumberRange;

    @JsonProperty({type:{clazz:ItemAge}})
    itemAge:ItemAge;

    datDoRange?:DateRange;

    datOdRange?:DateRange;

    oznaceno?: boolean;

    dispecerText?: string;

    @JsonProperty({type:{clazz:User}})
    uzivatelFilter: User;

    provozovna?: string;

    uzivatelText?: string;

    klasifikace?: string;

    invalDuv?: string;

    parentId?: number

    @JsonProperty({type:{clazz:Vicinity}})
    okoliOdkud?: Vicinity

    @JsonProperty({type:{clazz:Vicinity}})
    okoliKam?: Vicinity

    deletedOnRange?: DateRange
    modifiedOnRange?: DateRange
    entityType?: string
    prepravaId?: number
    vozidloId?: number
}

export class PrejezdFilter extends VPFilter {
    prejezdRange?: NumberRange
    prejezdOdRange?: NumberRange
    prejezdKamRange?: NumberRange
    excludedNabidkaId?: number
    @JsonIgnore({ignoreSet:true, ignoreGet:false})
    prejezdOdWaypoint?: Waypoint
    @JsonIgnore({ignoreSet:true, ignoreGet:false})
    prejezdKamWaypoint?: Waypoint
    prejezdOdWaypointLastSearch?: Waypoint
    prejezdKamWaypointLastSearch?: Waypoint
}

export class PrejezdFilterZadani extends VPFilter {
    @JsonProperty({type:{clazz:Waypoint}})
    prejezdOdWaypoint?: Waypoint
    @JsonProperty({type:{clazz:Waypoint}})
    prejezdKamWaypoint?: Waypoint
    dPrejezd?: number
    dPrejezdOd?: number
    dPrejezdKam?: number
    druh: number
    excludedNabidkaId?: number
    @JsonProperty({type:{isArray: true}})
    druhyPrejezd: number[];
}

export class DokladkaFilter extends VPFilter {
    vyhodnostRange:NumberRange;
    zajizdkaRange:NumberRange;
    excludedNabidkaId?: number
    @JsonIgnore({ignoreSet:false, ignoreGet:false})
    nakladka?: Waypoint
    @JsonIgnore({ignoreSet:false, ignoreGet:false})
    vykladka?: Waypoint
    nakladkaLastSearch?: Vicinity
    vykladkaLastSearch?: Vicinity
}

export class DokladkaZadani {
    @JsonProperty({type:{clazz:Waypoint}})
    nakladka?: Waypoint
    @JsonProperty({type:{clazz:Waypoint}})
    vykladka?: Waypoint
    delka: number;
    vaha: number;
    @JsonProperty({type:{isArray: true}})
    druhyDokladka: number[];
    @JsonProperty({converters: MomentConverters})
    datumDokladkyOd: Moment;
    @JsonProperty({converters: MomentConverters})
    datumDokladkyDo: Moment;
    excludedNabidkaId?: number
    naves?: boolean
    souprava?: boolean
    jine?: boolean
    vpRange?: DateRange
    isFilled(): boolean {
        return exist(this.nakladka) && exist(this.vykladka) && exist(this.delka) && exist(this.vaha)
    }
}

export class SbernaSluzbaZadani {
    @JsonProperty({type:{clazz:Waypoint}})
    nakladka: Waypoint;
    @JsonProperty({type:{clazz:Waypoint}})
    vykladka?: Waypoint;
    @JsonProperty({type:{clazz:ProfilVozidla}})
    profilVozidla: ProfilVozidla;
    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;
    @JsonProperty({type:{isArray: true}})
    druhySbernaSluzba?: number[];
    @JsonProperty({converters: MomentConverters})
    datumOd: Moment;
    @JsonProperty({converters: MomentConverters})
    datumDo?: Moment;
    naves?: boolean
    souprava?: boolean
    jine?: boolean
    delka: number;
    vaha: number;

    recalculate?: boolean;

    maxPrejezd: number;
    maxZajizdka: number = 500;

    maxDelka: number;
    maxVaha: number;

    isFilled(): boolean {
        return exist(this.nakladka) && exist(this.profilVozidla) && exist(this.datumOd) && exist(this.delka) && exist(this.vaha) && exist(this.currency)
    }
}
