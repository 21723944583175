import {FormField, useFormData, useFormIsDefaultVicinityAddress} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {
    useCiselnikSelect,
    useCurrencySelectObj,
    useDateIsoSelect,
    useEnumCiselnikSelect,
    useEnumeratedSelect,
    useKlasifikaceSelect,
    useUserBasicSelect,
    useUserDispecerSelect,
    useYesNoSelect
} from "../../../../raal_components/SelectOptions";
import {Grid} from "@material-ui/core";
import {FormNumberRange, FormNumberRangeOptions} from "../../../../../common/component/form/FormNumberRange";
import React, {ReactNode, useCallback} from "react";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {
    cenaConstraint,
    paletyConstraint,
    sizeFormat,
    sizeIntegerFormat,
    vahaConstraint
} from "./PrepravaAVozidlaShared";
import {
    InvalDuvodPreprava,
    InvalDuvodVozidlo,
    PrepravaSearchEntityType,
    VolneVozySearchEntityType
} from "../../../../model/CommonTypes";
import {FormDateRangeSelect} from "../../../../raal_components/FormTwoDatesSelect";
import {SystemParamKey} from "../../../../model/SystemParameter";
import {cenaFormat} from "../zadani/InzerceCiselnik";
import {FormDateRange, FormDateRangeOptions} from "../../../../../common/component/form/FormDateRange";
import {DokladkaFilter, PrejezdFilter, VPFilter} from "../../../../model/PrepravaVozidlo";
import {
    FormVicinityContainer,
    FormVicinityContainerNew,
    Vicinity
} from "../../../../../common/component/form/FormVicinityContainer";
import _ from "lodash";
import PrejezdyDokladkyIndikatorPrimarniFiltr from "../kilometrovnik/PrejezdyDokladkyIndikatorPrimarniFiltr";
import {Waypoint} from "../../../../model/Waypoint";
import {useDataStore} from "../../../../../common/utils/Util";

export type FiltrFormProps = {
    lastBrowseDateDataKey?: string
    typCiselniku:CiselnikTyp
    showOznaceno?: boolean
    preprava: boolean
    viewing?: boolean
    typAdvance:SystemParamKey
    defaultRange:number
    defaultAdvance:number
    admin?: boolean
    archive?: boolean
    history?: boolean
    singleView?: boolean
    disableMultichoice?: boolean
    children?: ReactNode
    hideItemAge?: boolean
    archiveZadani?: boolean
    search?: boolean
    historyTab?: boolean
    oldVersion?: boolean //pokud false, tak se použije FormVicinityContainerNew. Po úpravě všeho na New bude možné smazat celou logiku rozlišování
    isArchiveOfBrowsedOffers?: boolean
    currentVicinityNakladkyRef?: React.MutableRefObject<Vicinity | null>
    currentVicinityVykladkyRef?: React.MutableRefObject<Vicinity | null>
    okoliDisabled?: boolean,
    isDokladky?: boolean
    currentNakladkaRef?: React.MutableRefObject<Waypoint | null>
    currentVykladkaRef?: React.MutableRefObject<Waypoint | null>
    currentNakladkaRadiusRef?: React.MutableRefObject<number | null>
    currentVykladkaRadiusRef?: React.MutableRefObject<number | null>
    isEmptyOkoliOdkudLastSearchRef?:React.MutableRefObject<boolean | null>
    isEmptyOkoliKamLastSearchRef?:React.MutableRefObject<boolean | null>
    isUserSetEmptyOkoliOdkudRef?:React.MutableRefObject<boolean | null>
    isUserSetEmptyOkoliKamRef?:React.MutableRefObject<boolean | null>
    currentPrejezdOdWaypointRef?: React.MutableRefObject<Waypoint | null>
    currentPrejezdKamWaypointRef?: React.MutableRefObject<Waypoint | null>
    prejezdOdWaypointLastSearchRef?: React.MutableRefObject<Waypoint | null>
    prejezdKamWaypointLastSearchRef?: React.MutableRefObject<Waypoint | null>
    isPrejezdy?: boolean
}

export const njsSwitch = (type: 'N' | 'S' | 'J', data: any) => {
    _.set(data, 'naves', type === 'N');
    _.set(data, 'souprava', type === 'S');
    _.set(data, 'jine', type === 'J');
}

function isPrejezdFilter(data: VPFilter): data is PrejezdFilter {
    return (data as PrejezdFilter).prejezdOdWaypoint !== undefined;
}

function isDokladkaFilter(data: VPFilter): data is DokladkaFilter {
    return (data as DokladkaFilter).nakladka !== undefined;
}

function handleDokladkyLocationChange(filterData: any, vicinity: any, fieldKey: 'nakladka' | 'vykladka', vicinityKey: 'okoliOdkud' | 'okoliKam', currentWaypointRef: React.MutableRefObject<any>, currentVicinityRef: React.MutableRefObject<any>, isUserClearOkoli: boolean, storeFilter: (data: any) => void, isDokladkaFilter: (data: any) => boolean) {
    // Aktualizace waypointu, pokud došlo ke změně osmPlace
    if (vicinity?.osmPlace && vicinity?.osmPlace !== currentWaypointRef?.current?.nazevMista) {
        currentWaypointRef.current = Waypoint.fromOkoli(vicinity);
    }

    // Správa vicinity a radiusu
    if (!currentVicinityRef?.current || vicinity?.radius) {
        currentVicinityRef.current = vicinity;
    } else if (currentVicinityRef.current?.radius && isUserClearOkoli) {
        currentVicinityRef.current.radius = null;
    } else if (currentVicinityRef.current?.radius && vicinity) {
        vicinity.radius = currentVicinityRef.current.radius;
    }

    // Uložení změn do filtru pro dokládky
    if (isDokladkaFilter(filterData)) {
        if (filterData[`${fieldKey}Radius`] !== vicinity?.radius) {
            filterData[`${fieldKey}Radius`] = vicinity?.radius;
            storeFilter(filterData);
        }

        if (vicinity?.countryCode && vicinity?.koordinat?.coordinates?.length === 2 && vicinity?.osmPlace && (vicinity.countryCode !== filterData[fieldKey]?.countryCode || vicinity.koordinat.coordinates[0] !== filterData[fieldKey]?.koordinat?.coordinates?.[0] || vicinity.koordinat.coordinates[1] !== filterData[fieldKey]?.koordinat?.coordinates?.[1] || vicinity.koordinat.type !== filterData[fieldKey]?.koordinat?.type || vicinity.osmPlace !== filterData[fieldKey]?.nazevMista)) {
            filterData[fieldKey] = Waypoint.fromOkoli(vicinity);
            filterData[`${fieldKey}Radius`] = null;
            vicinity.radius = null;
            storeFilter(filterData);
        }
    }
}

export function FilterForm({
                               typCiselniku,
                               showOznaceno = false,
                               preprava,
                               viewing = false,
                               typAdvance,
                               defaultRange,
                               defaultAdvance,
                               admin = false,
                               archive = false,
                               history = false,
                               singleView = false,
                               children = undefined,
                               disableMultichoice = false,
                               archiveZadani = false,
                               search = false,
                               historyTab = false,
                               oldVersion = false,
                               isArchiveOfBrowsedOffers = false,
                               currentVicinityNakladkyRef,
                               currentVicinityVykladkyRef,
                               okoliDisabled = false,
                               isDokladky = false,
                               currentNakladkaRef,
                               currentVykladkaRef,
                               currentNakladkaRadiusRef,
                               currentVykladkaRadiusRef,
                               isEmptyOkoliOdkudLastSearchRef,
                               isEmptyOkoliKamLastSearchRef,
                               isUserSetEmptyOkoliOdkudRef,
                               isUserSetEmptyOkoliKamRef,
                               currentPrejezdOdWaypointRef,
                               currentPrejezdKamWaypointRef,
                               prejezdOdWaypointLastSearchRef,
                               prejezdKamWaypointLastSearchRef,
                               isPrejezdy
                           }: FiltrFormProps) {
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const dateFromSelect = useDateIsoSelect({dateType: 'datOd', typAdvance: typAdvance, defaultAdvanceValue: defaultAdvance})
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const spacing = 1;
    const dateSpacing = 0;
    const ciselnikSelectFilter = useCiselnikSelect(typCiselniku, {isClearable: true, isMulti: disableMultichoice!==true});
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: preprava ? CiselnikTyp.P : CiselnikTyp.V, enm: preprava ? InvalDuvodPreprava : InvalDuvodVozidlo, enmName: preprava ? "Enumerations.InvalDuvodPreprava" : "Enumerations.InvalDuvodVozidlo"});
    const klasifikaceSelect = useKlasifikaceSelect({isClearable: true, isMulti: true})
    const rangeNumberSharedOptions = {spacing: 1, numberProps: {format:sizeFormat, constraint:vahaConstraint}};
    const rangePaletySharedOptions = {spacing: 1, numberProps: {format: sizeIntegerFormat, constraint: paletyConstraint}};
    const rangeCenaSharedOptions = {spacing: 1, numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dispecerSelectProps = useUserDispecerSelect({isClearable:true});
    const filterData = useFormData<VPFilter>();
    const searchTypeSelect = useEnumeratedSelect(preprava ? PrepravaSearchEntityType : VolneVozySearchEntityType, preprava ? "SearchEnum.Preprava" : "SearchEnum.VolneVozy", "string", {isClearable:true});
    const isDefaultFormVicinityAddress = useFormIsDefaultVicinityAddress();
    const [loadFilter, storeFilter] = useDataStore(DokladkaFilter, "DokladkaFilter", true, "session");
    const compareRegion = useCallback((field: "okoliOdkud" | "okoliKam") => {
        const odkud = filterData.regOd ?? filterData.okoliOdkud?.countryCode;
        const kam = filterData.regKam ?? filterData.okoliKam?.countryCode;
        return (field === "okoliKam" && odkud === "CZ") || (field === "okoliOdkud" && kam === "CZ");
    }, [filterData])

    return (
        <Grid container spacing={1}>
            <Grid container item xs={12} spacing={spacing}>
                {isPrejezdFilter(filterData) ? (<>
                        {filterData.prejezdOdWaypoint && (<PrejezdyDokladkyIndikatorPrimarniFiltr
                                osmPlace={filterData.prejezdOdWaypointLastSearch?.nazevMista}
                                waypointNazev={filterData.prejezdOdWaypoint?.nazevMista}
                                isVPFilter={true}
                                titleKey={t("Preprava.okoliOdkud")}
                                prejezdMinValue={filterData?.prejezdOdRange?.min}
                                prejezdMaxValue={filterData?.prejezdOdRange?.max}
                                prejezdMinTextStart={t("Prejezdy.Indikator.minValueTextStart")}
                                prejezdMinTextEnd={t("Prejezdy.Indikator.minValueTextEnd")}
                                prejezdMinTextEndOnlyMinValue={t("Prejezdy.Indikator.minValueTextEndOnlyMin")}
                                prejezdMaxTextStart={t("Prejezdy.Indikator.maxValueTextStart")}
                                prejezdMaxTextStartOnlyMaxValue={t("Prejezdy.Indikator.maxValueTextStartOnlyMax")}
                                prejezdMaxTextEnd={t("Prejezdy.Indikator.maxValueTextEnd")}
                                prejezdTitleOdkud={t("Prejezdy.Indikator.Title.odkud")}
                                prejezdTitleOdkudOnlyValueMin={t("Prejezdy.Indikator.Title.odkudOnlyValueMin")}
                                prejezdTitleOdkudOnlyValueMax={t("Prejezdy.Indikator.Title.odkudOnlyValueMax")}
                                isPrejezdy={true}
                                isOkoliOdkud={true}
                            />)}

                        {filterData.prejezdKamWaypoint && (<PrejezdyDokladkyIndikatorPrimarniFiltr
                                osmPlace={filterData.prejezdKamWaypointLastSearch?.nazevMista}
                                waypointNazev={filterData.prejezdKamWaypoint?.nazevMista}
                                isVPFilter={true}
                                titleKey={t("Preprava.okoliKam")}
                                prejezdMinValue={filterData?.prejezdKamRange?.min}
                                prejezdMaxValue={filterData?.prejezdKamRange?.max}
                                prejezdMinTextStart={t("Prejezdy.Indikator.minValueTextStart")}
                                prejezdMinTextEnd={t("Prejezdy.Indikator.minValueTextEnd")}
                                prejezdMinTextEndOnlyMinValue={t("Prejezdy.Indikator.minValueTextEndOnlyMin")}
                                prejezdMaxTextStart={t("Prejezdy.Indikator.maxValueTextStart")}
                                prejezdMaxTextStartOnlyMaxValue={t("Prejezdy.Indikator.maxValueTextStartOnlyMax")}
                                prejezdMaxTextEnd={t("Prejezdy.Indikator.maxValueTextEnd")}
                                prejezdTitleKam={t("Prejezdy.Indikator.Title.kam")}
                                prejezdTitleKamOnlyValueMin={t("Prejezdy.Indikator.Title.kamOnlyValueMin")}
                                prejezdTitleKamOnlyValueMax={t("Prejezdy.Indikator.Title.kamOnlyValueMax")}
                                isPrejezdy={true}
                                isOkoliKam={true}
                            />)}
                    </>) : isDokladkaFilter(filterData) && (<>
                        {filterData?.nakladka && (<PrejezdyDokladkyIndikatorPrimarniFiltr
                                osmPlace={filterData?.okoliOdkud?.osmPlace}
                                waypointNazev={filterData?.nakladka?.nazevMista}
                                radius={filterData?.nakladkaLastSearch?.radius ?? filterData?.okoliOdkud?.radius}
                                isVPFilter={true}
                                titleKey={t("Preprava.okoliOdkud")}
                                radiusIndicatorStartKey={t("Dokladky.okoliIndikatorOkoliDoZacatek")}
                                radiusIndicatorEndKey={t("Dokladky.okoliIndikatorOkoliDoKonec")}
                                isDokladky={true}
                                dokladkyTitleRadius={t("Dokladky.indikatorTitleRadiusOkoliOdkud")}
                            />)}

                        {filterData?.vykladka && (<PrejezdyDokladkyIndikatorPrimarniFiltr
                                osmPlace={filterData?.okoliKam?.osmPlace}
                                waypointNazev={filterData?.vykladka?.nazevMista}
                                radius={filterData?.vykladkaLastSearch?.radius ?? filterData?.okoliKam?.radius}
                                isVPFilter={true}
                                titleKey={t("Preprava.okoliKam")}
                                radiusIndicatorStartKey={t("Dokladky.okoliIndikatorOkoliDoZacatek")}
                                radiusIndicatorEndKey={t("Dokladky.okoliIndikatorOkoliDoKonec")}
                                isDokladky={true}
                                dokladkyTitleRadius={t("Dokladky.indikatorTitleRadiusOkoliKam")}
                            />)}
                    </>)}
                {children}
                <Grid container item spacing={1}>
                    <Grid item lg={6} sm={6} xs={12}>
                        <FormField name={"okoliOdkud"} type={"Custom"} customComponent={oldVersion ? FormVicinityContainer : FormVicinityContainerNew}
                                   customComponentOptions={{
                                       disableClearable: true,
                                       checkLicense: true,
                                       titleOkoli: t("Preprava.okoliOdkud"),
                                       titleRadius: t("Preprava.radiusOdkud"),
                                       titleMpz: t("Preprava.mpz"),
                                       titlePsc: t("Preprava.psc"),
                                       titleNazevMista: t("Preprava.odkud"),
                                       showTitle: true,
                                       filterForm: true,
                                       okoliDisabled,
                                       isFilterForm: true,
                                       isFilterOdkud: true,
                                       isDefaultFormVicinityAddress: isDefaultFormVicinityAddress,
                                       isDokladky,
                                       currentNakladkaRef,
                                       currentNakladkaRadiusRef,
                                       isEmptyOkoliOdkudLastSearchRef,
                                       isUserSetEmptyOkoliOdkudRef,
                                       currentPrejezdOdWaypointRef,
                                       isPrejezdy,
                                       prejezdOdWaypointLastSearchRef,
                                       countryCodeForCompare: () => compareRegion("okoliOdkud"),
                                       onChange: (okoli: Vicinity, isUserClearOkoli: boolean = false) => {
                                           if ("prejezdOdWaypoint" in filterData) {
                                               // Aktualizace pokud uzivatel vymazal okoli
                                               if (!okoli?.osmPlace && currentPrejezdOdWaypointRef?.current && isUserSetEmptyOkoliOdkudRef.current === true) {
                                                   currentPrejezdOdWaypointRef.current = null
                                               }

                                               if (okoli?.osmPlace) {
                                                   currentPrejezdOdWaypointRef.current = Waypoint.fromOkoli(okoli)
                                                   if (isUserSetEmptyOkoliOdkudRef.current === true) {
                                                       isUserSetEmptyOkoliOdkudRef.current = false
                                                   }
                                               } else if (currentPrejezdOdWaypointRef?.current && isUserSetEmptyOkoliOdkudRef.current !== true) {
                                                   const updateOkoli: Vicinity = {...okoli}

                                                   updateOkoli.osmPlace = null
                                                   updateOkoli.countryCode = null
                                                   updateOkoli.koordinat = null

                                                   updateOkoli.osmPlace = currentPrejezdOdWaypointRef?.current?.nazevMista
                                                   updateOkoli.countryCode = currentPrejezdOdWaypointRef?.current?.countryCode
                                                   updateOkoli.koordinat = currentPrejezdOdWaypointRef?.current?.koordinat

                                                   okoli = updateOkoli
                                               }
                                           }

                                           if ("nakladka" in filterData) {
                                               handleDokladkyLocationChange(filterData, okoli, 'nakladka', 'okoliOdkud', currentNakladkaRef, currentVicinityNakladkyRef, isUserClearOkoli, storeFilter, isDokladkaFilter)
                                           }
                                           filterData.okoliOdkud = okoli
                                       }
                                   }}
                        />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                        <FormField name={"okoliKam"} type={"Custom"} customComponent={oldVersion ? FormVicinityContainer : FormVicinityContainerNew}
                                   customComponentOptions={{
                                       disableClearable: true,
                                       checkLicense: true,
                                       titleOkoli: t("Preprava.okoliKam"),
                                       titleRadius: t("Preprava.radiusKam"),
                                       titleMpz: t("Preprava.mpz"),
                                       titlePsc: t("Preprava.psc"),
                                       titleNazevMista: t("Preprava.kam"),
                                       showTitle: true,
                                       filterForm: true,
                                       okoliDisabled,
                                       isFilterForm: true,
                                       isFilterKam: true,
                                       isDokladky,
                                       currentVykladkaRef,
                                       currentVykladkaRadiusRef,
                                       isEmptyOkoliKamLastSearchRef,
                                       isUserSetEmptyOkoliKamRef,
                                       isDefaultFormVicinityAddress: isDefaultFormVicinityAddress,
                                       currentPrejezdKamWaypointRef,
                                       isPrejezdy,
                                       prejezdKamWaypointLastSearchRef,
                                       countryCodeForCompare: () => compareRegion("okoliKam"),
                                       onChange: (okoli: Vicinity, isUserClearOkoli: boolean = false) => {
                                           if ("prejezdKamWaypoint" in filterData) {
                                               // Aktualizace pokud uzivatel vymazal okoli
                                               if (!okoli?.osmPlace && currentPrejezdKamWaypointRef?.current && isUserSetEmptyOkoliKamRef.current === true) {
                                                   currentPrejezdKamWaypointRef.current = null
                                               }

                                               if (okoli?.osmPlace) {
                                                   currentPrejezdKamWaypointRef.current = Waypoint.fromOkoli(okoli)
                                                   if (isUserSetEmptyOkoliKamRef.current === true) {
                                                       isUserSetEmptyOkoliKamRef.current = false
                                                   }
                                               } else if (currentPrejezdKamWaypointRef?.current && isUserSetEmptyOkoliKamRef.current !== true) {
                                                   const updateOkoli: Vicinity = {...okoli}

                                                   updateOkoli.osmPlace = null
                                                   updateOkoli.countryCode = null
                                                   updateOkoli.koordinat = null

                                                   updateOkoli.osmPlace = currentPrejezdKamWaypointRef?.current?.nazevMista
                                                   updateOkoli.countryCode = currentPrejezdKamWaypointRef?.current?.countryCode
                                                   updateOkoli.koordinat = currentPrejezdKamWaypointRef?.current?.koordinat

                                                   okoli = updateOkoli
                                               }
                                           }

                                           if ("vykladka" in filterData) {
                                                handleDokladkyLocationChange(filterData, okoli, 'vykladka', 'okoliKam', currentVykladkaRef, currentVicinityVykladkyRef, isUserClearOkoli, storeFilter, isDokladkaFilter)
                                            }
                                           filterData.okoliKam = okoli;
                                       }
                                   }}
                        />
                    </Grid>
                </Grid>
                {isDokladky && <>
                    <Grid item lg={6} sm={12} xs={12}>
                        <FormField<FormNumberRangeOptions> title={t("Preprava.delka")} name={"delkaRange"}
                                                           type={"Custom"}
                                                           customComponent={FormNumberRange}
                                                           customComponentOptions={rangeNumberSharedOptions}/>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <FormField<FormNumberRangeOptions> title={t("Preprava.vaha")} name={"vahaRange"} type={"Custom"}
                                                           customComponent={FormNumberRange}
                                                           customComponentOptions={rangeNumberSharedOptions}/>
                    </Grid>
                </>}
                {!search&&<Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("Preprava.druhy")} name={disableMultichoice !== true ? "druhy" : "druh"}
                               type={"select"} selectProps={ciselnikSelectFilter}/>
                </Grid>}
                <Grid item lg={2} sm={4} xs={12}>
                    <FormField title={t("Preprava.naves")} name={"naves"} type={"checkbox"} onWillChange={(field, newValue) => {
                        if (newValue) njsSwitch('N', filterData);
                    }} forceUpdateWhenDataChanged={['souprava', 'jine']} />
                </Grid>
                <Grid item lg={2} sm={4} xs={12}>
                    <FormField title={t("Preprava.souprava")} name={"souprava"} type={"checkbox"} onWillChange={(field, newValue) => {
                        if (newValue) njsSwitch('S', filterData)
                    }} forceUpdateWhenDataChanged={['naves', 'jine']} />
                </Grid>
                <Grid item lg={2} sm={4} xs={12}>
                    <FormField title={t("Preprava.jine")} name={"jine"} type={"checkbox"} onWillChange={(field, newValue)=> {
                        if (newValue) njsSwitch('J', filterData)
                    }} forceUpdateWhenDataChanged={['souprava', 'naves']} />
                </Grid>
                <Grid item lg={2} sm={12} xs={12}>
                    <FormField title={t("Currency.Title")} name='currency' type='select' selectProps={currencySelectProps}/>
                </Grid>
                <Grid item lg={2} sm={12} xs={12}>
                    <FormField title={t("Default.Telefon")} name={"rychlyKontakt"} type={"text"}/>
                </Grid>
                <Grid item lg={2} sm={12} xs={12}>
                    <FormField title={t("Default.Email")} name={"email"} type={"text"}/>
                </Grid>
                <Grid item lg={3} sm={12} xs={12}>
                    {viewing ? <FormField title={t("Preprava.dispecer")} name={"dispecerText"} type={"text"}/> : <FormField title={t("Inzerce.Dispecer")} name='dispecer' type='select' selectProps={dispecerSelectProps}/>}
                </Grid>

                <Grid item lg={3} sm={12} xs={12}>
                    <FormField title={t("Provozovna.Kod")} name={"provozovna"} type={"text"}/>
                </Grid>

                {!archiveZadani && (viewing || admin) ? undefined :
                <Grid item xs={12} sm={12} lg={6}>
                    <FormField title={t("User.Title")} name='uzivatelFilter' type='select' selectProps={userSelectProps}/>
                </Grid>}
                {!archiveZadani && (viewing===true || showOznaceno) ? undefined : !admin || search  ? <Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("PVI.InvalDuv")} name={"invalDuv"} type={"select"} selectProps={invalDuvSelect}/>
                    </Grid> : null
                }
                {admin || search ?
                    <>
                        <Grid item lg={6} sm={12} xs={12}>
                            <FormField<FormDateRangeOptions> title={t("Preprava.datOd")} name={"datOdRange"} type={"Custom"}
                                                             customComponent={FormDateRange} customComponentOptions={{spacing}}/>
                        </Grid>
                        <Grid item lg={6} sm={12} xs={12}>
                            <FormField<FormDateRangeOptions> title={t("Preprava.datDo")} name={"datDoRange"} type={"Custom"}
                                                             customComponent={FormDateRange} customComponentOptions={{spacing}}/>
                        </Grid>
                    </>
                :
                    <Grid item lg={6} sm={12} xs={12}>
                        <FormField name={"vpRange"} type={"Custom"}
                        customComponent={FormDateRangeSelect} customComponentOptions={{dateSpacing, dateFromSelect, typParametru: typAdvance, defaultRange}}/>
                    </Grid>
                }
                {
                    admin || search ?
                        <>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormField title={t("PVI.Valid")} name={"valid"} type={"select"} selectProps={yesNoSelect}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormField title={t("PVI.InvalDuv")} name={"invalDuv"} type={"select"} selectProps={invalDuvSelect}/>
                            </Grid>
                        </>
                        : null
                }
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.AirDistanceShort")} name={"airDistanceRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                {!isDokladky && <>
                    <Grid item lg={6} sm={12} xs={12}>
                        <FormField<FormNumberRangeOptions> title={t("Preprava.delka")} name={"delkaRange"}
                                                           type={"Custom"}
                                                           customComponent={FormNumberRange}
                                                           customComponentOptions={rangeNumberSharedOptions}/>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <FormField<FormNumberRangeOptions> title={t("Preprava.vaha")} name={"vahaRange"} type={"Custom"}
                                                           customComponent={FormNumberRange}
                                                           customComponentOptions={rangeNumberSharedOptions}/>
                    </Grid>
                </>}
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("Preprava.cena")} name="cenaRange" type="Custom" customComponent={FormNumberRange} customComponentOptions={rangeCenaSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.palety")} name={"paletyRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangePaletySharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.sirka")} name={"sirkaRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.vyska")} name={"vyskaRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.lozPlochaSmaller")} name={"lozPlochaRange"}
                                                       type={"Custom"} customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.objem")} name={"objemRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={8} sm={12} xs={12}>
                    <FormField title={t("Preprava.verejnaPozn")} name={"verejnaPozn"} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                </Grid>

                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nakladkaZezadu")} name={"nakladkaZezadu"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nakladkaBokem")} name={"nakladkaBokem"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nakladkaShora")} name={"nakladkaShora"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.naklProstorVcelku")} name={"naklProstorVcelku"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.zvedaciCelo")} name={"zvedaciCelo"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.druhyRidic")} name={"druhyRidic"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.zakazDokladky")} name={"zakazDokladky"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.adr")} name={"adr"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.hydraulickaRuka")} name={"hydraulickaRuka"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nadrozmernyNaklad")} name={"nadrozmernyNaklad"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                {admin || search ?
                <Grid item lg={4} sm={12} xs={12}>
                    <FormField title={t("User.Title")} name={"uzivatelText"} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                </Grid> : undefined}
                {viewing && !admin && !archive ?
                <Grid item xs={12} sm={12} lg={4}>
                    <FormField title={t("Klasifikace.klasifikace")} name='klasifikace.klasifikace' type={"select"} selectProps={klasifikaceSelect}/>
                </Grid>
                 : undefined}
                 {/* skryto na žádost zákazníka dle #3832
                 {showOznaceno && Boolean(user.provozovna) && !history && !archive ?
                     <Grid item lg={4} sm={12} xs={12}>
                         <FormField title={t("PVI.Marked")} name={"oznaceno"} type={"select"} selectProps={yesNoSelect}/>
                     </Grid> : null
                 }*/}
                {history && !singleView ?
                    <Grid item lg={4} sm={6} xs={12}>
                        <FormField title={t("Preprava.id")} name={"parentId"} type={"number"} numberProps={{format: '0.[00]'}}/>
                    </Grid> : null}
                {search ?
                    <Grid item xs={12} sm={6} lg={4}>
                        <FormField title={t("SearchEnum.entityType")} name='entityType' type='select' selectProps={searchTypeSelect} serverValidation={{translate:t}}/>
                    </Grid> : null
                }
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField<FormDateRangeOptions> title={t("PVI.Modified")} name={"modifiedOnRange"} type={"Custom"}
                                                     customComponent={FormDateRange}
                                                     customComponentOptions={{spacing: 1, timeFormat: false}}/>
                </Grid>
                {archive || search  ?
                    <Grid item lg={4} sm={6} xs={12}>
                        <FormField<FormDateRangeOptions> title={t("Archive.Archived")} name={isArchiveOfBrowsedOffers ?  "datInsRange" : "deletedOnRange"} type={"Custom"} customComponent={FormDateRange} customComponentOptions={{spacing: 1, timeFormat: false}}/>
                    </Grid> : null
                }
                {((search || admin) && !historyTab) && (
                    <Grid item lg={4} sm={6} xs={12}>
                        <FormField title={t("Default.Id")} name={'id'} type={"number"} numberProps={{format: '0.[00]'}}/>
                    </Grid>
                )}
                {archiveZadani || search ? <Grid item lg={12} sm={12} xs={12}>
                    <FormField title={t("Preprava.neverPozn")} name={"neverPozn"} type={"text"}/>
                </Grid> : null}
            </Grid>
        </Grid>);
}
