import {CustomFieldComponentProps} from "./FormFieldInterface";
import {FormInputType} from "../../../web/raal_components/form/Form";
import {useRangeFocusable} from "./FormRange";
import React, {useEffect, useImperativeHandle, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import {StandaloneField} from "./StandaloneField";
import {invoke} from "../../utils/Invoke";
import {ChildComponentProps, Vicinity, VicinityOptions} from "./FormVicinityContainer";
import {pick} from "lodash";

export const FormVicinityAddress = (props:CustomFieldComponentProps<Vicinity, VicinityOptions>&ChildComponentProps) => {
    const textFieldProps = {style:{maxWidth:'100%'}};
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [focused, onFieldFocused] = useRangeFocusable(props);
    const isInForm = options.filterForm ?? false;
    const [value, setValue] = useState(parseVicinityAddress(isInForm ? props.data[props.name] : props.value))

    useEffect(() => {
        setValue(parseVicinityAddress(isInForm ? props.data[props.name] : props.value))
    }, [props.value]);

    let mpz = options.filterForm ?
        (props.data[props.name]?.mpz ?? null) :
        (value?.mpz ?? null);
    let psc = options.filterForm ?
        (props.data[props.name]?.psc ?? null) :
        (value?.psc ?? null);
    let nazevMista = options.filterForm ?
        (props.data[props.name]?.nazevMista ?? null) :
        (value?.nazevMista ?? null);
    let parsedMpz = Array.isArray(mpz) ? mpz.join(",") : mpz
    let parsedPsc = Array.isArray(psc) ? psc.join(",") : psc

    const defaultValue: Vicinity = {
        koordinat: null,
        osmPlace: null,
        radius: null,
        countryCode: null
    }

    const defaultValue2: Vicinity = {
        psc: null,
        mpz: null,
        nazevMista: null,
    }

    function parseVicinityAddress(vicinity: Vicinity) {
        if(typeof props.value?.mpz === 'string') vicinity.mpz = [props.value.mpz];
        if(typeof props.value?.psc === 'string') vicinity.psc = [props.value?.psc];
        return vicinity
    }

    const onChange = (v?: Vicinity) => {
        props.onValueChanged(v)
        invoke(options.onChange, v);
        setValue(v)
    }

    useImperativeHandle(props.componentRef, () => {
        return {
            selectFirstField: () => onFieldFocused(1),
            onChange
        }
    })
    const cols = !options?.filterForm ? 6 : 7;
    const colsPsc = !options?.filterForm ? 5 : 5;

    return (
        <>
            <Grid container spacing={!options?.filterForm ? 0 : 1}>
                <Grid item lg={cols} sm={cols} xs={cols}>
                    <Box style={{width:'100%'}}>
                        <StandaloneField
                            focused={focused===1}
                            onBlur={()=>{
                                invoke(props.onBlur)
                            }}
                            variant={props.variant ?? "outlined"}
                            onKeyDown={(event) => {
                                // Pokud je klávesa číslo (0-9) a není to speciální klávesa (Backspace, Delete, Arrow keys, atd.), zabráníme výchozímu chování
                                if (event.key.match(/\d/)) {
                                    event.preventDefault();
                                    return
                                }

                                if (props.onKeyDown) {
                                    props.onKeyDown(event);
                                }
                            }}
                            type={FormInputType.Text}
                            showAdornment={props.showAdornment}
                            inputClassName={props.inputClassName}
                            value={parsedMpz}
                            onValueChanged={v => {
                                onChange({...defaultValue, ...pick(value, Object.keys(defaultValue2)), mpz: v?.toUpperCase().split(",")});
                            }}
                            title={options?.showTitle&&options?.titleMpz}
                            placeholder={options?.titleMpz}
                            textFieldProps={{...textFieldProps, inputProps: {maxLength: 60}}}
                            onFocus={(o, userFocus) => {
                                if(userFocus) {
                                    onFieldFocused(1);
                                }
                            }}
                        />
                    </Box>
                </Grid>
                {!options?.filterForm ? <Grid item lg={1} sm={1} xs={1}></Grid> : undefined}
                <Grid item lg={colsPsc} sm={colsPsc} xs={colsPsc}>
                    <Box style={{width:'100%'}}>
                        <StandaloneField
                            focused={focused===2}
                            onBlur={()=>{
                                invoke(props.onBlur)
                            }}
                            variant={props.variant ?? "outlined"}
                            onKeyDown={props.onKeyDown}
                            type={FormInputType.Text}
                            showAdornment={props.showAdornment}
                            inputClassName={props.inputClassName}
                            value={parsedPsc}
                            onValueChanged={v => {
                                onChange({...defaultValue, ...pick(value, Object.keys(defaultValue2)), psc: v?.split(",")});
                            }}
                            title={options?.showTitle&&options?.titlePsc}
                            placeholder={options?.titlePsc}
                            textFieldProps={{...textFieldProps, inputProps: {maxLength: 60}}}
                            onFocus={(o, userFocus) => {
                                if(userFocus) {
                                    onFieldFocused(2);
                                }
                            }}
                        />
                    </Box>
                </Grid>
                <div style={{height: options?.verticalSpace ?? 8}}/>
                <Grid item lg={12} sm={12} xs={12}>
                    <Box style={{width:'100%'}}>
                        <StandaloneField
                            focused={focused===3}
                            onBlur={()=>{
                                invoke(props.onBlur)
                            }}
                            variant={props.variant ?? "outlined"}
                            onKeyDown={props.onKeyDown}
                            type={FormInputType.Text}
                            showAdornment={props.showAdornment}
                            inputClassName={props.inputClassName}
                            value={nazevMista}
                            onValueChanged={v => {
                                onChange({...defaultValue, ...pick(value, Object.keys(defaultValue2)), nazevMista: v});
                            }}
                            title={options?.showTitle&&options?.titleNazevMista}
                            placeholder={options?.titleNazevMista}
                            textFieldProps={textFieldProps}
                            onFocus={(o, userFocus) => {
                                if(userFocus) {
                                    onFieldFocused(3);
                                }
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
