import {CustomFieldComponentProps} from "./FormFieldInterface";
import {FormInputType} from "../../../web/raal_components/form/Form";
import {useRangeFocusable} from "./FormRange";
import React, {useEffect, useImperativeHandle, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import {StandaloneField} from "./StandaloneField";
import {formatForRaalUsers, FormNominatim} from "./FormNominatim";
import {Waypoint} from "../../../web/model/Waypoint";
import {invoke} from "../../utils/Invoke";
import _ from "lodash";
import {ChildComponentProps, Vicinity, VicinityOptions} from "./FormVicinityContainer";
import {exist} from "../../utils/Util";
import {useMpzResolver} from "../../utils/MpzUtils";
import {
    calculateDokladkyOsmPlaceResultInColumn,
    calculateDokladkyOsmPlaceResultInForm,
    calculateDokladkyRadiusResultInColumn,
    calculateDokladkyRadiusResultInForm,
    handleDokladkyOsmPlaceChange,
    handleDokladkyRadiusChangeInColumn,
    handleDokladkyRadiusChangeInForm
} from "../../utils/formvicinity/FormVicinityDokladkyUtils";

type FVExtension  = {
    customComponentOptions?: {
        okoliDisabled?: boolean
        isDokladky?: boolean
        isFilterOdkud?: boolean // true -> jedna se komponentu umistenou v radkovem filtru ve filtru Okoli Odkud
        isFilterKam?: boolean // true - >jedna se komponentu umistenou v radkovem filtru ve filtru Okoli Kam
        currentVicinityNakladkyRef: React.MutableRefObject<Vicinity | null>
        currentVicinityVykladkyRef: React.MutableRefObject<Vicinity | null>
        currentNakladkaRef?: React.MutableRefObject<Waypoint | null>
        currentVykladkaRef?: React.MutableRefObject<Waypoint | null>
        currentNakladkaRadiusRef?: React.MutableRefObject<number | null>
        currentVykladkaRadiusRef?: React.MutableRefObject<number | null>
        isEmptyOkoliOdkudLastSearchRef?: React.MutableRefObject<boolean | null>
        isUserSetEmptyOkoliOdkudRef?: React.MutableRefObject<boolean | null>
        isEmptyOkoliKamLastSearchRef?: React.MutableRefObject<boolean | null>
        isUserSetEmptyOkoliKamRef?: React.MutableRefObject<boolean | null>
        currentPrejezdOdWaypointRef?: React.MutableRefObject<Waypoint | null>
        currentPrejezdKamWaypointRef?: React.MutableRefObject<Waypoint | null>
        isPrejezdy?: boolean
        prejezdOdWaypointLastSearchRef?: React.MutableRefObject<Waypoint | null>
        prejezdKamWaypointLastSearchRef?: React.MutableRefObject<Waypoint | null>
    }
}

type localVicinity = {
    osmPlace: string | null,
    radius: string | null
}

export const FormVicinity = (props:CustomFieldComponentProps<Vicinity, VicinityOptions>&ChildComponentProps & FVExtension) => {

    const {
        customComponentOptions: {
            currentNakladkaRef,
            currentNakladkaRadiusRef,
            isEmptyOkoliOdkudLastSearchRef,
            isUserSetEmptyOkoliOdkudRef,
            currentVykladkaRef,
            currentVykladkaRadiusRef,
            isEmptyOkoliKamLastSearchRef,
            isUserSetEmptyOkoliKamRef,
            currentPrejezdOdWaypointRef,
            currentPrejezdKamWaypointRef
        } = {},
    } = props;
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const activeNakladkaRef = currentNakladkaRef || options?.currentNakladkaRef
    const activeNakladkaRadiusRef = currentNakladkaRadiusRef || options?.currentNakladkaRadiusRef;
    const activeIsEmptyOkoliOdkudLastSearchRef = isEmptyOkoliOdkudLastSearchRef || options?.isEmptyOkoliOdkudLastSearchRef
    const activeIsUserSetEmptyOkoliOdkudRef = isUserSetEmptyOkoliOdkudRef || options?.isUserSetEmptyOkoliOdkudRef
    const activeVykladkaRef = currentVykladkaRef || options?.currentVykladkaRef
    const activeVykladkaRadiusRef = currentVykladkaRadiusRef || options?.currentVykladkaRadiusRef;
    const activeIsEmptyOkoliKamLastSearchRef = isEmptyOkoliKamLastSearchRef || options?.isEmptyOkoliKamLastSearchRef
    const activeIsUserSetEmptyOkoliKamRef = isUserSetEmptyOkoliKamRef || options?.isUserSetEmptyOkoliKamRef
    const activeCurrentPrejezdOdWaypointRef = currentPrejezdOdWaypointRef || options?.currentPrejezdOdWaypointRef
    const activeCurrentPrejezdKamWaypointRef = currentPrejezdKamWaypointRef || options?.currentPrejezdKamWaypointRef
    const textFieldProps = {style: {maxWidth: '100%'}, inputProps: {maxLength: 5}};

    const [focused, onFieldFocused] = useRangeFocusable(props);
    const [resolveMpz] = useMpzResolver();

    const isInForm = options.filterForm ?? false;
    // @ts-ignore
    const nullVicinity: localVicinity = {osmPlace: null, radius: null};

    let osmPlaceResult: any;
    if (!options?.isDokladky || !options?.isPrejezdy) {
        osmPlaceResult = props.value?.osmPlace ?? props.data?.[props.name]?.osmPlace ?? null;
    } if (options?.isPrejezdy) {
        if (options?.isFilterOdkud) {
            osmPlaceResult = activeCurrentPrejezdOdWaypointRef?.current?.nazevMista ?? null;
        } else if (options?.isFilterKam) {
            osmPlaceResult = activeCurrentPrejezdKamWaypointRef?.current?.nazevMista ?? null;
        }
    } else if (options?.isDokladky) {
        if (options?.isFilterOdkud) {
            if (activeNakladkaRef?.current && !activeNakladkaRef?.current?.nazevMista) {
                activeNakladkaRef.current = Waypoint.fromOkoli(props.value)
            }

            if (isInForm) {
                osmPlaceResult = calculateDokladkyOsmPlaceResultInForm(activeIsUserSetEmptyOkoliOdkudRef, activeNakladkaRef, props.value)
            } else {
                osmPlaceResult = calculateDokladkyOsmPlaceResultInColumn(activeIsUserSetEmptyOkoliOdkudRef, activeNakladkaRef, props.value)
            }
        } else if (options?.isFilterKam) {
            if (activeVykladkaRef?.current && !activeVykladkaRef?.current?.nazevMista) {
                activeVykladkaRef.current = Waypoint.fromOkoli(props.value)
            }

            if (isInForm) {
                osmPlaceResult = calculateDokladkyOsmPlaceResultInForm(activeIsUserSetEmptyOkoliKamRef, activeVykladkaRef, props.value)
            } else {
                osmPlaceResult = calculateDokladkyOsmPlaceResultInColumn(activeIsUserSetEmptyOkoliKamRef, activeVykladkaRef, props.value)
            }
        }
    }

    let radiusResult: any
    if (!options?.isDokladky) {
        radiusResult = osmPlaceResult ? (props.value?.radius ?? props.data?.[props.name]?.radius ?? null) : null;
    } else if (osmPlaceResult) {
        if (options?.isFilterOdkud) {
            if (!activeNakladkaRadiusRef?.current && activeNakladkaRef?.current?.koordinat && props?.value?.koordinat && activeNakladkaRadiusRef?.current != props?.value?.radius) {
                activeNakladkaRadiusRef.current = props?.value?.radius
            }

            if (options?.filterForm) {
                radiusResult = calculateDokladkyRadiusResultInForm(osmPlaceResult, activeNakladkaRadiusRef)
            } else {
                radiusResult = calculateDokladkyRadiusResultInColumn(osmPlaceResult, activeNakladkaRadiusRef)
            }
        } else if (options?.isFilterKam) {
            if (!activeVykladkaRadiusRef?.current && activeVykladkaRef?.current?.koordinat && props?.value?.koordinat && activeVykladkaRadiusRef?.current != props?.value?.radius) {
                activeVykladkaRadiusRef.current = props?.value?.radius
            }

            if (options?.filterForm) {
                radiusResult = calculateDokladkyRadiusResultInForm(osmPlaceResult, activeVykladkaRadiusRef)
            } else {
                radiusResult = calculateDokladkyRadiusResultInColumn(osmPlaceResult, activeVykladkaRadiusRef)
            }
        }
    } else {
        radiusResult = null;
    }

    const [localVicinity, setLocalVicinity] = useState<{osmPlace: string | null, radius: string | null}>(nullVicinity);

    useEffect(() => {
        setLocalVicinity({osmPlace: osmPlaceResult, radius: radiusResult})
    }, [props.data, props.value]);

    const defaultValue: Vicinity = {
        psc: null,
        mpz: null,
        nazevMista: null,
    }

    const onChange = (v?: Vicinity, isUserClearOkoli: boolean = false) => {
        props.onValueChanged(v)
        invoke(options.onChange, v, isUserClearOkoli);
    }

    useImperativeHandle(props.componentRef, () => {
        return {
            selectFirstField: () => onFieldFocused(1),
            onChange
        }
    })

    return (
        <>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField
                        title={options?.showTitle&&options?.titleOkoli}
                        placeholder={options?.titleOkoli}
                        type={"Custom"}
                        customComponent={FormNominatim}
                        focused={focused===1}
                        customComponentOptions={{
                            checkLicense: true,
                            countryCodeForCompare: options?.countryCodeForCompare,
                            autofillValue: true,
                            onChange: (osmPlace: any) => {
                                if (!options?.isDokladky && !options?.isPrejezdy) {
                                    const waypoint = osmPlace?.name ? Waypoint.fromOsmPlace(osmPlace) : null;

                                    const tempValue: Vicinity = waypoint ? {
                                        ...props.value, ...defaultValue,
                                        osmPlace: formatForRaalUsers(osmPlace, () => resolveMpz(osmPlace.address?.country_code)),
                                        countryCode: waypoint?.countryCode,
                                        koordinat: _.isEmpty(waypoint.koordinat) ? null : waypoint.koordinat,
                                        radius: null
                                    } : null
                                    onChange(tempValue);
                                    if (tempValue == null) {
                                        setLocalVicinity(nullVicinity);
                                        return;
                                    }
                                    setLocalVicinity({...localVicinity, osmPlace: tempValue.osmPlace, radius: null})
                                } else if (options?.isPrejezdy) {
                                    const waypoint = osmPlace?.name ? Waypoint.fromOsmPlace(osmPlace) : null;

                                    if (options?.isFilterOdkud) {
                                        if (waypoint) {
                                            activeIsUserSetEmptyOkoliOdkudRef.current = false
                                        } else {
                                            activeIsUserSetEmptyOkoliOdkudRef.current = true
                                        }
                                    } else if (options?.isFilterKam) {
                                        if (waypoint) {
                                            activeIsUserSetEmptyOkoliKamRef.current = false
                                        } else {
                                            activeIsUserSetEmptyOkoliKamRef.current = true
                                        }
                                    }
                                    const tempValue: Vicinity = waypoint ? {
                                        ...props.value, ...defaultValue,
                                        osmPlace: formatForRaalUsers(osmPlace, () => resolveMpz(osmPlace.address?.country_code)),
                                        countryCode: waypoint?.countryCode,
                                        koordinat: _.isEmpty(waypoint.koordinat) ? null : waypoint.koordinat,
                                        radius: null
                                    } : null
                                    onChange(tempValue);
                                    if (tempValue == null) {
                                        setLocalVicinity(nullVicinity);
                                        return;
                                    }
                                    setLocalVicinity({...localVicinity, osmPlace: tempValue.osmPlace, radius: null})
                                } else if (options?.isDokladky) {
                                    if (options?.isFilterOdkud) {
                                        const waypoint = osmPlace?.name ? Waypoint.fromOsmPlace(osmPlace) : null;
                                        activeIsUserSetEmptyOkoliOdkudRef.current = !waypoint;

                                        const tempValue: Vicinity = waypoint ? {
                                            ...props.value, ...defaultValue,
                                            osmPlace: formatForRaalUsers(osmPlace, () => resolveMpz(osmPlace.address?.country_code)),
                                            countryCode: waypoint?.countryCode,
                                            koordinat: _.isEmpty(waypoint.koordinat) ? null : waypoint.koordinat,
                                            radius: null
                                        } : null

                                        handleDokladkyOsmPlaceChange(tempValue, activeNakladkaRadiusRef, activeNakladkaRef, nullVicinity, setLocalVicinity, onChange,);
                                    } else if (options?.isFilterKam) {
                                        const waypoint = osmPlace?.name ? Waypoint.fromOsmPlace(osmPlace) : null;
                                        activeIsUserSetEmptyOkoliKamRef.current = !waypoint;

                                        const tempValue: Vicinity = waypoint ? {
                                            ...props.value, ...defaultValue,
                                            osmPlace: formatForRaalUsers(osmPlace, () => resolveMpz(osmPlace.address?.country_code)),
                                            countryCode: waypoint?.countryCode,
                                            koordinat: _.isEmpty(waypoint.koordinat) ? null : waypoint.koordinat,
                                            radius: null
                                        } : null

                                        handleDokladkyOsmPlaceChange(tempValue, activeVykladkaRadiusRef, activeVykladkaRef, nullVicinity, setLocalVicinity, onChange,);
                                    }
                                }
                            }
                        }}
                        onBlur={()=>{
                            invoke(props.onBlur);
                        }}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={props.onKeyDown}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        textFieldProps={textFieldProps}
                        value={localVicinity?.osmPlace}
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(1);
                            }
                        }}
                    />
                </Box>
            </Grid>
            <div style={{height: options?.verticalSpace ?? 8}}/>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField
                        focused={focused===2}
                        numberProps={{...options?.numberProps, format: '0,0.[00]', constraint: {precision:9, scale:4}}}
                        onBlur={()=>{
                            invoke(props.onBlur);
                        }}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={(e) => {
                            // Rozlisujeme medzi riadkovym a rozsirenym filtrom
                            if (isInForm) {
                                if (!options?.isDokladky) {
                                    if (!props.data[props.name] || !osmPlaceResult) {
                                        e.preventDefault();
                                        return;
                                    }
                                    props.onKeyDown && props.onKeyDown(e);
                                } else {
                                    if (!localVicinity?.osmPlace) {
                                        e.preventDefault();
                                        return;
                                    }
                                    props.onKeyDown && props.onKeyDown(e);
                                }
                            }
                            else {
                                if (!options?.isDokladky) {
                                    if (!exist(props.value?.koordinat)) {
                                        e.preventDefault();
                                        return;
                                    }
                                } else {
                                    if (!localVicinity?.osmPlace) {
                                        e.preventDefault();
                                        return;
                                    }
                                }
                                props.onKeyDown&&props.onKeyDown(e);
                            }
                        }}
                        type={FormInputType.Number}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        value={localVicinity.radius}
                        onValueChanged={v => {
                            if (!localVicinity.osmPlace) { // zamezeni zmeny radiusu, pokud neni zadane misto
                                return
                            }

                            let tmpValue: Vicinity | null;
                            if (isInForm) { // AK JE FORMULAR
                                if (!options?.isDokladky) {
                                    if (!props.data[props.name]) {
                                        tmpValue = null;
                                    } else {
                                        tmpValue = {
                                            ...props.data[props.name], radius: v
                                        }
                                    }
                                } else if (options?.isFilterOdkud) {
                                    tmpValue = handleDokladkyRadiusChangeInForm(v, props, props.name, activeIsEmptyOkoliOdkudLastSearchRef, activeNakladkaRadiusRef, defaultValue)
                                } else if (options?.isFilterKam) {
                                    tmpValue = handleDokladkyRadiusChangeInForm(v, props, props.name, activeIsEmptyOkoliKamLastSearchRef, activeVykladkaRadiusRef, defaultValue)
                                }
                            } else { // AK JE RIADKOVY FILTER
                                if (!options?.isDokladky) {
                                    tmpValue = exist(props.value?.koordinat) ? {
                                        ...props.value, ...defaultValue, radius: v
                                    } : {...props.value}
                                } else if (props.value) {
                                    if (options?.isFilterOdkud) {
                                        tmpValue = handleDokladkyRadiusChangeInColumn(v, props.value, activeIsEmptyOkoliOdkudLastSearchRef, activeNakladkaRadiusRef, defaultValue)
                                    } else if (options?.isFilterKam) {
                                        tmpValue = handleDokladkyRadiusChangeInColumn(v, props.value, activeIsEmptyOkoliKamLastSearchRef, activeVykladkaRadiusRef, defaultValue)
                                    }
                                }
                            }
                            const isUserClearOkoli = !v;
                            onChange(tmpValue, isUserClearOkoli);
                            setLocalVicinity({...localVicinity, radius:v})
                        }}
                        disabled={props.customComponentOptions?.okoliDisabled || options?.okoliDisabled}
                        title={options?.showTitle&&options?.titleRadius}
                        placeholder={props.customComponentOptions?.okoliDisabled ? '' : options?.titleRadius}
                        textFieldProps={textFieldProps}
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(2);
                            }
                        }}
                    />
                </Box>
            </Grid>
        </>
    );
}
