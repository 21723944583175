import React, { createContext, useContext, useState } from "react";

export type FormVicinityContainerFilterViewMode = "FormVicinity" | "FormVicinityAddress";

interface FormVicinityContainerContextProps {
    formVicinityContainerFilterOdkudViewMode: FormVicinityContainerFilterViewMode;
    setFormVicinityContainerFilterOdkudViewMode: (mode: FormVicinityContainerFilterViewMode) => void;
    formVicinityContainerFilterKamViewMode: FormVicinityContainerFilterViewMode;
    setFormVicinityContainerFilterKamViewMode: (mode: FormVicinityContainerFilterViewMode) => void;
}

// Context byl vytvoren pro potreby task #4664 - PROHLIZENI + ARCHIV ZADANI + ARCHIV PROHLIZENI - synchronizace zobrazeni filtru ODKUD a KAM
const FormVicinityContainerContext = createContext<FormVicinityContainerContextProps | undefined>(undefined);

export const FormVicinityContainerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [formVicinityContainerFilterOdkudViewMode, setFormVicinityContainerFilterOdkudViewMode] = useState<FormVicinityContainerFilterViewMode>(null);
    const [formVicinityContainerFilterKamViewMode, setFormVicinityContainerFilterKamViewMode] = useState<FormVicinityContainerFilterViewMode>(null);

    return (
        <FormVicinityContainerContext.Provider
            value={{
                formVicinityContainerFilterOdkudViewMode,
                setFormVicinityContainerFilterOdkudViewMode,
                formVicinityContainerFilterKamViewMode,
                setFormVicinityContainerFilterKamViewMode,
            }}
        >
            {children}
        </FormVicinityContainerContext.Provider>
    );
};

export const useFormVicinityContainerContext = (): FormVicinityContainerContextProps => {
    const context = useContext(FormVicinityContainerContext);
    if (!context) {
        throw new Error("useFormVicinityContainerContext must be used within a FormVicinityContainerProvider");
    }
    return context;
};