import * as React from "react";
import {useRef, useState} from "react";


import {useTranslation} from "react-i18next";
import {FormField, FormInputType, useFormData} from "../../../raal_components/form/Form";
import {Dial} from "../../../raal_components/dial/Dial";
import {Provozovna, Stav} from "../../../model/Provozovna";
import {UserProvozovnaFirmaDetailView} from "../UserProvozovnaFirmaDetailView";
import {Grid} from '@material-ui/core';
import {ProvozovnaFilter} from "../admin/ProvozovnaCiselnik";
import {Column} from "../../../raal_components/grid/DataGrid.d";
import {useKlasifikaceSelect, useStatSelectAsAutocomplete} from '../../../raal_components/SelectOptions';
import {FlagIcon} from "../../../../common/component/FlagIcon";
import {evaluateDataVisibility} from "../../../../common/logic/visibility-logic";
import {FormNominatim} from "../../../../common/component/form/FormNominatim";
import {Waypoint} from "../../../model/Waypoint";
import {useAppContext} from "../../../context/AppContext";
import {DataGridExposed} from "../../../raal_components/grid/DataGrid";
import {exist} from "../../../../common/utils/Util";
import numeral from "numeral";
import {sizeIntegerFormat} from "./_vp/PrepravaAVozidlaShared";
import moment from "moment";
import {checkComma} from "../../../raal_components/grid/MTExportContainer";
import {User} from "../../../model/User";
import {TabHelper} from "../../../raal_components/controller/TabHelper";
import {
    useClearZaznamOznaceni,
    useZaznamOznaceni,
    useZaznamOznaceniStyle,
    ZaznamOznaceniTyp
} from "./Oznaceni";

export function ProvozovnaFilterForm() {
    const statSelectAutocompleteFilter = useStatSelectAsAutocomplete({isClearable:true});
    const {t} = useTranslation();
    const klasifikaceSelect = useKlasifikaceSelect({isClearable: true})
    const filterData = useFormData<ProvozovnaFilter>();
    const {user} = useAppContext();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kod"} title={t("Provozovna.Kod")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pNazev"} title={t("Provozovna.PNazev")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <FormField name={"pUlice"} title={t("Provozovna.PUliceCisloPopisne")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pObec"} title={t("Provozovna.PObec")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"pCastObce"} title={t("Provozovna.PCastObce")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"pPsc"} title={t("Provozovna.PPsc")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"pStat"} title={t("Provozovna.PStat")} type={"select"}  selectProps={statSelectAutocompleteFilter}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"firma.ico"} title={t("Provozovna.IcoDic")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"firma.http"} title={t("Firma.http")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <FormField name={"telCislo"} title={t("Provozovna.PhoneNumbersFilter")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <FormField name={"email"} title={t("Provozovna.EmailsFilter")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"datovaSchranka"} title={t("Provozovna.DatovaSchranka")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"not1"} title={t("Default.Note")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Klasifikace.klasifikace")} name='klasifikace.klasifikace' type='select' selectProps={klasifikaceSelect}/>
            </Grid>
            {user.canShowKmFeature() ? <Grid container item spacing={1}>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("Provozovna.okoli")} name={"okoli"} type={"Custom"} customComponent={FormNominatim}
                               getValue={(data) => {
                                   return data.okoli?.nazevMista;
                               }}
                               setValue={(data, fieldName, value) => {
                                   data.okoli.nazevMista = value;
                               }}
                               customComponentOptions={{
                                   enableOSRM: true,
                                   autofillValue: true,
                                   onChange: (osmPlace: any) => {
                                       filterData.okoli =  Waypoint.fromOsmPlace(osmPlace);
                                   }
                               }}
                    />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <FormField title={t("Provozovna.radius")} name={"radius"} type={FormInputType.Number}/>
                </Grid>
            </Grid> : undefined}
        </Grid>
    );
}


export function ProvozovnaCiselnikUser() {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const dataGridRef = useRef<DataGridExposed<Provozovna, ProvozovnaFilter>>();
    const [okoliFiltering, setOkoliFiltering] = useState(false)
    const {pdfLayout} = useProvozovnaPdfLayout(user);
    const getStav = () => {
        return <div style={{fontWeight: 'bold', color: 'red'}}>{t("Provozovna.stopped")}</div>;
    }
    const zaznamOznaceni = useZaznamOznaceniStyle(ZaznamOznaceniTyp.PROVOZOVNA);
    const clearZaznamOznaceni = useClearZaznamOznaceni(
        useZaznamOznaceni("user", ZaznamOznaceniTyp.PROVOZOVNA),
        () => {dataGridRef.current?.table()?.refresh()}
    );

    const columns:Column<Provozovna>[] = [
         {
            title: t("Provozovna.airDistance").toUpperCase(),
            field: 'airDistance',
            defaultSort: !okoliFiltering ? undefined : "asc",
            readonly: true,
            hidden: !okoliFiltering,
            cellStyle: {width: 50},
            headerStyle: {width: 50},
            render: (data: Provozovna) => data.airDistance && numeral(data.airDistance).format(sizeIntegerFormat),
        },
        {
            title: t("Provozovna.Kod").toUpperCase(),
            field: 'kod',
            defaultSort: okoliFiltering ? undefined : "asc",
            filterProps:() => ({type:FormInputType.Text}),
            cellStyle: {width: 50},
            headerStyle: {width: 50},
            render(data: Provozovna) {return evaluateDataVisibility(data, data?.firma, "provozovna.kod", false) ? data.kod : null }
        },
        {
            title: t("Provozovna.PNazev").toUpperCase(),
            field: 'pNazev',
            filterProps:() => ({type:FormInputType.Text}),
            render(data: Provozovna){
                if (!evaluateDataVisibility(data, data?.firma, "provozovna.pNazev", false)) return null;
                return data.pNazev;
            }
        },
        {
            title: t("Provozovna.PUliceCisloPopisne").toUpperCase(),
            field: 'pUlice',
            filterProps:() => ({type:FormInputType.Text}),
            render(data: Provozovna) {
                if (data.stav !== Stav.JEDE) return getStav();
                if (!evaluateDataVisibility(data, data?.firma, "provozovna.pUlice", false)) return null;
                let streetCp: string[] = [];
                data?.pUlice&&streetCp.push(data?.pUlice);
                data?.pCisloPopisne&&streetCp.push(data?.pCisloPopisne);
                return streetCp.join(' ') ;
            }
        },
        {
            title: t("Provozovna.PPsc").toUpperCase(),
            field: 'pPsc',
            filterProps:() => ({type:FormInputType.Text}),
            cellStyle: {width: 80},
            headerStyle: {width: 80},
            render(data: Provozovna){
                if (!evaluateDataVisibility(data, data?.firma, "provozovna.pPsc", false)) return null;
                return data.pPsc;
            }
        },
        {
            title: t("Provozovna.PObec").toUpperCase(),
            field: 'pObec',
            filterProps:() => ({type:FormInputType.Text}),
            render(data: Provozovna){
                if (!evaluateDataVisibility(data, data?.firma, "provozovna.pObec", false)) return null;
                return data.pObec;
            }
        },
        {
            title: t("Provozovna.PStat").toUpperCase(),
            field: 'pStat',
            filterProps:() => ({type:FormInputType.Text}),
            render(data: Provozovna) {
                if (!evaluateDataVisibility(data, data?.firma, "provozovna.pStat", false)) return null;
                return <FlagIcon mpz={data.pStat} />
            },
            cellStyle: {width: 80},
            headerStyle: {width: 80},
        },
        {
            title: t("Provozovna.IcoDic").toUpperCase(),
            field: 'firma.ico',
            sorting:false,
            filterProps:() => ({type: FormInputType.Text}),
            render(data: Provozovna) {
                if (!evaluateDataVisibility(data, data?.firma, ["firma.ico", "firma.dic", "firma.dic1"], false)) return null;
                if (data.stav === Stav.JEDE) {
                    return  t("Provozovna.UserInDetail")
                }
                return null;
            }
        },
        {
            title: t("Default.PhoneNumbersKratke").toUpperCase(),
            field: 'telCislo',
            sorting:false,
            filterProps: () => ({type: FormInputType.Text}),
            render(data: Provozovna) {
                if (!evaluateDataVisibility(data, data?.firma, "provozovna.telCislaKontaktu", false)) return null;
                return t("Provozovna.UserInDetail");
            }
        },
        {
            title: t("Default.Email").toUpperCase(),
            field: 'email',
            sorting:false,
            filterProps: () => ({type: FormInputType.Text}),
            render(data: Provozovna) {
                if (!evaluateDataVisibility(data, data?.firma, "provozovna.email", false)) return null;
                return t("Provozovna.UserInDetail");
            }
        }
    ].filter(i => {
        return exist(i);
    }) as Column<Provozovna>[];

    return (
        <Dial<Provozovna, ProvozovnaFilter> config={{
            id:"provozovna_uzivatel",
            clazz:Provozovna,
            tableTitle: t("Sections.Company").toUpperCase(),
            endpoint: `user/provozovna`,
            columns: columns,
            hideAddNewActions: true,
            actions: [],
            hideDefaultDummyAction:true,
            hidePaginationButtons:false,
            lazyLoading: false,
            footerPagingText: t("Provozovna.FilterInfo"),
            maxCount: 30,
            defaultSort: {field: "kod", orderDirection: "asc"},
            prioritySort: [{field: "okoli", orderBy: "airDistance", orderDirection: "asc"}],
            options: {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = zaznamOznaceni.getStyle(data);
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            alternativeButtons: [clearZaznamOznaceni],
            exportConfig: {
                exportable: true,
                exportDetailOnly: true,
                endpoint: null,
                fileName: "export_provozovna",
                translationPrefix: 'Provozovna',
                formats: ["csv", "xls", "pdf"],
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin, true),
                exportableProps: [
                    {
                        type: ["csv", "xls"],
                        fields: ["kod", "pNazev", "pUlice", "pCisloPopisne", "pPsc", "pObec", "pStat", "kNazev", "kUlice", "kCisloPopisne", "kPsc", "kObec", "kStat", "datovaSchranka", "http", "spStat", "jazyky", "auta", "not1", "pojisteni", "uOsobaUser", "uTelefonUser", "uEmailUser", "emaily", "telCislaKontaktu", "dispeceri", "r1Nazev", "r1Ulice", "r1CisloPopisne", "r1Psc", "r1Obec", "r1Stat", "ico", "dic", "dic1", "regDatum", "datSmlouvy"]
                    },
                    {
                        type: ["pdf"],
                        fields: ["r1Nazev", "ico", "r1Ulice", "dic", "r1CisloPopisne", "dic1", "r1Obec", "regDatum", "r1Psc", "datSmlouvy", "r1Stat",
                        "kod", "pNazev", "kNazev", "pUlice", "kUlice", "pCisloPopisne", "kCisloPopisne", "pPsc", "kPsc", "pObec", "kObec", "pStat", "kStat", "datovaSchranka", "http", "spStat", "jazyky", "auta", "not1", "pojisteni", "uOsobaUser", "uTelefonUser", "uEmailUser", "emaily", "telCislaKontaktu", "dispeceri"]
                    }
                ],
                extendedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        translationPrefix: ["Firma"],
                        addExtendedProps: (data) => {
                            return {
                                r1Nazev: data.firma?.r1Nazev,
                                r1Obec: data.firma?.r1Obec,
                                r1Ulice: data.firma?.r1Ulice,
                                r1CisloPopisne: data.firma?.r1CisloPopisne,
                                r1Psc: data.firma?.r1Psc,
                                r1Stat: data.firma?.r1Stat,
                                ico: data.firma?.ico,
                                dic: data.firma?.dic,
                                dic1: data.firma?.dic1,
                                regDatum: data.firma?.regDatum,
                                datSmlouvy: data.firma?.datSmlouvy,
                                pojisteni: data.firma?.pojisteni,
                                http: data.firma?.http
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "emaily",
                        format: (data) => {
                            return data ? data.join(', ') : null;
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "telCislaKontaktu",
                        format: (data) => {
                            return data && data.length !== 0 ? data.map((d: any) => `${d.slice(0, 4)} ${d.slice(4)}`).join(', ') : null;
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "dispeceri",
                        format: (data) => {
                            return data && data.length !== 0 ? data.filter((d: any) => d.jmeno).map((d: any) => d.formatDisplayName()).join(', ') : null;
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "uTelefonUser",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
        }}


        onFilterChanged={() => {
            const isOkoliFiltering = exist(dataGridRef.current?.table()?.getFilter()?.filters?.okoli) && exist(dataGridRef.current?.table()?.getFilter()?.filters?.radius);
            if(okoliFiltering !== isOkoliFiltering)
                setOkoliFiltering(isOkoliFiltering);
        }}
        gridRef={dataGridRef}
        hideSaveButton={false}
        hideNewButtonOnEdit
        crudConfig={{editEnabled: true, removeEnabled:false, addEnabled:false}}
        layoutFilter={() => <ProvozovnaFilterForm />}
        layoutForm={()=><TabHelper<Provozovna> render={(d)=>
            <>
              <UserProvozovnaFirmaDetailView adminView={false} provozovnaId={d.id}/>
            </>}/>
        }
        filterClazz={ProvozovnaFilter}
        />
    );
}

export const useProvozovnaPdfLayout = (user: User) => {
    const {t} = useTranslation();
    const pdfLayout = (data: any, fields: any, pageBreak: boolean, index: number, origin: any, addHeader?:boolean) => {
        const provozovna = origin?.uzivatel?.provozovna ? origin?.uzivatel?.provozovna : origin;
        const firma = provozovna?.firma;
        const itemCustom = (title: string, data: string, checkString?: boolean, marginLeft: number = 0) => {
            //Test string length
            if (checkString && data && data.length > 25) {
                const stringPartsArray = data.split(' ');
                for (let i = 0; i < stringPartsArray.length; i++) {
                    if (stringPartsArray[i].length > 25) {
                        const parts = stringPartsArray[i].match(/.{1,25}/g);
                        stringPartsArray[i] = parts.join(' ');
                    }
                }

                data = stringPartsArray.join(' ');
            }
            return <div style={{marginLeft: marginLeft}}><strong>{title ? `${title}: ` : null}</strong><span>{data}</span></div>
        }

        return <div className={'font-9'} key={'div_' + index}>
            {addHeader ? <table data-pdfmake="{'widths':['*','*']}" style={{marginBottom: 10}}>
                <tr>
                    <td rowSpan={2} style={{border: 0}}><img src={'logo'} style={{width: 200}} alt={"img"} /></td>
                    <td style={{border: 0, textAlign: 'right'}}>{`${t('Default.PdfExportedUser')}: ${user.jmeno ?? '-'}`}</td>
                </tr>
                <tr>
                    <td style={{border: 0, textAlign: 'right'}}>{`${t('Default.PdfExportedDate')}: ${moment().format('L LT')}`}</td>
                </tr>
            </table> : null}
            <hr/>
            <div style={{textAlign: 'center'}}>
                <h6>{t('Default.PdfFirma').replace("{0}", provozovna?.kod ?? '')}</h6>
            </div>
            <hr/>
            <table data-pdfmake="{'widths':['*','*','*']}">
                <tr>
                    <td style={{border: 0}}><strong>{`${t('Firma.UserContactLabel')}: `}</strong></td>
                    <td style={{border: 0}}><strong>{`${t('Provozovna.UserContactLabel')}: `}</strong></td>
                    <td style={{border: 0}}><strong>{`${t('Provozovna.UserCorespondLabel')}: `}</strong></td>
                </tr>
                <tr>
                    <td style={{border: 0}}>
                        <div>
                            <div>{itemCustom(null, firma?.r1Nazev ?? '', true)}</div>
                            <div>{itemCustom(null, `${firma?.r1Ulice ?? ''} ${firma?.r1CisloPopisne ?? ''}`, true)}</div>
                            <div>{itemCustom(null, `${firma?.r1Psc ?? ''} ${firma?.r1Obec ?? ''} ${firma?.r1Stat ?? ''}`, true)}</div>
                        </div>
                    </td>
                    <td style={{border: 0}}>
                        <div>
                            <div>{itemCustom(null, provozovna?.pnazev ?? provozovna?.pNazev, true)}</div>
                            <div>{itemCustom(null, `${provozovna?.pulice ?? provozovna?.pUlice ?? ''} ${provozovna?.pcisloPopisne ?? provozovna?.pCisloPopisne ?? ''}`, true)}</div>
                            <div>{itemCustom(null, `${provozovna?.ppsc ?? provozovna?.pPsc ?? ''} ${provozovna?.pobec ?? provozovna?.pObec ?? ''} ${provozovna?.pstat ?? provozovna?.pStat ?? ''}`, true)}</div>
                        </div>
                    </td>
                    <td style={{border: 0}}>
                        <div>
                            <div>{itemCustom(null, provozovna?.knazev ?? provozovna?.kNazev ?? '', true)}</div>
                            <div>{itemCustom(null, `${provozovna?.kulice ?? provozovna?.kUlice ?? ''} ${provozovna?.kcisloPopisne ?? provozovna?.kCisloPopisne ?? ''}`, true)}</div>
                            <div>{itemCustom(null, `${provozovna?.kpsc ?? provozovna?.kPsc ?? ''} ${provozovna?.kobec ?? provozovna?.kObec ?? ''} ${provozovna?.kstat ?? provozovna?.kStat ?? ''}`, true)}</div>
                        </div>
                    </td>
                </tr>
            </table>
            <hr/>
            <table data-pdfmake="{'widths':['*','*','*']}">
                <tr>
                    <td style={{border: 0}}>{itemCustom(t('Firma.ico'), firma?.ico)}</td>
                    <td style={{border: 0}}>{itemCustom(t('Firma.dic'), firma?.dic)}</td>
                    <td style={{border: 0}}>{itemCustom(t('Firma.dic1'), firma?.dic1)}</td>
                </tr>
                <tr>
                    <td style={{border: 0}}>{itemCustom(t('Firma.vznik'), firma?.regDatum ? moment(firma?.regDatum).format('DD.MM.YYYY') : null)}</td>
                    <td style={{border: 0}}>{itemCustom(t('Firma.raalOd'), firma?.datSmlouvy ? moment(firma?.datSmlouvy).format('DD.MM.YYYY') : null)}</td>
                    <td style={{border: 0}}>{itemCustom(t('Provozovna.DatovaSchranka'), provozovna?.datovaSchranka ? provozovna.datovaSchranka : null, true)}</td>
                </tr>
            </table>
            <table data-pdfmake="{'widths':['*','*']}">
                <tr>
                    <td colSpan={2} style={{border: 0}}>{itemCustom(t('Provozovna.telCislaKontaktu'), checkComma(provozovna?.telCislaKontaktu?.join(', ')))}</td>
                </tr>
                <tr>
                    <td style={{border: 0}}>{itemCustom(t('Provozovna.emaily'), checkComma(provozovna?.emaily?.join(', ')))}</td>
                    <td style={{border: 0}}>{itemCustom(t('Firma.http'), firma?.http)}</td>
                </tr>
            </table>
        </div>
    }

    return {pdfLayout}
}
