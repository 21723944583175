import React, {useRef} from "react";
import {
    InvalidDuvodInzerat,
    InzeratFilterOznaceno,
    InzeratView,
    InzeratViewAdmin,
    InzeratViewArchiv,
    InzeratViewDetail
} from "../../../../model/Inzerat";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import {
    useCurrencySelectObj,
    useEnumeratedSelect,
    useInzeratInvalDuvSelect,
    useKlasifikaceSelect,
    useUserBasicSelect,
    useYesNoSelect
} from "../../../../raal_components/SelectOptions";
import {Grid} from "@material-ui/core";
import {FormNumberRange} from "../../../../../common/component/form/FormNumberRange";
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import {httpEndpointCustom} from "../../../../../common/utils/HttpUtils";
import {exist, formatPrice, truncateString} from '../../../../../common/utils/Util';
import {useAppContext} from "../../../../context/AppContext";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {GenericMap} from "../../../../../index.d";
import _ from "lodash";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {cenaConstraint, getExportableProps} from '../_vp/PrepravaAVozidlaShared';
import {cenaFormat, inzerceAdminExportableFields, useInzercePdfLayout} from "../zadani/InzerceCiselnik";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {InlinePhoneNumberDial} from "../../../../../common/component/PhoneNumberDial";
import {FormDateRange, FormDateRangeOptions} from "../../../../../common/component/form/FormDateRange";
import {InzeratSearchEntityType} from "../../../../model/CommonTypes";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ComponentMode} from "../../../../routes";
import {InzerceCiselnikFormNew} from "../_inzerce/InzerceCiselnikForm";
import {
    InzeratViewDetailFormBasicPart, InzeratViewDetailFormBasicPartNew
} from "../_inzerce/InzerceCiselnikViewDetailFormBasicPart";
import {InzerceViewAdminPart} from "./InzerceViewAdminPart";
import InzerceHistoryViewPart from "./InzerceHistoryViewPart";
import {useClearInzeratViewRecords, useZaznamOznaceniStyle } from "../Oznaceni";
import {InlineEmailClient} from "../../../../../common/component/EmailClient";

export interface InzerceViewCiselnikProps {
    admin?: boolean,
    archive?: boolean,
    viewArchive?: boolean,
    history?: InzeratViewDetail,
    filter?: GenericMap,
    isTabbed?:boolean,
    forceHistory?: boolean
    pvi?: boolean
    zadani?: boolean
    mode?: ComponentMode
    /**
     * Defines custom base path.
     */
    basePath?: string
    minimumTableHeight?: number
    id?: string
    cacheGroup?: string
}

/**
 * TODO - Přepsáno do New, ale uvnitř není stejná logika jako v ExtendedViewNew, bude třeba ještě projít a sloučit.
 * @param props
 * @constructor
 */
export default function InzerceCiselnikView(props: InzerceViewCiselnikProps = {admin: false, forceHistory: false, zadani: false}) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const oznacenoFilter = useRef(false);
    const refreshFun = useRef(() => {});
    const rangeCenaSharedOptions = {numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dateKey = "inzerat-view-browse-date";
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);
    const historyView = exist(props.history) || props.forceHistory;
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true});
    const [showConfirm] = useConfirmDialog();
    const {pathname} = useLocation();
    const dtGrid = useRef<DataGridExposed<InzeratViewAdmin, InzeratFilterOznaceno>>();
    const inzerceId = useHashId();
    const {pdfLayout} = useInzercePdfLayout(user);
    const userArchiveZadani = !props.viewArchive && props.archive && !props.admin
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const allowStomp = !props.archive && !props.viewArchive && !props.history && !props.zadani;
    const clearInzeratViewRecords =
        useClearInzeratViewRecords(() => {dtGrid.current?.table()?.refresh({}, null, true)})
    const klasifikaceSelect = useKlasifikaceSelect({isClearable:true, autoOpenDisabled: true, isMulti: true})

    const getEndPoint = () => {
        const accessUrl = props.admin ? 'admin' : 'user';
        if (props.zadani){
            // Vrátí zadani
            return `${accessUrl}/inzerat-history`;
        }
        if (props.archive) {
            if (props.viewArchive) {
                // Vrátí prohlížení archívu
                return `${accessUrl}/inzerat-prohlizeni-archiv`;
            } else {
                if (historyView) {
                    // Vrátí historii archivu inzerce
                    return `${accessUrl}/inzerat-historie-archiv`;
                } else {
                    // Vrátí archiv zadání
                    return `${accessUrl}/inzerat-archiv`;
                }
            }
        } else {
            if (historyView) {
                // Vrátí historii inzerce
                return `${accessUrl}/inzeratview-history`;
            } else {
                // Vrátí náhled inzerátu
                return `${accessUrl}/inzeratview`;
            }
        }
    }
    const nabidkaOznaceni = useZaznamOznaceniStyle(getEndPoint());

    return <>
        <Dial<InzeratViewAdmin, InzeratFilterOznaceno, InzeratViewDetail>
        mode={props.mode}
        isTabbed={props.isTabbed}
        tabDetailUrl= {props.basePath ? props.basePath : undefined}
        lastBrowsedDateKey={dateKey}
        isModal={!props.admin && !exist(inzerceId)}
        logActivity
        gridRef={dtGrid}
        onGridMount={()=>{
            refreshFun.current = () => dtGrid.current?.table()?.refresh();
            oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
        }}

        onFilterChanged={(data) => {
            oznacenoFilter.current =data?.oznaceno ?? false;
        }}
        filterClazz={InzeratFilterOznaceno}
        clazzDetail={InzeratViewDetail}
        config={{
            id: props.id,
            cache: {group: props.cacheGroup},
            initialPageSize: 100,
            minimumTableHeight: props.minimumTableHeight,
            lockSupport: {enabled: false},
            overflowHidden: true,
            watchChanges: !props.admin && !props.archive && !props.viewArchive,
            alternativeButtons: props.admin ? undefined : [clearInzeratViewRecords],
            requiredColumns: ['popis'],
            disableRowClick: !!props.history,
            defaultHiddenColumns: ["klasifikace"],
            exportConfig: {
                exportable: true,
                exportDetailOnly: !((props.archive && !props.viewArchive) || props.admin || props.zadani),
                endpoint: getEndPoint(),
                exportAll: props.admin && !historyView,
                fileName: !historyView ? (props.archive || props.viewArchive ? "export_archiv_inzerce" : "export_inzerce") : (props.archive || props.viewArchive ? "export_historie_archiv_inzerce" : "export_historie_inzerce"),
                translationPrefix: ['Inzerce', 'User', 'Preprava', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                exportableProps: getExportableProps(userArchiveZadani || props.admin ? inzerceAdminExportableFields : [
                    {
                        type: ["csv", "xls", "pdf"],
                        fields: ["popis", "rychlyKontakt", "cena", "currency", "provozovna", "dispecer", "uzivatel", "datIns",  "modifiedOn"]
                    }
                ], (props.archive || props.viewArchive) && !historyView),
                excludedProps: [
                    {
                        type: ["csv", "xls"],
                        fields: ["id", "sekce"]
                    }
                ],
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod,
                                ...(props.archive || props.viewArchive) && {archived: props.viewArchive ? data.datIns : data.deletedOn}
                            }
                        }
                    }
                 ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: (props.admin && !historyView) ? {
                rowStyle: (data: any) => {
                  if (!data.valid)
                      return globalStyles.rowStyleAlert;
                  if(data.invalDuv === InvalidDuvodInzerat.DUPLICITA && props.admin){
                      return globalStyles.rowStyleDuplicated;
                  }
                  return undefined;
                }
            } : {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = nabidkaOznaceni.getStyle(data);
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            stomp: allowStomp && {
                topic: props.pvi ? `/provozovna/${user.provozovna.kod}/crud-inzerat` : `/crud-inzerat`,
                toggleable: !props.admin,
                allowStompUiUpdates: true
            },
            clazz: InzeratViewAdmin,
            hideDefaultDummyAction: false,
            tableTitle: t("Dials.AdvertisingProhlizet").toUpperCase(),
            endpoint: getEndPoint(),
            filtering: true,
            hideAddNewActions: true,
            defaultQueryParameters: _.merge(props.history ? {parentId: props.history.id}: {}, props.filter),
            actions: props.admin && !props.archive ? [(data:InzeratViewAdmin) => ({
                hidden: historyView || data?.invalDuv===InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM,
                icon: () => <Cancel/>,
                onClick: async () => {
                    showConfirm({
                        body: t("Inzerce.InvalidInzeratConfirm"),
                        onConfirm: async () => {
                            try {
                                dtGrid.current.table().setLoading(true);
                                await httpEndpointCustom(`admin/inzerat/${data.id}/invalidate`, {method: "POST"});
                                dtGrid.current.table().refresh();
                            } finally {
                                dtGrid.current.table().setLoading(false);
                            }
                        }
                    });
                }
            }),
                (data:InzeratViewAdmin) => ({
                    hidden: historyView || data?.invalDuv!==InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM,
                    icon: () => <Check/>,
                    onClick: () => {
                        showConfirm({
                            body: t("Inzerce.ValidInzeratConfirm"),
                            onConfirm: async () => {
                                try {
                                    dtGrid.current.table().setLoading(true);
                                    await httpEndpointCustom(`admin/inzerat/${data.id}/validate`, {method: "POST"});
                                    dtGrid.current.table().refresh();
                                } finally {
                                    dtGrid.current.table().setLoading(false);
                                }
                            }
                        });
                    }
                })
            ] : [],
            columns: [
                historyView && !exist(props.history) ? {
                    title: t("Inzerce.Id"),
                    field: 'inzeratId',
                    filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
                    render: (data: InzeratViewAdmin) => data.parentExists ? <Link to={{
                        pathname: `/admin/nabidky/inzerce/${data.inzeratId}`,
                        state: {
                            forcePreviousPage: pathname
                        }
                    }
                    } onClick={(e) => {e.stopPropagation();}}>{data.inzeratId}</Link> : data.inzeratId
                    } : undefined,
                props.viewArchive ? {
                    title: t("Inzerce.Id"),
                    field: 'nabidkaId',
                    filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
                    render: (data: InzeratViewAdmin) => <Link to={{
                        pathname: `/prohlizet/inzerce/${data.nabidkaId}`,
                        state: {
                            forcePreviousPage: pathname
                        }
                    }
                    } onClick={(e) => {e.stopPropagation();}}>{data.nabidkaId}</Link>
                } : undefined,
                /*  skryto na žádost zákazníka dle #3832
                    Boolean(user.provozovna) && !historyView && !(props.archive)? {
                    title:t("PVI.Marked"),
                    field:"oznaceno",
                    render:(data: InzeratView) => <MarkIndicator checked={Boolean(data.oznaceno)} url="user/inzerat" id={data.id} afterCommit={() => {
                        if(oznacenoFilter.current)
                            refreshFun.current();
                    }}/>,
                    filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
                    sorting: false

                } : null,*/
                {
                    title: t("Inzerce.AdText").toUpperCase(),
                    field: 'popis',
                    filterProps:() => ({type:FormInputType.Text}),
                    cellStyle: {minWidth: 200},
                    headerStyle: {minWidth: 200}
                },
                {
                    title: t("Default.Telefon").toUpperCase(),
                    field: 'rychlyKontakt',
                    render:(row:InzeratView)=><InlinePhoneNumberDial hideIcon phoneNumber={row.rychlyKontakt} rowData={row} isInzerce={true}/>,
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title:t('Default.Email').toUpperCase(),
                    field:"email",
                    filterProps: () => ({type: FormInputType.Text}),
                    sorting: false,
                    render:(data:InzeratView)=> <InlineEmailClient hideIcon emailAddress={data.email} rowData={data}/>,
                },
                {
                    title: t("Inzerce.Price").toUpperCase(),
                    field: 'cena',
                    render:(data:InzeratView) => {
						const price = userArchiveZadani ? data.cena ?? '' : data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? ''
						return data.jednotka ? price + "/" + data.jednotka : price
					},
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions:{...rangeCenaSharedOptions, verticalSpace: 0}}),
                    cellStyle: {textAlign:"right"}
                },
                userArchiveZadani && {
                    title: t("Preprava.currency").toUpperCase(),
                    field: "currency",
                    render:(data:InzeratView)=> data.currency?.name ?? '',
                    filterProps:() => ({type: FormInputType.Select, selectProps: currencySelectProps}),
                },
                userArchiveZadani && {
                    title: t("User.Title").toUpperCase(),
                    field: 'uzivatel',
                    render:(row:InzeratViewArchiv)=> row.uzivatel ? `${row.uzivatel?.login ?? ''} ${row?.uzivatel.jmeno ? `(${row.uzivatel.jmeno})` : ""}` : '',
                    filterProps:() => ({type: FormInputType.Select, selectProps: userSelectProps}),
                    cellStyle: {minWidth: 180},
                    headerStyle: {minWidth: 180}
                },
                (!props.archive || props.viewArchive || props.admin) &&  {
                    title: t("Provozovna.Kod").toUpperCase(),
                    field: 'provozovna',
                    render:(data:InzeratView) => data.provozovna && data.firma ? truncateString(`${data.provozovna} (${data.firma})`, 20) : '',
                    filterProps: () => ({type: FormInputType.Text})
                },
				// #4233 Odstranit sloupec Dispečer
                // !userArchiveZadani && {
                //     title: t("Inzerce.Dispecer"),
                //     field: 'dispecer',
                //     render:(row:InzeratView)=> `${row.dispecer?.jmeno ?? ''}`,
                //     filterProps:() => ({type: FormInputType.Text, name: "dispecerText"}),
                //     cellStyle: {width: 200, maxWidth: 200},
                //     headerStyle: {width: 200, maxWidth: 200}
                // },
                (props.admin) ?
                {
                    title: t("PVI.Inserted").toUpperCase(),
                    field: 'datIns',
                    render: (data:InzeratView) => data.datIns?.format("L LT")
                } : undefined,
                {
                    title: t("PVI.Modified").toUpperCase(),
                    field: 'modifiedOn',
                    defaultSort: props.archive && !historyView ? undefined : "desc",
                    render: (data: any) => data.modifiedOn?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false, verticalSpace: 0},  name: "modifiedOnRange"}),
                },
                ...(props.admin || userArchiveZadani ? [
                    !userArchiveZadani && {
                        title: t("PVI.Valid").toUpperCase(),
                        field: 'valid',
                        render: (data: InzeratViewAdmin) => <CheckMark checked={data.valid}/>,
                        filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
                    },
                    {
                        title: t("PVI.InvalDuv").toUpperCase(),
                        field: 'invalDuv',
                        render: (data: InzeratViewAdmin) =>  {return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data.invalDuv)},
                        filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                        cellStyle: {minWidth: 180},
                        headerStyle: {minWidth: 180}
                    },
                    !userArchiveZadani && {
                        title: t("User.Title").toUpperCase(),
                        field: 'uzivatelText',
                        render:(data:InzeratViewAdmin)=> data.uzivatel?.toString() ?? "",
                        filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                        cellStyle: {minWidth: 180},
                        headerStyle: {minWidth: 180}
                    }
                ] : []),
                props.archive && !historyView ?
                    {
                        title: t("Archive.Archived").toUpperCase(),
                        field: props.viewArchive ? 'datIns' : 'deletedOn',
                        defaultSort: props.archive ? "desc" : undefined,
                        render:(data:InzeratViewAdmin) =>  (props.viewArchive ? data.datIns : data.deletedOn)?.format("L LT") ?? "",
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false},  name: props.viewArchive ? 'datInsRange' : 'deletedOnRange'}),
                    }
                    : undefined,
                {
                    title: t("Klasifikace.klasifikaceShort").toUpperCase(),
                    field: 'klasifikace',
                    render: (data: any) => data.klasifikace ?  data.klasifikace.klasifikace : "",
                    filterProps: () => ({type: FormInputType.Select, selectProps: klasifikaceSelect, name: 'klasifikace.klasifikace'}),
                    tooltip: t("Klasifikace.klasifikace").toUpperCase(),
                    sorting: false,
                }



            ].filter(i => Boolean(i)) as Column<InzeratView>[]
        }}
        layoutDetail={()=><TabHelperNew<InzeratViewDetail> render={(data) =>
            <>
                <InzeratViewDetailFormBasicPart data={data} admin={props.admin} archive={props.archive} viewArchive={props.viewArchive} history={Boolean(props.history)}/>
                {props.admin ? <InzerceViewAdminPart inzerat={data} history={historyView} archive={props.archive}/> : null}
            </>}/>}
        crudConfig={{addEnabled:false, editEnabled:false, removeEnabled: props.viewArchive}}
        layoutFilter={() =><InzerceViewCiselnikFilter {...{lastBrowseDateDataKey: dateKey, historyTab: historyView, admin: props.admin, history: Boolean(props.history), historyView: historyView, archive: props.archive}}/>}
        layoutForm={!Boolean(props.history) && userArchiveZadani ? () => <InzerceCiselnikFormNew archiveZadani={true} /> : undefined}
        isDetailReadOnly={userArchiveZadani}
        formDisabled={() => userArchiveZadani}
        hideSaveButton
        hideNewButtonOnEdit
        tabs={[
        ...(props.admin || userArchiveZadani ? [
            historyView ? null : {
                title: t("Inzerce.History"),
                render: ()=><TabHelperNew<InzeratViewDetail> render={(data) => <InzeratHistoryViewTab inzerat={data} admin={props.admin} archive={props.archive}/>}/>
            }
            ].filter(i=>i) : [])
        ]}

    />
    </>
}

export type InzerceViewCiselnikFilterProps = {
    lastBrowseDateDataKey:string
    admin?: boolean
    history?: boolean
    historyView?: boolean
    archive?: boolean
    search?: boolean
    historyTab?: boolean
    mode?: ComponentMode
    isArchiveOfOwnOffers?: boolean
}

export function InzerceViewCiselnikFilter(props:InzerceViewCiselnikFilterProps) {
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    // const {user} = useAppContext();
    const invalDuvSelect = useInzeratInvalDuvSelect();
    const rangeCenaSharedOptions = {numberProps: {format:cenaFormat, constraint:cenaConstraint}};
    const klasifikaceSelect = useKlasifikaceSelect({isClearable: true})
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const {admin, historyView, archive, search} = props;
    const searchTypeSelect = useEnumeratedSelect(InzeratSearchEntityType, "SearchEnum.Inzerat", "string", {isClearable:true});

    return (
        <Grid container spacing={1}>
            {/* skryto na žádost zákazníka dle #3832
            {Boolean(user.provozovna) && !Boolean(history) && !Boolean(archive) && !search ?
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("PVI.Marked")} name={"oznaceno"} type={"select"} selectProps={yesNoSelect}/>
            </Grid> : null}*/}
            {((props.admin || props.search) && !props.historyTab) && (
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Default.Id")} name={"id"} type={"number"} numberProps={{format: '0.[00]'}}/>
                </Grid>
            )}
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Inzerce.Description")} name='popis' type='text'/>
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
                <FormField title={t("Default.Telefon")} name='rychlyKontakt' type='text'/>
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
                <FormField title={t("Default.Email")} name='email' type='text'/>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Provozovna.Kod")} name='provozovna' type='text'/>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Inzerce.Dispecer")} name='dispecerText' type='text'/>
            </Grid>
            {
                admin || search ?
                    <>
                        {!Boolean(archive) ? <Grid item xs={12} sm={6} lg={4}>
                            <FormField title={t("PVI.Valid")} name={"valid"} type={"select"} selectProps={yesNoSelect}/>
                        </Grid> : null}
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormField title={t("PVI.InvalDuv")} name={"invalDuv"} type={"select"} selectProps={invalDuvSelect}/>
                        </Grid>
                    </>
                    : null
            }
            {admin || search ?
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("User.Title")} name={"uzivatelText"} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
            </Grid> : undefined}
            {historyView && !exist(props.history) ?
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Inzerce.Id")} name={"id"} type={"number"} numberProps={{format: '0.[00]'}}/>
            </Grid> : null}
            {!admin && !Boolean(archive) && !search ?
            <Grid item xs={12} sm={12} lg={4}>
                <FormField title={t("Klasifikace.klasifikace")} name={'klasifikace.klasifikace'} type={'select'} selectProps={klasifikaceSelect}/>
            </Grid> : undefined}
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Currency.Title")} name='currency' type='select' selectProps={currencySelectProps} serverValidation={{translate:t}}/>
            </Grid>
            {search ?
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField title={t("SearchEnum.entityType")} name='entityType' type='select' selectProps={searchTypeSelect} serverValidation={{translate:t}}/>
                </Grid> : null
            }
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Inzerce.Price")} name="cenaRange" type="Custom" customComponent={FormNumberRange} customComponentOptions={rangeCenaSharedOptions}   />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField<FormDateRangeOptions> title={t("PVI.Modified")} name={"modifiedOnRange"} type={"Custom"}
                                                 customComponent={FormDateRange}
                                                 customComponentOptions={{spacing: 1, timeFormat: false}}/>
            </Grid>
            {archive || search ?
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField<FormDateRangeOptions> title={t("Archive.Archived")} name={props.isArchiveOfOwnOffers || props.admin ? "deletedOnRange" : "datArchRange"} type={"Custom"} customComponent={FormDateRange} customComponentOptions={{spacing: 1, timeFormat: false}}/>
                </Grid> : null
            }
        </Grid>
    )
}

export function InzeratHistoryViewTab({inzerat, archive, admin, zadani} : {inzerat: InzeratViewDetail, archive?: boolean, admin?: boolean, zadani?: boolean}) {
    return <InzerceCiselnikView admin={admin} history={inzerat} isTabbed archive={archive} zadani={zadani}/>;
}

export const InzerceCiselnikViewNew = (props: InzerceViewCiselnikProps) => {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const rangeCenaSharedOptions = {numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dateKey = "inzerat-view-browse-date";
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true});
    const [showConfirm] = useConfirmDialog();
    const dtGrid = useRef<DataGridExposed<InzeratViewAdmin, InzeratFilterOznaceno>>();
    const {pdfLayout} = useInzercePdfLayout(user);
    const allowStomp = !props.admin;
    const accessUrl = props.admin ? 'admin' : 'user';
    const endpoint = `${accessUrl}/inzeratview`;
    const clearInzeratViewRecords =
        useClearInzeratViewRecords(() => {dtGrid.current?.table()?.refresh()})
    const nabidkaOznaceni = useZaznamOznaceniStyle(endpoint);

    return <>
        <Dial<InzeratViewAdmin, InzeratFilterOznaceno, InzeratViewDetail>
            mode={props.mode}
            lastBrowsedDateKey={dateKey}
            logActivity
            gridRef={dtGrid}
            filterClazz={InzeratFilterOznaceno}
            clazzDetail={InzeratViewDetail}
            config={{
                lockSupport: {enabled: false},
                overflowHidden: true,
                watchChanges: !props.admin,
                alternativeButtons: props.admin ? undefined : [clearInzeratViewRecords],
                requiredColumns: ['popis'],
                exportConfig: {
                    exportable: true,
                    exportDetailOnly: !props.admin,
                    endpoint: endpoint,
                    exportAll: props.admin,
                    fileName: "export_inzerce",
                    translationPrefix: ['Inzerce', 'User', 'Preprava', 'Archive'],
                    formats: ["pdf", "xls", "csv", "xml"],
                    exportAllFormats: ["csv"],
                    pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                    exportableProps: getExportableProps(props.admin ? inzerceAdminExportableFields : [
                        {
                            type: ["csv", "xls", "pdf"],
                            fields: ["popis", "rychlyKontakt", "cena", "currency", "provozovna", "dispecer", "uzivatel", "modifiedOn"]
                        }
                    ], false),
                    excludedProps: [
                        {
                            type: ["csv", "xls"],
                            fields: ["id", "sekce"]
                        }
                    ],
                    extendedProps: [
                        {
                            type: ["csv", "xls"],
                            addExtendedProps: (data) => {
                                return {
                                    provozovna: data.uzivatel?.provozovna?.kod
                                }
                            }
                        }
                    ],
                    formattedProps: [
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "invalDuv",
                            format: (data) => {
                                return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data)
                            }
                        },
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "rychlyKontakt",
                            format: (data) => {
                                return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                            }
                        }
                    ]
                },
                options: props.admin ? {
                    rowStyle: (data: any) => {
                        if (!data.valid)
                            return globalStyles.rowStyleAlert;
                        if(data.invalDuv === InvalidDuvodInzerat.DUPLICITA && props.admin){
                            return globalStyles.rowStyleDuplicated;
                        }
                        return undefined;
                    }
                } : {
                    rowStyle: (data: any) => {
                        if (data.klasifikace?.barva) {
                            return {backgroundColor: data.klasifikace?.barva}
                        }
                        const oznaceniStyle = nabidkaOznaceni.getStyle(data);
                        if(oznaceniStyle) return oznaceniStyle;
                    }
                },
                stomp: allowStomp && {
                    topic: props.pvi ? `/provozovna/${user.provozovna.kod}/crud-inzerat` : `/crud-inzerat`,
                    toggleable: !props.admin,
                    allowStompUiUpdates: true
                },
                clazz: InzeratViewAdmin,
                hideDefaultDummyAction: false,
                tableTitle: t("Dials.AdvertisingProhlizet").toUpperCase(),
                endpoint: endpoint,
                filtering: true,
                hideAddNewActions: true,
                defaultQueryParameters: props.filter,
                actions: props.admin ? [(data:InzeratViewAdmin) => ({
                    hidden: data?.invalDuv===InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM,
                    icon: () => <Cancel/>,
                    onClick: async () => {
                        showConfirm({
                            body: t("Inzerce.InvalidInzeratConfirm"),
                            onConfirm: async () => {
                                try {
                                    dtGrid.current.table().setLoading(true);
                                    await httpEndpointCustom(`admin/inzerat/${data.id}/invalidate`, {method: "POST"});
                                    dtGrid.current.table().refresh();
                                } finally {
                                    dtGrid.current.table().setLoading(false);
                                }
                            }
                        });
                    }
                }),
                    (data:InzeratViewAdmin) => ({
                        hidden: data?.invalDuv!==InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM,
                        icon: () => <Check/>,
                        onClick: () => {
                            showConfirm({
                                body: t("Inzerce.ValidInzeratConfirm"),
                                onConfirm: async () => {
                                    try {
                                        dtGrid.current.table().setLoading(true);
                                        await httpEndpointCustom(`admin/inzerat/${data.id}/validate`, {method: "POST"});
                                        dtGrid.current.table().refresh();
                                    } finally {
                                        dtGrid.current.table().setLoading(false);
                                    }
                                }
                            });
                        }
                    })
                ] : [],
                columns: [
                    {
                        title: t("Inzerce.AdText").toUpperCase(),
                        field: 'popis',
                        filterProps:() => ({type:FormInputType.Text}),
                        cellStyle: {minWidth: 200},
                        headerStyle: {minWidth: 200}
                    },
                    {
                        title: t("Default.Telefon").toUpperCase(),
                        field: 'rychlyKontakt',
                        render:(row:InzeratView)=><InlinePhoneNumberDial hideIcon phoneNumber={row.rychlyKontakt} rowData={row}/>,
                        filterProps: () => ({type: FormInputType.Text})
                    },
                    {
                        title:t('Default.Email').toUpperCase(),
                        field:"email",
                        filterProps: () => ({type: FormInputType.Text}),
                        sorting: false,
                        render:(data:InzeratView)=> <InlineEmailClient hideIcon emailAddress={data.email} rowData={data}/>,
                    },
                    {
                        title: t("Inzerce.Price").toUpperCase(),
                        field: 'cena',
                        render:(data:InzeratView) => {
							const price = data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? '';
							return data.jednotka ? price + "/" + data.jednotka : price;
						},
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions:rangeCenaSharedOptions}),
                        cellStyle: {textAlign:"right"}
                    },
                    {
                        title: t("Provozovna.Title").toUpperCase(),
                        field: 'provozovna',
                        render:(data:InzeratView) => data.provozovna && data.firma ? truncateString(`${data.provozovna} (${data.firma})`, 20) : '',
                        filterProps: () => ({type: FormInputType.Text})
                    },
                    {
                        title: t("Inzerce.Dispecer").toUpperCase(),
                        field: 'dispecer',
                        render:(row:InzeratView)=> `${row.dispecer?.jmeno ?? ''}`,
                        filterProps:() => ({type: FormInputType.Text, name: "dispecerText"}),
                        cellStyle: {width: 200, maxWidth: 200},
                        headerStyle: {width: 200, maxWidth: 200}
                    },
                    (props.admin) ?
                        {
                            title: t("PVI.Inserted").toUpperCase(),
                            field: 'datIns',
                            render: (data:InzeratView) => data.datIns?.format("L LT")
                        } : undefined,
                    {
                        title: t("PVI.Modified").toUpperCase(),
                        field: 'modifiedOn',
                        defaultSort: "desc",
                        render: (data: any) => data.modifiedOn?.format("L LT"),
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false},  name: "modifiedOnRange"}),
                    },
                    ...(props.admin ? [
                        {
                            title: t("PVI.Valid").toUpperCase(),
                            field: 'valid',
                            render: (data: InzeratViewAdmin) => <CheckMark checked={data.valid}/>,
                            filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
                        },
                        {
                            title: t("PVI.InvalDuv").toUpperCase(),
                            field: 'invalDuv',
                            render: (data: InzeratViewAdmin) =>  {return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data.invalDuv)},
                            filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                            cellStyle: {minWidth: 180},
                            headerStyle: {minWidth: 180}
                        },
                        {
                            title: t("User.Title").toUpperCase(),
                            field: 'uzivatelText',
                            render:(data:InzeratViewAdmin)=> data.uzivatel?.toString() ?? "",
                            filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                            cellStyle: {minWidth: 180},
                            headerStyle: {minWidth: 180}
                        }
                    ] : []),
                ].filter(i => Boolean(i)) as Column<InzeratView>[]
            }}
            layoutDetail={()=><TabHelperNew<InzeratViewDetail> render={(data) =>
                <>
                    <InzeratViewDetailFormBasicPartNew data={data} admin={props.admin}/>
                    {props.admin ? <InzerceViewAdminPart inzerat={data} /> : null}
                </>}/>}
            crudConfig={{addEnabled:false, editEnabled:false, removeEnabled: props.viewArchive}}
            layoutFilter={() =><InzerceViewCiselnikFilter {...{lastBrowseDateDataKey: dateKey, admin: props.admin}}/>}
            hideSaveButton
            hideNewButtonOnEdit
            tabs={[
                ...(props.admin ? [
                    {
                        title: t("Inzerce.History"),
                        render: ()=><TabHelperNew<InzeratViewDetail> render={(data) => <InzerceHistoryViewPart inzerat={data} admin={props.admin} mode={ComponentMode.GridMode}/>}/>
                    }
                ].filter(i=>i) : [])
            ]}

        />
    </>
}
