import {useRef} from "react";

export interface AppCache {
    setCache: (key: string, value: any) => void;
    getCache: (key: string) => any;
    resetCache: () => void;
}

export const useAppCache = (): AppCache => {
    const cacheData = useRef<{ [key: string]: any }>({});

    const setCache = (key: string, value: any): void => {
        cacheData.current = ({ ...cacheData.current, [key]: value });
    };

    const getCache = (key: string): any => {
        return cacheData.current[key];
    };

    const resetCache = (): void => {
        cacheData.current = {};
    }

    return { setCache, getCache, resetCache };
};