import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@material-ui/core';
import { FormField, useForm } from '../../../../raal_components/form/Form';
import { useTranslation } from 'react-i18next';
import { decimalNumberFormat, shortNumberConstraint } from '../../../../../common/common-constraints';
import { useStyleContext } from '../../../../context/ThemeModeContext';
import {
	AvoidFeatureEnum,
	EuropeanCountryCodeAlpha3,
	HazardousGoodsEnum,
	HereProfilVozidla,
	newVehicle,
	useCO2EmissionClassOptions,
	useEmissionTypeOptions,
	useEuropeanCurrencyCodeOptions,
	useTruckTypeOptions,
	useTunnelCategoryOptions,
} from '../../../../model/HereProfilVozidla';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 * Formulář pro editaci profilu vozidla volaný z dialogu HereProfilVozidlaDial.
 * @constructor
 */
export function HereProfilVozidlaForm() {
    const {t} = useTranslation();
    const form = useForm<HereProfilVozidla>();
	const europeanCurrencyCodeOptions = useEuropeanCurrencyCodeOptions();
	const tunnelCategoryOptions = useTunnelCategoryOptions();
	const truckTypeOptions = useTruckTypeOptions();
	const emissionTypeOptions = useEmissionTypeOptions();
	const co2EmissionClassOptions = useCO2EmissionClassOptions();
	const {classes} = useStyleContext();
	const [hazardousGoodsAccordion, setHazardousGoodsAccordion] = useState(false);
	const [excludeCountryAccordion, setExcludeCountryAccordion] = useState(false);
	const [avoidFeaturesArray, setAvoidFeaturesArray]  = useState<AvoidFeatureEnum[] | undefined>(undefined);
	const [excludeCountriesArray, setExcludeCountriesArray]  = useState<EuropeanCountryCodeAlpha3[] | undefined>(undefined);
	const [hazardousGoodsArray, setHazardousGoodsArray]  = useState<HazardousGoodsEnum[] | undefined>(undefined);

	// Níže jsou tři bloky řešící drobný workaround pro checkboxy a práci s výčtem uloženým jako string oddělený čárkou (asi by šlo řešit i jinak "lépe").
	useEffect(() => {
		if (form.data.avoidFeatures && !avoidFeaturesArray) {
			const featuresArray = form.data.avoidFeatures.split(',');
			const avoidFeaturesArrayToSet: AvoidFeatureEnum[] = featuresArray.map(item => AvoidFeatureEnum[item as keyof typeof AvoidFeatureEnum]);
			setAvoidFeaturesArray(avoidFeaturesArrayToSet);
			avoidFeaturesArrayToSet && avoidFeaturesArrayToSet.map((item) => {
				form.onRefreshFieldValue(item);
			});
		}
	}, [form.data.avoidFeatures]);

	useEffect(() => {
		if (form.data.excludeCountries && !excludeCountriesArray) {
			const featuresArray = form.data.excludeCountries.split(',');
			const excludeCountriesArrayToSet: EuropeanCountryCodeAlpha3[] = featuresArray.map(item => EuropeanCountryCodeAlpha3[item as keyof typeof EuropeanCountryCodeAlpha3]);
			setExcludeCountriesArray(excludeCountriesArrayToSet);
			excludeCountriesArrayToSet && excludeCountriesArrayToSet.map((item) => {
				form.onRefreshFieldValue(item);
			});
		}
	}, [form.data.excludeCountries]);

	useEffect(() => {
		if (form.data.vehicle?.hazardousGoods && !hazardousGoodsArray) {
			const itemArray = form.data.vehicle?.hazardousGoods.split(',');
			const hazardousGoodsArrayToSet: HazardousGoodsEnum[] = itemArray.map(item => HazardousGoodsEnum[item as keyof typeof HazardousGoodsEnum]);
			setHazardousGoodsArray(hazardousGoodsArrayToSet);
			hazardousGoodsArrayToSet && hazardousGoodsArrayToSet.map((item) => {
				form.onRefreshFieldValue(item);
			});
		}
	}, [form.data.vehicle?.hazardousGoods]);

	// Převede centimetry na metry.
	const cmToM = (data: HereProfilVozidla, fieldName: string) => {
		const f = fieldName.split('.')[1];
		// @ts-ignore
		return data.vehicle[f] ? data.vehicle[f] / 100 : data.vehicle[f];
	}

	// Převede metry na centimetry
	const mToCm = (data: HereProfilVozidla, fieldName: string, value: any) => {
		const f = fieldName.split('.')[1];
		if (!value) {
			// @ts-ignore
			data.vehicle[f] = null;
		} else {
			// @ts-ignore
			data.vehicle[f] = Math.round(value * 100);
		}
	}

	// Převede kg na tuny.
	const kgToT= (data: HereProfilVozidla, fieldName: string) => {
		const f = fieldName.split('.')[1];
		// @ts-ignore
		return data.vehicle[f] ? data.vehicle[f] / 1000 : data.vehicle[f];
	}

	// Převede t na kg
	const tToKg = (data: HereProfilVozidla, fieldName: string, value: any) => {
		const f = fieldName.split('.')[1];
		if (!value) {
			// @ts-ignore
			data.vehicle[f] = null;
		} else {
			// @ts-ignore
			data.vehicle[f] = Math.round(value * 1000);
		}
	}

    return <Grid container spacing={2} direction="column">
		{/*Název profilu, měna, náklady*/}
        <Grid container item spacing={1}>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField name={"nazev"} title={t("Here.Nazev")} type={"text"} required textFieldProps={{inputProps: {maxLength: 40}}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField dataTip={t("Here.Currency.TitleToolTip")} title={t("Here.Currency.Title")} name='currency' type='select' autoSelectFirstValueOnTab required selectProps={europeanCurrencyCodeOptions}/>
            </Grid>
			<Grid item lg={2} sm={6} xs={12}>
				<FormField title={t("Here.CustomNaklady.NaHodinu")} name='casovyNaklad' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
			</Grid>
			<Grid item lg={2} sm={6} xs={12}>
				<FormField title={t("Here.CustomNaklady.NakladNaKm")} name='nakladNaKm' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
			</Grid>
        </Grid>
		<Grid item xs={12} sm={12} lg={12}>
			<Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
			<Grid item xs={12} lg={12}>
				<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.Vehicle.Section")}</Typography>
			</Grid>
		</Grid>
		{/*Základní informace o vozidle*/}
		<Grid container item spacing={1}>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField dataTip={t("Here.Vehicle.Height")} title={t("Here.Vehicle.Height")} name='vehicle.height' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} getValue={cmToM} setValue={mToCm}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField title={t("Here.Vehicle.Width")} name='vehicle.width' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} getValue={cmToM} setValue={mToCm} />
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField title={t("Here.Vehicle.Length")} name='vehicle.length' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} getValue={cmToM} setValue={mToCm} />
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField title={t("Here.Vehicle.GrossWeight")} name='vehicle.grossWeight' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} getValue={kgToT} setValue={tToKg} />
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField title={t("Here.Vehicle.AxleCount")} name='vehicle.axleCount' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField title={t("Here.Vehicle.PayloadCapacity")} name='vehicle.payloadCapacity' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} getValue={kgToT} setValue={tToKg}/>
			</Grid>
			{/*Položky níže jsou dočasně disablovány. Fungují, ale jenom v určitých kombinacích. Po lepším rozklíčování jak je používat se můžou enablovat. Prozatím domluveno disablování.*/}
			{/*Ale nějaké položky fungují bez problému jako je kategorie tunelu a typ nákladního auta. Ale na žádost raalu disablováno.*/}
			<Grid item lg={4} sm={6} xs={12}>
				<FormField disabled title={t("Here.Vehicle.WeightPerAxle")} name='vehicle.weightPerAxle' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} getValue={kgToT} setValue={tToKg}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField disabled title={t("Here.Vehicle.WeightPerAxleGroup")} name='vehicle.weightPerAxleGroup' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} getValue={kgToT} setValue={tToKg}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField disabled title={t("Here.Vehicle.TrailerCount")} name='vehicle.trailerCount' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField disabled dataTip={t("Here.Vehicle.TrailerAxleCountToolTip")} title={t("Here.Vehicle.TrailerAxleCount")} name='vehicle.trailerAxleCount' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField disabled dataTip={t("Here.Vehicle.TunnelCategoryToolTip")} title={t("Here.Vehicle.TunnelCategory")} name='vehicle.tunnelCategory' type='select' autoSelectFirstValueOnTab selectProps={tunnelCategoryOptions}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField disabled title={t("Here.Vehicle.Type")} name='vehicle.type' type='select' autoSelectFirstValueOnTab selectProps={truckTypeOptions}/>
			</Grid>
		</Grid>
		{/*Parametry pro výpočet mýta podle emisí*/}
		<Grid item xs={12} sm={12} lg={12}>
			<Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
			<Grid item xs={12} lg={12}>
				<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.TollEmission.Section")}</Typography>
			</Grid>
		</Grid>
		<Grid container item spacing={1}>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField title={t("Here.TollEmission.TollEmissionType")} name='tollEmissionType' type='select' autoSelectFirstValueOnTab selectProps={emissionTypeOptions}/>
			</Grid>
			<Grid item lg={4} sm={6} xs={12}>
				<FormField title={t("Here.TollEmission.TollEmissionTypeCO2EmissionClass")} name='tollEmissionTypeCO2EmissionClass' type='select' autoSelectFirstValueOnTab selectProps={co2EmissionClassOptions}/>
			</Grid>
		</Grid>
		<Grid item xs={12} sm={12} lg={12}>
			<Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
			<Grid item xs={12} lg={12}>
				<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.AvoidFeatures.Section")}</Typography>
			</Grid>
		</Grid>
		{/*Číslelník položek pro vyhnutí se specifickým cestám jako je tunel a trajekt.*/}
		<Grid container item spacing={1}>
			{
				Object.values(AvoidFeatureEnum).map((it: AvoidFeatureEnum) => (
					<Grid item xs={12} sm={6} lg={3}>
						<FormField title={t(`Here.AvoidFeatures.Feature.${it}`)} name={it} type="checkbox"
								   getValue={(data: HereProfilVozidla) => !!data.avoidFeatures?.split(",").includes(it)}
								   setValue={(data: HereProfilVozidla, fieldName, value) => {
									   const temp = value
										   ? [...new Set([...(avoidFeaturesArray || []), it])] // Add if not present
										   : avoidFeaturesArray?.filter(item => item !== it) || []; // Remove if present
									   setAvoidFeaturesArray(temp);
									   data.avoidFeatures = temp?.toString();
								   }}
						/>
					</Grid>
				))
			}
		</Grid>
		{/*Číselník nebezpečného zboží přepravovaného ve vozidle.*/}
		<Grid item xs={12} sm={12} lg={12}>
			<Accordion expanded={hazardousGoodsAccordion} onChange={() => setHazardousGoodsAccordion(!hazardousGoodsAccordion)}>
				<AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="p1-content" id="p1-header">
					<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.HazardousGoods.Section")}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container item spacing={1}>
						{
							Object.values(HazardousGoodsEnum).map((it) => (
								<Grid item xs={12} sm={6} lg={3}>
									<FormField title={t(`Here.HazardousGoods.HazardousGood.${it}`)} name={it} type="checkbox"
											   getValue={(data: HereProfilVozidla) => !!data.vehicle?.hazardousGoods?.split(",").includes(it)}
											   setValue={(data: HereProfilVozidla, fieldName, value) => {
												   data.vehicle = data.vehicle || newVehicle();
												   const temp = value
													   ? [...new Set([...(hazardousGoodsArray || []), it])] // Add if not present
													   : hazardousGoodsArray?.filter(item => item !== it) || []; // Remove if present
												   setHazardousGoodsArray(temp);
												   data.vehicle.hazardousGoods = temp?.toString();
											   }}
									/>
								</Grid>
							))
						}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Grid>
		{/*Číselník vyhnutí se specifikovaným zemím.*/}
		<Grid item xs={12} sm={12} lg={12}>
			<Accordion expanded={excludeCountryAccordion} onChange={() => setExcludeCountryAccordion(!excludeCountryAccordion)}>
				<AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="p1-content" id="p1-header">
					<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.ExcludeCountry.Section")}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container item spacing={1}>
						{
							Object.values(EuropeanCountryCodeAlpha3).map((it) => (
								<Grid item xs={3} sm={1} lg={1}>
									<FormField title={t(`Here.ExcludeCountry.Country.${it}`)} name={it} type="checkbox"
											   getValue={(data: HereProfilVozidla) => !!data.excludeCountries?.split(",").includes(it)}
											   setValue={(data: HereProfilVozidla, fieldName, value) => {
												   const temp = value
													   ? [...new Set([...(excludeCountriesArray || []), it])] // Add if not present
													   : excludeCountriesArray?.filter(item => item !== it) || []; // Remove if present
												   setExcludeCountriesArray(temp);
												   data.excludeCountries = temp?.toString();
											   }}
									/>
								</Grid>
							))
						}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Grid>
		{/*Nastavení profilu jako defaultního v přepravách a volných vozech.*/}
		<Grid item xs={12} sm={12} lg={12}>
			<Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
			<Grid item xs={12} lg={12}>
				<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.DefaultPVSettings.Section")}</Typography>
			</Grid>
		</Grid>
        <Grid container item spacing={1}>
            <Grid item xs={12} sm={4} lg={2}>
                <FormField title={t("Here.DefaultPVSettings.Preprava")} name="defaultInPreprava" type={"checkbox"} />
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <FormField title={t("Here.DefaultPVSettings.Vozidlo")} name="defaultInVozidlo" type={"checkbox"} />
            </Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<FormField title={t("Here.DefaultPVSettings.Kilometrovnik")} name="defaultInKilometrovnik" type={"checkbox"} />
			</Grid>
        </Grid>
    </Grid>
}
