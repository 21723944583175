import * as DG from "../DataGrid.d";
import {FilterProps, MapperProps, QueryState} from "../DataGrid.d";
import React, {MutableRefObject, Ref, useContext, useEffect, useImperativeHandle, useRef} from "react";
import {LoadingExposed} from "../../../../common/component/Loading";
import {AutoRefreshStateHandler} from "../../../AutoRefresh";
import {invoke} from "../../../../common/utils/Invoke";
import {DGContext, DGExposed} from "../DataGrid";
import {FilterTableExposed, TableFilterComponent} from "./TableFilter";
import {OptimizedTable} from "./OptimizedTable";
import {GenericMap} from "../../../../index.d";
import {CrudUpdateEvent} from "../../../model/CrudUpdate";
import {ScrollableList} from "../../../../common/utils/Util";
import {QueryResult} from "material-table";
import {FormVicinityContainerProvider} from "../../../context/FormVicinityContainerContext";

export type TableExposed<T extends object, Filter> = {
    refresh(extraHeaders?: GenericMap, data?: CrudUpdateEvent, force?:boolean): Promise<any>,
    onFilterChanged?(filter:Filter):void,
    getFilter():QueryState<Filter>,
    count():Promise<number>,
    fetch():Promise<ScrollableList<T>>,
    getData():QueryResult<T>,
    setLoading(status:boolean):void,
    isLoading():boolean,
    modifiedCount(timestamp?: string):Promise<number>,
    onDetailOpen():void,
    onDetailClosed():void,
    onRefreshTable(addRowOnly?: boolean): void
    setLastBrowsedDateKey():void
    focusEditRow(): void
	focusNextEditRow(): void
    onResetLastTabIndex(): void
    onConfirmChangedData(data: T[] | T): void
    onRefreshFilterHeaderIndexes(): void
    setFiltersOpened(opened:boolean): void
    isFiltersOpened():boolean
}

/**
 * Renderovaci komponenta
 * @params prop
 * @constructor
 */
export const TableComponent = <T extends object, Filter>(props: DG.Props<T> & FilterProps<Filter> & { tbRef: MutableRefObject<TableExposed<T, Filter>>, dataGridRef?:Ref<DGExposed>, createFilterDataObject():any, renderAdditionalHeaderItems?:(open:boolean)=>React.ReactNode}&MapperProps<Filter>) => {
    const loadingRef = useRef<LoadingExposed>();
    const filterRef = useRef<FilterTableExposed>();
    const arState = useRef<AutoRefreshStateHandler>()
    const {getState, setState} = useContext(DGContext);

    useImperativeHandle(props.dataGridRef, () => ({
        disableAutorefresh,
        onDetailOpen,
        onDetailClosed,
        onConfirmChangedData,
        onRefreshFilterHeaderIndexes
    }))

    const disableAutorefresh = (temporarily?: boolean) => {
        arState.current.disableAutorefresh(temporarily);
    }

    const onDetailOpen = () => {
        props.tbRef.current.onDetailOpen();
    }

    const onDetailClosed = () => {
        props.tbRef.current.onDetailClosed();
    }

    const onConfirmChangedData = (data: T) => {
        props.tbRef.current.onConfirmChangedData(data)
    }

    const onRefreshFilterHeaderIndexes = () => {
        props.tbRef.current.onRefreshFilterHeaderIndexes();
    }

    useEffect(() => {
        if (props.onFilterChanged) {
            invoke(props.onFilterChanged, getState().current.filters ?? {});
            filterRef.current?.forceUpdate();
        }
        // eslint-disable-next-line
    }, [])

    const onTableFilterChanged = (data: Filter) => {
        props.onEditingEnd();
        if(data && !props.tbRef?.current?.isLoading()){
            setState({...getState(), current:{...getState().current, filters: data}});
        }
    }

    return <>
        <FormVicinityContainerProvider>
            <TableFilterComponent {...{...props, arState, loadingRef}} ref={filterRef}/>
            <OptimizedTable {...{...props, arState, onEditingEnd: onTableFilterChanged}} loadingRef={loadingRef}
                            onFilterChanged={(filters) => {
                                invoke(props.onFilterChanged, filters);
                                filterRef.current?.forceUpdate();
                            }}/>
        </FormVicinityContainerProvider>
    </>;
}
