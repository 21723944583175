import {DokladkaFilter, VPFilter, VPList} from "../../../../model/PrepravaVozidlo";
import {useTranslation} from "react-i18next";
import {
    createProvozovnaEmailyContactSelect,
    useCiselnikSelect,
    useCurrencySelectObj,
    useDateIsoSelect,
    useDateSelect, useDispecerForInputFormsSelect,
    useEnumCiselnikSelect, useKlasifikaceSelect, usePriceUnitsSelectObj,
    useRychlyContactSelect,
    useUserBasicSelect,
    useYesNoSelect,
} from '../../../../raal_components/SelectOptions';
import {FormInputType} from "../../../../raal_components/form/Form";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {FormNumberRange} from "../../../../../common/component/form/FormNumberRange";
import numeral from "numeral";
import {SystemParameter, SystemParamKey} from "../../../../model/SystemParameter";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {useCiselnikValues, useData, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import React, {MutableRefObject, useEffect, useRef} from "react";
import {
    cenaConstraint, paletyConstraint,
    sizeFormat,
    sizeIntegerFormat,
    sizeViewFormat,
    useDateLogic,
    useNSJLogic,
    usePlaceAutocomplete,
    useVPLogic,
    vahaConstraint
} from "./PrepravaAVozidlaShared";
import {
    createUpdatePrejezdWaypointOrNakladkaOrVykladka,
    exist,
    formatDate,
    formatPrice,
    truncateString, useDataStore,
} from "../../../../../common/utils/Util";
import {useAppContext} from "../../../../context/AppContext";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {useStyleContext} from '../../../../context/ThemeModeContext';
import {Moment} from "moment";
import { InvalDuvodPreprava, InvalDuvodVozidlo, priceUnits } from '../../../../model/CommonTypes';
import {FormDateRangeSelect, FormTwoDatesSelect} from "../../../../raal_components/FormTwoDatesSelect";
import {cenaFormat} from "../zadani/InzerceCiselnik";
import {GenericMap} from "../../../../../index.d";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {InlinePhoneNumberDial} from "../../../../../common/component/PhoneNumberDial";
import {NabidkaType} from "../../../../model/NabidkaType";
import {
    FormVicinityContainer,
    FormVicinityContainerNew, Vicinity
} from "../../../../../common/component/form/FormVicinityContainer";
import {njsSwitch} from "./VPFilter";
import {useSubscribe} from "use-pubsub-js";
import {showSnack} from "../../../../../common/component/SnackContainer";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@material-ui/core";
import {InlineEmailClient} from "../../../../../common/component/EmailClient";
import {Waypoint} from "../../../../model/Waypoint";

export const isTimeOutOfDetaults = (dateOd:Moment, dateDo:Moment) => {
    const h1 = dateOd.get("hours");
    const m1 = dateOd.get("minutes");
    const h2 = dateDo.get("hours");
    const m2 = dateDo.get("minutes");
    return h1 !== 0 || m1 !== 0 || h2 !== 23 || m2 !== 59;
};

export const isTimeDefault = (date:Moment) => {
    const h = date.get("hours");
    const m = date.get("minutes");
    return (h === 0 && m === 0) || (h === 23 && m === 59);
};

const getTitleWithAdditionalInfo = (title:String, tooltip:String) => {
    return <Tooltip title={tooltip}>
        <div style={{display: "flex", alignItems: "center"}}>
            {title}
            <FontAwesomeIcon style={{marginLeft: "5px", fontSize: "0.85em"}} icon={faIcon.faInfoCircle} size="sm"/>
        </div>
    </Tooltip>
};

export const DateCell = <T extends VPList>({data}:{data:T}) => {
    const {classes} = useStyleContext();
    const isSameDate = data.datOd.format("LL") === data.datDo.format("LL");
    const isDefaultTime = isTimeDefault(data.datOd) && isTimeDefault(data.datDo);
    const datOd = formatDate(data.datOd), datDo = formatDate(data.datDo);
    const casOd = data.datOd.format("HH:mm"), casDo = data.datDo.format("HH:mm");

    if(isSameDate && isDefaultTime){
        return (<>{datOd}</>);
    } else if(!isDefaultTime) {
        return isSameDate ?
            (<>{datOd} <span className={classes.coloredText}>({casOd} - {casDo})</span></>) :
            (<>
                {datOd} <span className={classes.coloredText}>{!isTimeDefault(data.datOd) && <>({casOd})</>}</span>
                {" - "}
                {datDo} <span className={classes.coloredText}>{!isTimeDefault(data.datDo) && <>({casDo})</>}</span>
            </>);
    } else /* !isSameDate && isDefaultTime */ {
        return (<>{datOd} - {datDo}</>);
    }
};

function isDokladkaFilter(data: VPFilter): data is DokladkaFilter {
    return (data as DokladkaFilter).nakladka !== undefined;
}

function handleDokladkyLocationChange(data: any, v: any, fieldKey: 'nakladka' | 'vykladka', vicinityKey: 'okoliOdkud' | 'okoliKam', currentWaypointRef: React.MutableRefObject<any>, currentVicinityRef: React.MutableRefObject<any>, currentRadiusRef: React.MutableRefObject<any>, setData: (data: any) => void, storeFilter: (data: any) => void, isDokladkaFilter: (data: any) => boolean, isUserClearOkoli: boolean) {
    // Inicializace waypointu, pokud ještě není nastavena
    if (!currentWaypointRef?.current) {
        currentWaypointRef.current = data[fieldKey];
    }

    // Aktualizace waypointu na základě změny
    if (v?.osmPlace && v?.osmPlace !== currentWaypointRef?.current?.nazevMista) {
        currentWaypointRef.current = Waypoint.fromOkoli(v);
    }

    // Aktualizace vicinity a manipulace s filtrem
    if ((!currentVicinityRef.current && v) || (v && ((currentVicinityRef.current?.osmPlace !== v.osmPlace && v.osmPlace) || (currentVicinityRef.current?.koordinat !== v.koordinat && v.koordinat) || (currentVicinityRef.current?.countryCode !== v.countryCode && v.countryCode)))) {
        currentVicinityRef.current = v;
        data[`${fieldKey}Radius`] = null;
        if (isDokladkaFilter(data)) {
            storeFilter(data);
        }
    } else if (currentVicinityRef.current && v) {
        if (isUserClearOkoli && currentVicinityRef.current?.radius) {
            currentVicinityRef.current.radius = null;
        } else if (v.radius && !currentVicinityRef.current?.radius) {
            currentVicinityRef.current.radius = v.radius;
        } else if ((currentVicinityRef.current?.radius !== v.radius) && v.radius) {
            currentVicinityRef.current.radius = v.radius;
            data[vicinityKey].radius = currentVicinityRef.current.radius;
            setData(data);
        } else if ((currentVicinityRef.current?.radius && !v.radius) && (currentVicinityRef.current?.radius !== v.radius) && v.osmPlace && v.koordinat && v.countryCode) {
            data[vicinityKey].radius = null;
            setData(data);
        } else if ((currentVicinityRef.current?.radius && !v.radius) && (currentVicinityRef.current?.radius !== v.radius) && (v.mpz || v.psc || v.nazevMista)) {
            v.radius = currentVicinityRef.current.radius;
        }
    }

    // Aktualizace waypointu
    const updateWaypoint = createUpdatePrejezdWaypointOrNakladkaOrVykladka(data[fieldKey], v);

    if (updateWaypoint) {
        data[fieldKey] = updateWaypoint;
        setData(data);
    }

    // Aktualizace radiusu
    if (data[`${fieldKey}Radius`] !== v?.radius) {
        data[`${fieldKey}Radius`] = v?.radius;
        setData(data);
        if (isDokladkaFilter(data)) {
            storeFilter(data);
        }
    }

    // Kontrola a reset radiusu
    if (!currentRadiusRef.current) {
        data[`${fieldKey}Radius`] = null;
        setData(data);
        if (isDokladkaFilter(data)) {
            storeFilter(data);
        }
    }
}

/**
 * @param typCiselniku
 * @param oznacenoFilter
 * @param refreshFun
 * @param oznacenoUrl
 * @param showCols
 * @param typParametru
 * @param defaultRange
 * @param typAdvance
 * @param defaultAdvance
 * @param admin
 * @param extras - pokud obsahují refactor:true, tak se použije FormVicinityContainerNew. Po úpravě všeho na New bude možné smazat celou logiku rozlišování
 */
export function useViewColumns<T extends VPList>(typCiselniku: CiselnikTyp, oznacenoFilter: boolean, refreshFun: React.MutableRefObject<() => void>, oznacenoUrl: string, showCols: string[] = [], typParametru: SystemParamKey, defaultRange: number, typAdvance: SystemParamKey, defaultAdvance: number, admin: boolean, extras: GenericMap = {}, autoOpenDisabled?: boolean, okoliDisabled?: boolean, currentVicinityNakladkyRef?: React.MutableRefObject<Vicinity | null>, currentVicinityVykladkyRef?: React.MutableRefObject<Vicinity | null>, isDokladky?: boolean, currentNakladkaRef?: React.MutableRefObject<Waypoint | null>, currentVykladkaRef?: React.MutableRefObject<Waypoint | null>, currentNakladkaRadiusRef?: React.MutableRefObject<number | null>, currentVykladkaRadiusRef?: React.MutableRefObject<number | null>, isEmptyOkoliOdkudLastSearchRef?: React.MutableRefObject<boolean | null>, isEmptyOkoliKamLastSearchRef?: React.MutableRefObject<boolean | null>, isUserSetEmptyOkoliOdkudRef?: React.MutableRefObject<boolean | null>, isUserSetEmptyOkoliKamRef?: React.MutableRefObject<boolean | null>, currentPrejezdOdWaypointRef?: React.MutableRefObject<Waypoint | null>, currentPrejezdKamWaypointRef?: React.MutableRefObject<Waypoint | null>, prejezdOdWaypointLastSearchRef?: React.MutableRefObject<Waypoint | null>, prejezdKamWaypointLastSearchRef?: React.MutableRefObject<Waypoint | null>, isPrejezdy?: boolean) {
    const {t} = useTranslation();
    const yesNoSelect = useYesNoSelect({isClearable:true, autoOpenDisabled: autoOpenDisabled});
    const {druhyJoined} = useCiselnikValues(typCiselniku);
    const dateFromSelect = useDateIsoSelect({dateType: 'datOd', typAdvance: typAdvance, defaultAdvanceValue: defaultAdvance})
    const {user} = useAppContext();
    const ciselnikSelectFilter = useCiselnikSelect(typCiselniku, {isClearable:true, isMulti: extras.disableMultichoice!==true, autoOpenDisabled: autoOpenDisabled});
    const rangeSharedOptions = {flexDirection:"column", verticalSpace: 0};
    const rangeNumberSharedOptions = {flexDirection:"column", verticalSpace: 0, numberProps: {format:sizeFormat, constraint:vahaConstraint}};
    const vicinityOptions = {flexDirection:"column", verticalSpace: 0};
    const rangeCenaSharedOptions = {flexDirection:"column", verticalSpace: 0, numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const {pathname} = useLocation();
    const userArchiveZadani = !extras.viewArchive && extras.archive && !admin
    const currencySelectProps = useCurrencySelectObj({isClearable:true, autoOpenDisabled: autoOpenDisabled});
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: extras.typ===NabidkaType.PREPRAVA ? CiselnikTyp.P : CiselnikTyp.V, enm: extras.typ===NabidkaType.PREPRAVA ? InvalDuvodPreprava : InvalDuvodVozidlo, enmName: extras.typ===NabidkaType.PREPRAVA ? "Enumerations.InvalDuvodPreprava" : "Enumerations.InvalDuvodVozidlo", autoOpenDisabled: autoOpenDisabled});
    const userSelectProps = useUserBasicSelect({isClearable:true, autoOpenDisabled: autoOpenDisabled});
    const locFun = useLocalizeCiselnikValue(extras.typ===NabidkaType.PREPRAVA ? CiselnikTyp.P : CiselnikTyp.V);
    const search = extras.search;
    const isUzivatelPV = !admin && (extras.typ===NabidkaType.PREPRAVA || extras.typ===NabidkaType.VOZIDLO);
    const klasifikaceSelect = useKlasifikaceSelect({isClearable:true, autoOpenDisabled: true, isMulti: true})
    const [loadFilter, storeFilter] = useDataStore(DokladkaFilter, "DokladkaFilter", true, "session");

    return [()=>[
        showCols.indexOf("parentId")>-1 ? {
            title: extras.idColumnName,
            field: 'parentId',
            filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
            render: (data: T) => data.parentExists ? <Link to={{
                pathname: `${extras.endpoint}/${data.parentId}`,
                state: {
                    forcePreviousPage: pathname
                }
            }
            } onClick={(e) => {e.stopPropagation();}}>{data.parentId}</Link> : data.parentId,
        } : undefined,
        /*skryto na žádost zákazníka dle #3832
        extras.viewArchive ? {
            title: extras.typ===NabidkaType.PREPRAVA ? t("Preprava.id") : t("Vozidlo.id"),
            field: 'nabidkaId',
            filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
            render: (data: InzeratViewAdmin) => <Link to={{
                pathname: `${extras.typ===NabidkaType.PREPRAVA ? '/prohlizet/prepravy' : '/prohlizet/volnevozy'}/${data.nabidkaId}`,
                state: {
                    forcePreviousPage: pathname
                }
            }
            } onClick={(e) => {e.stopPropagation();}}>{data.nabidkaId}</Link>
        } : undefined,*/
        /*  skryto na žádost zákazníka dle #3832
            Boolean(user.provozovna) && (oznacenoFilter) && !extras.archive ? {
            title:t("PVI.Marked"),
            field:"oznaceno",
            render:(data: T) => <MarkIndicator checked={Boolean(data.oznaceno)} url={oznacenoUrl} id={data.id} afterCommit={() => {
                if(oznacenoFilter)
                    refreshFun.current();
            }}/>,
            filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
            editProps:() => ({type:FormInputType.Checkbox}),
            sorting: false
        } : null,*/
        {
            title: getTitleWithAdditionalInfo(t("Preprava.odkud").toUpperCase(), t("Preprava.odkudKamToolTip")),
            field: "odkud",
            defaultSort: isUzivatelPV ? "asc" : undefined,
            render: (data: T) => data.odkudHelper,
            filterProps:() => ({
                type:FormInputType.Custom,
                customComponent: extras?.refactor ? FormVicinityContainerNew : FormVicinityContainer,
                name:"okoliOdkud",
                customComponentOptions: {
                    ...vicinityOptions,
                    titleOkoli: t("Preprava.okoliOdkud"),
                    titleRadius: t("Preprava.radiusOdkud"),
                    titleMpz: t("Preprava.mpz"),
                    titlePsc: t("Preprava.psc"),
                    titleNazevMista: t("Preprava.odkud"),
                    okoliDisabled,
                    isDokladky,
                    isFilterOdkud: true,
                    currentNakladkaRef,
                    currentNakladkaRadiusRef,
                    isEmptyOkoliOdkudLastSearchRef,
                    isUserSetEmptyOkoliOdkudRef,
                    currentPrejezdOdWaypointRef,
                    isPrejezdy,
                    prejezdOdWaypointLastSearchRef
                },
                onChange: (v: Vicinity, data: any, setData: (data: any) => void, fieldName: string, isUserClearOkoli: boolean = false) => {
                    if (data && typeof data.prejezdOdWaypoint === 'object' && data.prejezdOdWaypoint !== null && fieldName === "okoliOdkud") { // true -> zalozka prejezdy prepravy + volne vozy

                        // Aktualizace pokud uzivatel vymazal okoli
                        if (!v?.osmPlace && currentPrejezdOdWaypointRef?.current && isUserSetEmptyOkoliOdkudRef?.current === true) {
                            currentPrejezdOdWaypointRef.current = null
                        }

                        if (v?.osmPlace) {
                            currentPrejezdOdWaypointRef.current = Waypoint.fromOkoli(v)
                            if (isUserSetEmptyOkoliOdkudRef.current === true) {
                                isUserSetEmptyOkoliOdkudRef.current = false
                            }
                        } else if (currentPrejezdOdWaypointRef?.current && isUserSetEmptyOkoliOdkudRef.current !== true) {
                            const updateV: Vicinity = {...v}

                            updateV.osmPlace = null
                            updateV.countryCode = null
                            updateV.koordinat = null

                            updateV.osmPlace = currentPrejezdOdWaypointRef?.current?.nazevMista
                            updateV.countryCode = currentPrejezdOdWaypointRef?.current?.countryCode
                            updateV.koordinat = currentPrejezdOdWaypointRef?.current?.koordinat

                            v = updateV
                        }
                        const updatePrejezdOdWaypoint = createUpdatePrejezdWaypointOrNakladkaOrVykladka(data.prejezdOdWaypoint, v);

                        if (updatePrejezdOdWaypoint) {
                            data.prejezdOdWaypoint = updatePrejezdOdWaypoint;
                            setData(data);
                        }
                    } else if (data && typeof data.nakladka === 'object' && data.nakladka !== null && fieldName === "okoliOdkud") { // true -> zalozka dokladky
                        handleDokladkyLocationChange(data, v, 'nakladka', 'okoliOdkud', currentNakladkaRef, currentVicinityNakladkyRef, currentNakladkaRadiusRef, setData, storeFilter, isDokladkaFilter, isUserClearOkoli);
                    }
                }
            }),
            editProps: {textFieldProps: {inputProps: {maxLength: 20}}}
        },
        {
            title: getTitleWithAdditionalInfo(t("Preprava.kam").toUpperCase(), t("Preprava.odkudKamToolTip")),
            field: "kam",
            render: (data: T) => data.kamHelper,
            filterProps:() => ({
                type:FormInputType.Custom,
                customComponent: extras?.refactor ? FormVicinityContainerNew : FormVicinityContainer,
                name:"okoliKam",
                customComponentOptions: {
                    ...vicinityOptions,
                    titleOkoli: t("Preprava.okoliKam"),
                    titleRadius: t("Preprava.radiusKam"),
                    titleMpz: t("Preprava.mpz"),
                    titlePsc: t("Preprava.psc"),
                    titleNazevMista: t("Preprava.kam"),
                    okoliDisabled,
                    isDokladky,
                    isFilterKam: true,
                    currentVykladkaRef,
                    currentVykladkaRadiusRef,
                    isEmptyOkoliKamLastSearchRef,
                    isUserSetEmptyOkoliKamRef,
                    currentPrejezdKamWaypointRef,
                    isPrejezdy,
                    prejezdKamWaypointLastSearchRef
                },
                onChange: (v: Vicinity, data: any, setData: (data: any) => void, fieldName: string,  isUserClearOkoli: boolean = false) => {
                    if (data &&
                        typeof data.prejezdKamWaypoint === 'object' &&
                        data.prejezdKamWaypoint !== null &&
                        fieldName === "okoliKam") { // true -> zalozka prejezdy prepravy + volne vozy
                        // Aktualizace pokud uzivatel vymazal okoli
                        if (!v?.osmPlace && currentPrejezdKamWaypointRef?.current && isUserSetEmptyOkoliKamRef.current === true) {
                            currentPrejezdKamWaypointRef.current = null
                        }

                        if (v?.osmPlace) {
                            currentPrejezdKamWaypointRef.current = Waypoint.fromOkoli(v)
                            if (isUserSetEmptyOkoliKamRef.current === true) {
                                isUserSetEmptyOkoliKamRef.current = false
                            }
                        } else if (currentPrejezdKamWaypointRef?.current && isUserSetEmptyOkoliKamRef.current !== true) {
                            const updateV: Vicinity = {...v}

                            updateV.osmPlace = null
                            updateV.countryCode = null
                            updateV.koordinat = null

                            updateV.osmPlace = currentPrejezdKamWaypointRef?.current?.nazevMista
                            updateV.countryCode = currentPrejezdKamWaypointRef?.current?.countryCode
                            updateV.koordinat = currentPrejezdKamWaypointRef?.current?.koordinat

                            v = updateV
                        }

                        const updatePrejezdKamWaypoint = createUpdatePrejezdWaypointOrNakladkaOrVykladka(data.prejezdKamWaypoint, v);

                        if (updatePrejezdKamWaypoint) {
                            data.prejezdKamWaypoint = updatePrejezdKamWaypoint;
                            setData(data);
                        }
                    } else if (data && typeof data.vykladka === 'object' && data.vykladka !== null && fieldName === "okoliKam") { // true -> zalozka dokladka
                        handleDokladkyLocationChange(data, v, 'vykladka', 'okoliKam', currentVykladkaRef, currentVicinityVykladkyRef, currentVykladkaRadiusRef, setData, storeFilter, isDokladkaFilter, isUserClearOkoli);
                    }
                }
            }),
            editProps: {textFieldProps: {inputProps: {maxLength: 20}}}
        },
        user.canShowKmFeature() && !userArchiveZadani ? {
            title: getTitleWithAdditionalInfo(t("Preprava.AirDistanceShort"), t("Preprava.AirDistanceToolTip")),
            field: "airDistance",
            render:(data:T) => data.airDistance && numeral(data.airDistance).format(sizeIntegerFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"airDistanceRange", customComponentOptions: rangeNumberSharedOptions}),
        } : undefined,
        {
            title: t("RAALKratkyKody.N"),
            field: "naves",
            render: (data:T) => <CheckMark checked={data.naves}/>,
            filterProps: !extras?.disableNSJFilter ? () => (
                {type: FormInputType.Checkbox,
                    onChange: (value: boolean, data: any) => {
                        if (value) njsSwitch('N', data)
                    }
                }) : undefined,
        },
        {
            title: t("RAALKratkyKody.S"),
            field: "souprava",
            render: (data:T) => <CheckMark checked={data.souprava}/>,
            filterProps: !extras?.disableNSJFilter ? () => (
                {type: FormInputType.Checkbox,
                    onChange: (value: boolean, data: any) => {
                        if (value) njsSwitch('S', data)
                    }
                }) : undefined,
        },
        {
            title: t("RAALKratkyKody.J"),
            field: "jine",
            render: (data:T) => <CheckMark checked={data.jine}/>,
            filterProps: !extras?.disableNSJFilter ? () => (
                {type: FormInputType.Checkbox,
                    onChange: (value: boolean, data: any) => {
                        if (value) njsSwitch('J', data)
                    }
                }) : undefined,
        },
        {
            title: t("Preprava.delkaShort"),
            field: "delka",
            render:(data:T) =>  data.delka&&numeral(data.delka).format(sizeViewFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"delkaRange", customComponentOptions:rangeNumberSharedOptions}),
        },
        {
            title: t("Preprava.vahaShort"),
            field: "vaha",
            render:(data:T) => data.vaha&&numeral(data.vaha).format(sizeViewFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"vahaRange", customComponentOptions:rangeNumberSharedOptions}),
        },
        {
            title: t("Preprava.druhy").toUpperCase(),
            field: extras.disableMultichoice!==true ? 'druhy' : 'druh',
            sorting: false,
            render: (data:T) => druhyJoined(data.druhy),
            filterProps: !search ? () => ({type:FormInputType.Select, selectProps: ciselnikSelectFilter, customComponentOptions:rangeSharedOptions}) : undefined,
        },
        !userArchiveZadani && {
            title: t("Preprava.adr").toUpperCase(),
            field: 'adr',
            render:(data:T)=> <CheckMark checked={data.adr}/>,
            filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
        },
        !userArchiveZadani && {
            title: t("Preprava.verejnaPozn").toUpperCase(),
            field: 'verejnaPozn',
            filterProps: () => ({type: FormInputType.Text}),
            editProps: {textFieldProps: {inputProps: {maxLength: 30}}}
        },
        {
            title:t("Default.Datum").toUpperCase(),
            field:"datum",
            filterProps:() => ({type:FormInputType.Custom, customComponent: admin ? FormDateRange : FormDateRangeSelect, name:"vpRange", customComponentOptions: {...rangeSharedOptions, width:65, autoOpenDisabled: autoOpenDisabled, spacing: 0, dateFromSelect: dateFromSelect, typParametru: typAdvance, defaultRange: defaultRange, verticalSpace: 0, hideTitle: true}}),
            render:(data:T)=> <DateCell data={data} />,
        },
        {
            title: t("Default.Telefon").toUpperCase(),
            field: "rychlyKontakt",
            render:(data:T)=><InlinePhoneNumberDial hideIcon phoneNumber={data.rychlyKontakt} rowData={data}/>,
            filterProps: () => ({type: FormInputType.Text})
        },
        {
            title:t('Default.Email').toUpperCase(),
            field:"email",
            filterProps: () => ({type: FormInputType.Text}),
            render:(data:T)=> <InlineEmailClient hideIcon emailAddress={data.email} rowData={data}/>,
            sorting: false,
        },
        {
            title: t("Preprava.cena").toUpperCase(),
            field: "cena",
            render:(data:T) => {
				const price = userArchiveZadani ? data.cena ?? '' : data.cena && data.currency.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? ''
				return data.jednotka ? price + "/" + data.jednotka : price
			},
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions:rangeCenaSharedOptions}),
        },
        !userArchiveZadani && {
            title: t("Provozovna.Kod").toUpperCase(),
            field: 'provozovna',
            render:(data:VPList) => data.provozovna && data.firma ? truncateString(`${data.provozovna} (${data.firma})`, 20) : '',
            filterProps: () => ({type: FormInputType.Text}),
        },
        {
            title: t("PVI.Modified").toUpperCase(),
            field: 'modifiedOn',
            defaultSort: !isUzivatelPV ? "desc" : undefined,
            render: (data:T) => data.modifiedOn?.format("L LT"),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false, verticalSpace: 0},  name: "modifiedOnRange"}),
        },
        {
            title: t("Preprava.paletyShort").toUpperCase(),
            field: 'palety',
            render: (data:T) => data.palety ? data.palety.toString() : "",
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"paletyRange", customComponentOptions:rangeCenaSharedOptions}),
            tooltip: t("Preprava.palety").toUpperCase()
        },
        {
            title: t("Preprava.sirkaShort").toUpperCase(),
            field: 'sirka',
            render: (data:T) => data.sirka ? data.sirka.toString() : "",
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"sirkaRange", customComponentOptions:rangeCenaSharedOptions}),
            tooltip: t("Preprava.sirka").toUpperCase(),
        },
        {
            title: t("Preprava.vyskaShort").toUpperCase(),
            field: 'vyska',
            render: (data:T) => data.vyska ? data.vyska.toString() : "",
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"vyskaRange", customComponentOptions:rangeCenaSharedOptions}),
            tooltip: t("Preprava.vyska").toUpperCase(),
        },
        {
            title: t("Preprava.lozPlochaShort").toUpperCase(),
            field: 'lozPlocha',
            render: (data:T) => data.lozPlocha ? data.lozPlocha.toString() : "",
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"lozPlochaRange", customComponentOptions:rangeCenaSharedOptions}),
            tooltip: t("Preprava.lozPlocha").toUpperCase(),
        },
        {
            title: t("Preprava.objemShort").toUpperCase(),
            field: 'objem',
            render: (data:T) => data.objem ? data.objem.toString() : "",
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"objemRange", customComponentOptions:rangeCenaSharedOptions}),
            tooltip: t("Preprava.objem").toUpperCase()
        },
        {
            title: t("Klasifikace.klasifikaceShort").toUpperCase(),
            field: 'klasifikace',
            render: (data:T) => data.klasifikace ?  data.klasifikace.klasifikace : "",
            filterProps: () => ({type: FormInputType.Select, selectProps: klasifikaceSelect, name: 'klasifikace.klasifikace'}),
            tooltip: t("Klasifikace.klasifikace").toUpperCase(),
            sorting: false,
        },
        (userArchiveZadani || search) && {
            title: t("Preprava.currency").toUpperCase(),
            field: "currency",
            render:(data:T)=> data.currency?.name ?? '',
            filterProps:() => ({type: FormInputType.Select, selectProps: currencySelectProps}),
        },
        userArchiveZadani && {
            title: t("User.Title").toUpperCase(),
            field: 'uzivatel',
            render:(row:T)=> `${row.uzivatel?.login ?? ''} ${row?.uzivatel.jmeno ? `(${row.uzivatel.jmeno})` : ""}`,
            filterProps:() => ({type: FormInputType.Select, selectProps: userSelectProps}),
        },
        showCols.indexOf("datIns")>-1 ? {
            title: t("PVI.Inserted").toUpperCase(),
            field: 'datIns',
            render: (data:T) => data.datIns?.format("L LT")
        } : undefined,
        userArchiveZadani && {
            title: t("PVI.InvalDuv").toUpperCase(),
            field: 'invalDuv',
            render: (data: T) =>  {// @ts-ignore
                return locFun(extras.typ===NabidkaType.PREPRAVA ? "Enumerations.InvalDuvodPreprava" : "Enumerations.InvalDuvodVozidlo", extras.typ===NabidkaType.PREPRAVA ? InvalDuvodPreprava : InvalDuvodVozidlo, data.invalDuv)},
            filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
        },
        (extras.archive && !admin) && {
            title: t("Archive.Archived"),
            field: extras.viewArchive ? 'datArch' : 'deletedOn',
            render:(data:T) => (extras.viewArchive ? data.datArch : data.deletedOn)?.format("L LT") ?? "",
            defaultSort: extras.archive ? "desc" : undefined,
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false, verticalSpace: 0},  name: extras.viewArchive ? 'datInsRange' : 'deletedOnRange'}),
        }
    ].filter(i => Boolean(i)) as Column<VPList<any>>[]];
}

export function useInputColumns<T extends VPList>({defaultRange, preprava, realtimeErrorsDatDo, realtimeErrorsDatOd, typParametru, typCiselniku, defaultAdvance, typAdvance, maxDruhyKey}:{typParametru:SystemParamKey, typCiselniku:CiselnikTyp, realtimeErrorsDatOd:MutableRefObject<string[]>, realtimeErrorsDatDo:MutableRefObject<string[]>, preprava:boolean, defaultRange:number, defaultAdvance:number, typAdvance:SystemParamKey, maxDruhyKey:SystemParamKey}):[()=>Column<T>[]] {
    const {t} = useTranslation();
    const [createDateHandler] = useDateLogic(typParametru, defaultRange, typAdvance, defaultAdvance);
    const [placeAutocomplete] = usePlaceAutocomplete();
    const dateFromSelect = useDateSelect({dateType: 'datOd', typAdvance: typAdvance, defaultAdvanceValue: defaultAdvance})
    const {druhyJoined} = useCiselnikValues(typCiselniku);
    const ciselnikSelectFilter = useCiselnikSelect(typCiselniku, {isClearable:true});
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
	const priceUnitsSelectProps = usePriceUnitsSelectObj({isClearable: true, autoOpenDisabled: true});
    const [n, s, j] = useNSJLogic<T>(preprava, true);
    const [cena] = useVPLogic<T>(preprava);
    const locFun = useLocalizeCiselnikValue(preprava ? CiselnikTyp.P : CiselnikTyp.V);
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: preprava ? CiselnikTyp.P : CiselnikTyp.V, enm: preprava ? InvalDuvodPreprava : InvalDuvodVozidlo, enmName: preprava ? "Enumerations.InvalDuvodPreprava" : "Enumerations.InvalDuvodVozidlo"});
    const [maxDruhu] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === maxDruhyKey));
    const ciselnikSelect = useCiselnikSelect(typCiselniku, {isMulti:true, maxValuesCount: maxDruhu?.toInt() ?? 2});
    const {user} = useAppContext();
    const rychlyKontaktSelect = useRychlyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, autoOpenDisabled: true, inputType: "phone"});
    const dispecerSelectProps = useDispecerForInputFormsSelect({isClearable: true, params: {uzivatelId: user.userId}, autoOpenDisabled: true});
    const emailSelect = createProvozovnaEmailyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, autoOpenDisabled: true})

    const handler  = (token?:string | symbol) => {
        if (token === 'dataContextChanged') {
            showSnack({
                title: t('DataContextChanged.title'),
                severity: "warning",
                action: [{title: t('Buttons.Refresh'), onClick: () => window.location.reload()}],
                anchorOrigin: {vertical: "top", horizontal: "center"},
                preventAutoHide: true
            });
        }
    }

    const { unsubscribe, resubscribe } = useSubscribe({ token: 'dataContextChanged', handler })

    useEffect(() => {
        resubscribe();
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

    return [
        ()=>[
            {
                title:t("Preprava.odkud").toUpperCase(),
                required: true,
                field:"odkud",
                render: (data: T) => data.odkudHelper,
                filterProps: () => ({type:FormInputType.Text}),
                editProps: (data:T) => placeAutocomplete(data, "odkud", 20, exist(data.id)),
                additionalFields: ["pscOdkud", "waypointy[0].countryCode", "waypointy[0]"]
            },
            {
                title:t("Preprava.kam").toUpperCase(),
                required: true,
                field:"kam",
                render: (data: T) => data.kamHelper,
                filterProps:() => ({type:FormInputType.Text}),
                editProps:(data:T) => placeAutocomplete(data, "kam", 20),
                additionalFields: ["pscKam", "waypointy[1].countryCode", "waypointy[1]"]
            },
            {
                title:t("RAALKratkyKody.N"),
                required: true,
                field:"naves",
                render:(data:T)=> <CheckMark checked={data.naves}/>,
                filterProps: () => ({type: FormInputType.Checkbox}),
                editProps:() => ({type:FormInputType.Checkbox, onChange:(value, data, setData) => n(data, setData, value)})
            },
            {
                title:t("RAALKratkyKody.S"),
                required: true,
                field:"souprava",
                render:(data:T)=> <CheckMark checked={data.souprava}/>,
                filterProps: () => ({type: FormInputType.Checkbox}),
                editProps:() => ({type:FormInputType.Checkbox, onChange:(value, data, setData) => s(data, setData, value)})
            },
            {
                title:t("RAALKratkyKody.J"),
                required: true,
                field:"jine",
                render:(data:T)=> <CheckMark checked={data.jine}/>,
                filterProps: () => ({type: FormInputType.Checkbox}),
                editProps:() => ({type:FormInputType.Checkbox, onChange:(value, data, setData) => j(data, setData, value)})
            },
            {
                title:t("Preprava.delkaShort"),
                required: true,
                field:"delka",
                render:(data:T) => data.delka&&numeral(data.delka).format(sizeViewFormat),
                filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"delkaRange", customComponentOptions:{numberProps:{format:sizeFormat}}}),
                editProps:() => ({type:FormInputType.Number, numberProps:{format:sizeFormat, constraint:vahaConstraint}}),
            },
            {
                title:t("Preprava.vahaShort"),
                required: true,
                field:"vaha",
                render:(data:T) => data.vaha&&numeral(data.vaha).format(sizeViewFormat),
                filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"vahaRange", customComponentOptions:{numberProps:{format:sizeFormat}}}),
                editProps:() => ({type:FormInputType.Number, numberProps:{format:sizeFormat, constraint:vahaConstraint}}),
            },
            {
                title:t("Preprava.druhy").toUpperCase(),
                required: true,
                sorting: false,
                field:"druhy",
                render: (data:T)=>druhyJoined(data.druhy),
                filterProps:() => ({type:FormInputType.Select, selectProps: {...ciselnikSelectFilter}, name:"druh"}),
                editProps:() => ({type:FormInputType.Select, selectProps: {...ciselnikSelect, computedTextFieldForMultiSelect: true, minWidth: 142}, autoSelectFirstValueOnTab: true})
            },
            {
                title:t("Default.Datum").toUpperCase(),
                required: true,
                field:"datum",
                additionalFields:["datOd", "datDo"],
                filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"datumRange"}),
                render:(data:T)=> <DateCell data={data} />,
                editProps:(data:T, e:boolean, setData:(d:T)=>void)=>({type:FormInputType.Custom, customComponent:FormTwoDatesSelect,
                    customComponentOptions:{width:90, dateFromSelect, logicHandler:createDateHandler, data, setData, realtimeErrorsDatDo, realtimeErrorsDatOd, typParametru, defaultRange}})
            },
            {
                title: t("Default.Telefon").toUpperCase(),
                field: 'rychlyKontakt',
                filterProps:() => ({type:FormInputType.Text}),
                editProps: () => ({type: FormInputType.Select, selectProps: rychlyKontaktSelect, autoSelectFirstValueOnTab: true, useFormattedNumber: true}),
                render:(data:T)=><InlinePhoneNumberDial hideIcon phoneNumber={data.rychlyKontakt} rowData={data}/>
            },
            {
                title:t('Default.Email').toUpperCase(),
                field:"email",
                filterProps: () => ({type: FormInputType.Text}),
                render:(data:T)=> data.email,
                editProps:() => ({type: FormInputType.Select, selectProps: emailSelect, autoSelectFirstValueOnTab: true}),
                sorting: false,
            },
            {
                title:t("Preprava.cena").toUpperCase(),
                field:"cena",
                render:(data:T) => data.cena && numeral(data.cena).format(sizeFormat),
                filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions: {numberProps:{format:sizeFormat}}}),
                editProps:() => ({type:FormInputType.Number, numberProps:{format:sizeFormat, constraint:cenaConstraint}, onChange:(value, data, setData) => cena(data, setData, value)}),
            },
            {
                title: t("Currency.Title").toUpperCase(),
                field: 'currency',
                render:(row:T)=> row.currency?.name ?? '',
                filterProps:() => ({type: FormInputType.Select, selectProps: currencySelectProps}),
                editProps: (data) => ({type: FormInputType.Select, selectProps: currencySelectProps, autoSelectFirstValueOnTab: true, disabled: !data?.cena})
            },
			{
				title: t("Default.JednotkaShort").toUpperCase(),
				field: 'jednotka',
				render: (row) => row.jednotka ?? '',
				filterProps: () => ({ type: FormInputType.Select, selectProps: priceUnitsSelectProps }),
				editProps: (data) => ({ type: FormInputType.Select, selectProps: priceUnitsSelectProps, autoSelectFirstValueOnTab: true, disabled: !data?.cena }),
			},
            {
                title: t("User.Title").toUpperCase(),
                field: 'uzivatel',
                render:(row:T)=> `${row.uzivatel?.login ?? ''} ${row?.uzivatel.jmeno ? `(${row.uzivatel.jmeno})` : ""}`,
                filterProps:() => ({type: FormInputType.Select, selectProps: userSelectProps}),
            },
            {
                title: t("PVI.Modified").toUpperCase(),
                field: 'modifiedOn',
                defaultSort:"desc",
                render: (data:T) => data.modifiedOn?.format("L LT")
            },
            {
                title: t("PVI.InvalDuv").toUpperCase(),
                field: 'invalDuv',
                render: (row) =>  {// @ts-ignore
                    return locFun(preprava ? "Enumerations.InvalDuvodPreprava" : "Enumerations.InvalDuvodVozidlo", preprava ? InvalDuvodPreprava : InvalDuvodVozidlo, row.invalDuv)},
                filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
            },
            {
                title: t("Preprava.paletyShort").toUpperCase(),
                field: 'palety',
                render: (row) => row.palety ? row.palety.toString() : "",
                editProps:() => ({type:FormInputType.Number, numberProps:{constraint:paletyConstraint}}),
                tooltip: t("Preprava.palety").toUpperCase()
            },
            {
                title: t("Poznamka.poznamka").toUpperCase(),
                field: 'verejnaPozn',
                render: (row) => row.verejnaPozn ? row.verejnaPozn : "",
                editProps:() => ({type:FormInputType.Text, textFieldProps: {inputProps: {maxLength: 30}}}),
            },
            {
                title: t("Inzerce.Dispecer").toUpperCase(),
                field: 'dispecer',
                render: (row) => row.dispecer ? row.dispecer.jmeno : "",
                editProps: () => ({type: FormInputType.Select, selectProps: dispecerSelectProps, autoSelectFirstValueOnTab: true}),
            },
            {
                title: t("Preprava.sirkaShort").toUpperCase(),
                field: 'sirka',
                render: (row) => row.sirka ? row.sirka.toString() : "",
                editProps:() => ({type:FormInputType.Number, numberProps:{format:sizeFormat, constraint:vahaConstraint}}),
                tooltip: t("Preprava.sirka").toUpperCase()
            },
            {
                title: t("Preprava.vyskaShort").toUpperCase(),
                field: 'vyska',
                render: (row) => row.vyska ? row.vyska.toString() : "",
                editProps:() => ({type:FormInputType.Number, numberProps:{format:sizeFormat, constraint:vahaConstraint}}),
                tooltip: t("Preprava.vyska").toUpperCase()
            },
            {
                title: t("Preprava.lozPlochaShort").toUpperCase(),
                field: 'lozPlocha',
                render: (row) => row.lozPlocha ? row.lozPlocha.toString() : "",
                editProps:() => ({type:FormInputType.Number, numberProps:{format:sizeFormat, constraint:vahaConstraint}}),
                tooltip: t("Preprava.lozPlocha").toUpperCase(),
            },
            {
                title: t("Preprava.objemShort").toUpperCase(),
                field: 'objem',
                render: (row) => row.objem ? row.objem.toString() : "",
                editProps:() => ({type:FormInputType.Number, numberProps:{format:sizeFormat, constraint:vahaConstraint}}),
                tooltip: t("Preprava.objem").toUpperCase(),
            },
            {
                title: t("Preprava.neverPozn").toUpperCase(),
                field: 'neverPozn',
                render: (row) => row.neverPozn ? row.neverPozn : "",
                editProps:() => ({type:FormInputType.Text, textFieldProps: {inputProps: {maxLength: 100}}}),
            },
        ]
    ];
}
